import * as actionTypes from './actionTypes'

import client from '../../services/client'

export const commonSetTour = (value) => {
  return {
    type: actionTypes.COMMON_SET_TOUR,
    value: value
  }
}

export const commonDisableTour = (uid) => dispach => {
  client.setAccountProperties(uid, {isTourActive: false})
}
