import React from 'react'
import { Line} from 'react-chartjs-2'
import moment from 'moment'
import { mapKeys, defaultTo, isEmpty } from 'lodash'

import { monthRange } from '../../../utils/dates'
import { PRIMARY_COLOR_BG, PRIMARY_COLOR_BORDER, SECONDARY_COLOR_BG, SECONDARY_COLOR_BORDER } from '../../../utils/constants'

const PRIMARY_COLOR = { background: PRIMARY_COLOR_BG, border: PRIMARY_COLOR_BORDER  }
const SECONDARY_COLOR = { background: SECONDARY_COLOR_BG, border: SECONDARY_COLOR_BORDER }

const getLabel = (tracker) => {
  return `${tracker.options.trackerName} ${tracker.options.units}`
}

const getAmPmDataSet = (date, trackersData, trackSelected, color) => {
  const dates = monthRange(date, d => d)
  const keys = dates.map(i => i.date())
  const trackersDataDays = mapKeys(trackersData,(value, key) => moment(key).date() )

  const calculateAverage = (k) => {
    return !isEmpty(trackersDataDays[k]) ? (((defaultTo(parseInt(trackersDataDays[k].pm),0) + defaultTo(parseInt(trackersDataDays[k].am),0))/2)) : 0
  }

  const values = keys.map(calculateAverage)
  return {
          label: getLabel(trackSelected),
          data: values,
          backgroundColor: color.background,
          borderColor: color.border,
          borderWidth: 1,
        }
}

const getSingleDataSet = (date, trackersData, trackSelected, color) => {
  const dates = monthRange(date, d => d)
  const keys = dates.map(i => i.date())
  const trackersDataDays = mapKeys(trackersData,(value, key) => moment(key).date() )

  const handleValue = (k) => {
    if (trackersDataDays[k] && trackersDataDays[k].value) {
      return parseInt(trackersDataDays[k].value)
    } else {
      return 0
    }
  }

  return {
          label: getLabel(trackSelected),
          data: keys.map(handleValue),
          backgroundColor: color.background,
          borderColor: color.border,
          borderWidth: 1,
      }
}

const getDataSet = (date, trackersData, trackSelected, color) => {
  const keys = Object.keys(trackersData)
  let dataSet = null
  if (keys[0]) {
    if (trackersData[keys[0]].am) {
      dataSet = getAmPmDataSet(date,trackersData, trackSelected, color)
    } else {
      dataSet = getSingleDataSet(date,trackersData, trackSelected, color)
    }
  }

  return dataSet
}

const convertToData = (date, trackersData, trackSelected, compareTrackerName, compareTrackerData, compareTracker) => {
  const dataSet = getDataSet(date, trackersData, trackSelected, PRIMARY_COLOR)
  let compareDataSet = null
  let dataSets = null

  if (!isEmpty(compareTrackerName)) {
    compareDataSet = getDataSet(date, compareTrackerData, compareTracker, SECONDARY_COLOR)
    if (compareDataSet) {
      dataSets = [dataSet, compareDataSet]
    } else {
      dataSets = [dataSet]
    }
  } else {
    dataSets = [dataSet]
  }

  const dates = monthRange(date, d => d)

  return {
    labels: dates.map(i => i.date()),
    datasets: dataSets
  }
}

const buildOptions = (trackSelected) => {
  let maxNumber = null

  if (trackSelected.type == 'numbers') {
    maxNumber = trackSelected.options.maxNumber
  }


  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMax: maxNumber
          },
        },
      ],
    },
  }

  return options
}

const MonthChart = (props) => {
  const data = convertToData(props.date, props.trackersData, props.trackSelected, props.compareTrackerName,  props.compareTrackerData, props.compareTracker)
  const options = buildOptions(props.trackSelected)

  // const getElementAtEvent = element => {
  //   if (!element.length) return
  //
  //    // _datasetIndex: datasetIndex
  //   const { _index: index } = element[0]
  //
  //   const date = moment(props.date.format('YYYY-MM') + '-' + data.labels[index])
  //
  //   props.onChartClick(date)
  // }
  // getElementAtEvent={getElementAtEvent}
  
  return (
    <>
    <div className='header'>
      <h2 className='title'>{props.date.format('MMMM YYYY')}</h2>
    </div>
    <Line data={data} options={options}  />
  </>
 );
}



export default MonthChart
