import * as actionTypes from '../actions/actionTypes'

const initialState = {
  watcherResultsSecond:[],
}

const watchersSetField = (state, action) => {
  return {
    ...state,
    [action.field]: action.value
  }
}

const watchersSetWatcherResult = (state, action) => {
  const entry = {
    watcherId: action.watcher.id,
    ...action.result
  }

  const list = state.watcherResultsSecond.concat(entry)

  return {
    ...state,
    watcherResultsSecond: list
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.WATCHERS_SET_FIELD: return watchersSetField(state, action);
    case actionTypes.WATCHERS_SET_WATCHER_RESULT: return watchersSetWatcherResult(state, action)
    default: return state;
  }
}

export default reducer
