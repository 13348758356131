import React, { useState } from 'react'
import { Input,InputNumber, Switch, Form, Button, Select, AutoComplete, Radio } from 'antd'

import { trackNameUniquenesValidator } from '../../utils'
import { Box, Alert } from '../../../UI'
import './NumberOptions.css'

const Option = Select.Option

  const UNITS_OPTIONS = [
    {label:'Hours', value:'hours'},
    {label:'Intensity',value:'intensity'},
    {label:'Pounds',value:'pounds'},
    {label:'Ounnces',value:'ounnces'},
    {label:'Gallons',value:'gallons'},
    {label:'Times',value:'times'}
  ]

const MAX_NUMBERS = 100
const validateNumbers = (min, max, step) => {
  const diff = max - min
  const total = (diff / step) + 1
  if (total > MAX_NUMBERS) {
    return false
  } else {
    return true
  }
}

const tailLayout = {
  wrapperCol: { offset: 1, span: 2 },
};

const NumberOptions = (props) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [showOptions, setShowOptions] = useState(false)

  const onStep1FieldsChange = (changedFields, allFields) => {
    props.onNumbersUpdate(changedFields)
  }

  const onStep1Finish = (values) => {
    setShowOptions(true)
    if (values.listType === 'boolean') {
      props.onCreate(props.trackerType, props.configuration)
    }
  }

  const onStep2FieldsChange = (changedFields, allFields) => {

    const minNumber = allFields[2].value
    const maxNumber = allFields[3].value
    const step = allFields[4].value

    if (minNumber && maxNumber && step) {
      if (validateNumbers(minNumber, maxNumber, step)) {
        setErrorMessage(null)
      } else {
        setErrorMessage(`Cannot create select with more than ${MAX_NUMBERS} elements.`)
        return false
      }
    }
    props.onNumbersUpdate(changedFields)
  }

  const onFinish = (values) => {
    if (validateNumbers(values.minNumber, values.maxNumber, values.steps)) {
      props.onCreate(props.trackerType, props.configuration)
    } else {
      setErrorMessage(`Cannot create select with more than ${MAX_NUMBERS} elements.`)
      return false
    }
  }

  return (
    <Box title="or Create new tracker">
      <div className="Step-Content Step2-Form">
        {errorMessage && <Alert message={errorMessage} type="error"/> }
        {!showOptions && (
          <Form layout='vertical' initialValues={ {layout: 'vertical'} } onFieldsChange={onStep1FieldsChange} onFinish={onStep1Finish}>
            <Form.Item name="trackerName" label="Tracker Name" initialValue={props.numbers.trackerName} rules={[{ required: true,  message:'Please enter a tracker name!' }, trackNameUniquenesValidator(props.trackerNames)]}>
              <Input/>
            </Form.Item>

            <Form.Item
             label="Group Name"
             name="category"
             initialValue={props.numbers.category}
             rules={[{ required: true, message: 'Please input group name!' }]}
             >
              <AutoComplete options={props.categories} placeholder="A category name like mental health, physical health, activities etc.." />
            </Form.Item>

            <Form.Item
             label="Type"
             name="listType"
             initialValue={props.numbers.listType}
             rules={[{ required: true, message: 'Please input type!' }]}
             >

             <Radio.Group>
               <Radio value="boolean">Yes/No</Radio>
               <Radio value="numbers">Numeric</Radio>
             </Radio.Group>

            </Form.Item>

            <Form.Item
             label="Times a week"
             name="timesAWeek"
             initialValue={props.numbers.timesAWeek}
             rules={[{ required: true, message: 'Please enter times a week!' }]}
             >
             <Select defaultValue={7}>
               <Option value={1}>1</Option>
               <Option value={2}>2</Option>
               <Option value={3}>3</Option>
               <Option value={4}>4</Option>
               <Option value={5}>5</Option>
               <Option value={6}>6</Option>
               <Option value={7}>7</Option>
             </Select>
            </Form.Item>
            <span>How many days in a week you want to input data for this tracker.</span>


            <div className="NumberOptions-FormActions">
              {/*<Button style={{ margin: '0 8px' }} onClick={props.prev}>
                Previous
              </Button>*/}
              <Button shape="round" htmlType="submit" type='primary' disabled={errorMessage ? true : false}>Continue</Button>
            </div>
          </Form>
      )}

      {showOptions && (props.numbers.listType !== 'boolean') && (
        <Form layout='vertical' initialValues={ {layout: 'vertical'} } onFieldsChange={onStep2FieldsChange} onFinish={onFinish}>
          <Form.Item name="units" label="Units" initialValue={props.numbers.units} rules={[{ required: true }]}>
            {/*<Input placeholder="Hours, intensity ,grams, liters, times, etc..."/>*/}
            <AutoComplete options={UNITS_OPTIONS} placeholder="Hours, pounds, ounnces, gallons, times, etc..." />
          </Form.Item>
          {/*
            <Form.Item {...tailLayout} label="AM/PM" name="amPm" valuePropName="checked" initialValue={props.numbers.amPm}>
            <Switch/>
          </Form.Item>
          */}
          <Form.Item name="minNumber" label="Min Number"  initialValue={props.numbers.minNumber} rules={[
            { required: true }
          ]}>
            <InputNumber min={0} defaultValue={1}/>
          </Form.Item>
          <Form.Item name="maxNumber" label="Max Number"  initialValue={props.numbers.maxNumber}  rules={[
            { required: true},
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || parseInt(getFieldValue('minNumber')) < parseInt(value)) {
                  return Promise.resolve();
                }
                return Promise.reject('Min number should be less than max number!');
              },
            })
            ]}
            >
            <InputNumber min={1} defaultValue={10}/>
          </Form.Item>
          <Form.Item name="steps" label="Increments" initialValue={props.numbers.steps} rules={[{ required: true }]}>
            <Select defaultValue={1}>
              <Option value={0.25}>1/4</Option>
              <Option value={0.5}>1/2</Option>
              <Option value={1}>1</Option>
              <Option value={2}>2</Option>
              <Option value={5}>5</Option>
            </Select>
          </Form.Item>

          <Form.Item {...tailLayout} label="The less the best" name="theLessTheBest" valuePropName="checked" initialValue={props.numbers.theLessTheBest}>
            <Switch/>
          </Form.Item>

          <div className="NumberOptions-FormActions">
            <Button shape="round" style={{ margin: '0 8px' }} onClick={() => setShowOptions(false)}>
              Previous
            </Button>
            <Button shape="round" htmlType="submit" type='primary' disabled={errorMessage ? true : false}>Add tracker</Button>
          </div>
        </Form>
      )}
      </div>
    </Box>
  )
}

export default NumberOptions
