
/* Date formats */

export const DATE_FORMAT_LONG = "ddd, MMM D YYYY" //"MMMM Do YYYY"

export const DATE_FORMAT_SHORT = "MMM D" //"MMMM Do YYYY"

export const DATE_FORMAT_WITH_DAY = "ddd, MMM D"

export const FORMAT_DAY = 'dd D'

export const DATE_FORMAT = 'YYYY-MM-DD'

/* COLORS */

export const PRIMARY_COLOR = "#1890ff"

export const PRIMARY_COLOR_BG = 'rgba(255, 99, 132, 0.2)'

export const PRIMARY_COLOR_BORDER = 'rgba(255, 99, 132, 1)'

export const SECONDARY_COLOR_BG = 'rgba(54, 162, 235, 0.2)'

export const SECONDARY_COLOR_BORDER = 'rgb(54, 162, 235)'


export const TRACKERS_CATEGORIES = ['Mood','Physical Health','Activities','Behavioral','Cognitive','Psychological']


export const GOOGLE_ANALYTICS_ID = 'G-63HVRYEYPZ'

export const COOOKIES_STORAGE = 'acceptCookies'

export const ALERT_TYPE = 'warning'
