import React from 'react'
import { Menu, Button } from 'antd';
import { keys } from 'lodash'
import { AppstoreOutlined ,UnorderedListOutlined, LogoutOutlined, CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { withRouter, Link } from "react-router-dom";

import { MAP_PAGES_TO_STEPS } from '../../../data/tour'

// const { SubMenu } = Menu;

const shouldShowTour = (pathname, hasTrackers) => {
  if (pathname === "/") {
    if (hasTrackers) {
      return true
    } else {
      return false
    }
  } else {
    return keys(MAP_PAGES_TO_STEPS).includes(pathname)
  }
}

class NavigationMenu extends React.Component {
    state = {
      current: 'mail',
    };

    handleClick = e => {
      this.setState({ current: e.key });
    };

    handleOpenTour = () => {
      window.gtag('event','tour_opened')
      this.props.onSetTour(true)
    }

    render() {
      const {
        location,
        username,
        hasTrackers
      } = this.props
      // const { current } = this.state;
      //onClick={this.handleClick}
      //selectedKeys={[current]}
      const styles = {}

      styles.position = 'fixed'
      styles.width = '100%'
      styles.top = 0
      styles.zIndex = 2

      return (
        <Menu style={styles} selectedKeys={[location.pathname]} mode="horizontal">
          {/*<Menu.Item key="/day" icon={ <UnorderedListOutlined /> }>
            <Link to="/day">Day</Link>
          </Menu.Item>*/}
          <Menu.Item key="/" icon={ <UnorderedListOutlined /> }>
            <Link to="/today">Week</Link>
          </Menu.Item>
          {/*<Menu.Item key="/month" icon={<AppstoreOutlined />}>
            <Link to="/month">Month</Link>
          </Menu.Item>*/}
          <Menu.Item key="/reports" icon={<AppstoreOutlined />} className='tour-reports'>
            <Link to="/reports">Reports</Link>
          </Menu.Item>

          <Menu.Item key="/watchers" icon={<CheckCircleOutlined /> } className='tour-watchers'>
            <Link to="/watchers">Watchers</Link>
          </Menu.Item>

          <Menu.Item style={{float: 'right'}} key="/user" icon={<LogoutOutlined />}>
              <Link to="/logout">Logout {username}</Link>
          </Menu.Item>

          <Menu.Item style={{float: 'right'}} key="/account" icon={<InfoCircleOutlined />}>
              <Link to="/account">Account</Link>
          </Menu.Item>

          { shouldShowTour(location.pathname, hasTrackers) && (
            <Menu.Item style={{float: 'right'}}>
              <Button type='primary' shape='round' onClick={this.handleOpenTour}>Tour</Button>
            </Menu.Item>)
          }


          {/*<Menu.Item style={{float: 'right'}} key="/logout">
            {username}
          </Menu.Item>*/}
          {/*
          <SubMenu key="SubMenu" icon={<SettingOutlined />} title="Navigation Three - Submenu">
            <Menu.ItemGroup title="Item 1">
              <Menu.Item key="setting:1">Option 1</Menu.Item>
              <Menu.Item key="setting:2">Option 2</Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="Item 2">
              <Menu.Item key="setting:3">Option 3</Menu.Item>
              <Menu.Item key="setting:4">Option 4</Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <Menu.Item key="alipay">
            <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
              Navigation Four - Link
            </a>
          </Menu.Item>
        */}
        </Menu>
      );
    }
}

export default withRouter(NavigationMenu);
