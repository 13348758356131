import React from 'react'
import moment from 'moment'
import { Tooltip } from 'antd'
import { capitalize, map } from 'lodash'

import NumberEntry from './NumberEntry/NumberEntry'
import { DATE_FORMAT_LONG, FORMAT_DAY, DATE_FORMAT} from '../../../../utils/constants'
import { generateWeekDates } from '../../../../utils/dates'

import './Number.css'

const Number = (props) => {
  let entries = []
  if (props.format === 'day') {
    entries = [props.date]
  } else {
    entries = generateWeekDates(props.date)
  }

  return (
      <React.Fragment>
        {/*<label className="Options-Label">{props.trackerName}</label>*/}
        <ul className="Options">
          {map(entries, (entry, idx) => {

            if (props.amPm) {
              // const entryValue = parseInt(entry.format('DD'))
              // const entryDisplay Value = entry.format('DD')
              const entryValue = entry.format('YYYY-MM-DD')
              let isCurrentDate = false
              let isFutureDate = false
              if (entry.format(DATE_FORMAT) === moment().format(DATE_FORMAT)) {
                isCurrentDate = true
              }
              isFutureDate = entry.diff(moment()) > 0

              const cssClasses = []

              if (isCurrentDate) {
                cssClasses.push('Tracker-IsCurrentDate')
              }
              if (isFutureDate) {
                cssClasses.push('Tracker-isFutureDate')
              }

              if (props.tour && idx == 0) {
                cssClasses.push('tour-tracker-bullet')
              }

              return (<li className={cssClasses.join(' ')}>
                        <div className="Number-PmAm">
                          <div>
                            <Tooltip title={entry.format(DATE_FORMAT_LONG)}>{entry.format(FORMAT_DAY)}</Tooltip>
                          </div>
                          <div className="Number-Am">
                          <NumberEntry
                              disabled={isFutureDate}
                              date={entry}
                              units={props.units}
                              amPm="AM"
                              displayValue="AM"
                              value={entryValue}
                              label={props.entries[entryValue] && props.entries[entryValue]['AM']}
                              min={props.minNumber}
                              max={props.maxNumber}
                              step={props.steps}
                              theLessTheBest={props.theLessTheBest}
                              onClick={props.onClick}/>
                          </div>
                          <div className="Number-Pm">
                            <NumberEntry
                                disabled={isFutureDate}
                                date={entry}
                                units={props.units}
                                amPm="PM"
                                displayValue="PM"
                                value={entryValue}
                                label={props.entries[entryValue] && props.entries[entryValue]['PM']}
                                min={props.minNumber}
                                max={props.maxNumber}
                                step={props.steps}
                                theLessTheBest={props.theLessTheBest}
                                onClick={props.onClick}/>
                          </div>
                        </div>
                      </li>)
            } else {
              const entryDisplayValue = entry.format('dd D')
              const entryValue = entry.format('YYYY-MM-DD')
              let isCurrentDate = false
              let isFutureDate = false
              if (entry.format(DATE_FORMAT) === moment().format(DATE_FORMAT)) {
                isCurrentDate = true
              }
              isFutureDate = entry.diff(moment()) > 0

              const cssClasses = []

              if (isCurrentDate) {
                cssClasses.push('Tracker-IsCurrentDate')
              }
              if (isFutureDate) {
                cssClasses.push('Tracker-isFutureDate')
              }

              if (props.tour && idx == 0) {
                cssClasses.push('tour-tracker-bullet')
              }

              return (<li key={entryValue} className={cssClasses.join(' ')}>
                          <NumberEntry
                              disabled={isFutureDate}
                              date={entry}
                              units={capitalize(props.units)}
                              displayValue={entryDisplayValue}
                              value={entryValue}
                              label={props.entries[entryValue]}
                              min={props.minNumber}
                              max={props.maxNumber}
                              listType={props.listType}
                              step={props.steps}
                              theLessTheBest={props.theLessTheBest}
                              onClick={props.onClick}/>
                      </li>)
            }
          })}
        </ul>
      </React.Fragment>
  )
}

export default Number
