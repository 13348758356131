import React from 'react'

import { Button } from 'antd'
import { groupBy, map, isEmpty } from 'lodash'

import NumberOptions from './NumberOptions/NumberOptions'
import { Box } from '../../UI'
import { getPredefinedTrackers } from '../../../data/predefinedTrackers'

import './Step2.css'

const Step2 = (props) => {
  let content = null

  switch (props.trackerType) {
    case 'numbers':
      content = <NumberOptions
                    onNumbersFinished={props.onNumbersFinished}
                    onNumbersUpdate={props.onNumbersUpdate}
                    prev={props.prev}
                    numbers={props.numbers}
                    categories={props.categories}
                    trackerNames={props.trackerNames}
                    onCreate={props.onCreate}
                    configuration={props.configuration}
                    trackerType={props.trackerType}
                    />
      break;
    default:

  }

  const trackers = getPredefinedTrackers()
  const trackersGrouped = groupBy(trackers, t => t.category)

  const sortCondition  = (a, b) => {
    const nameA = a.trackerName.toUpperCase(); // ignore upper and lowercase
    const nameB = b.trackerName.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }

  return (
    <div>
      <Box title="Add predefined tracker">
        <div className="PredefinedTracker-Select">
          <select className="Form-Select" onChange={(e) => props.onSelectPredefinedTracker(e.target.value)}>
            <option></option>
            {map(trackersGrouped, (values, key) => {
              return (
                <optgroup label={key} key={key}>
                  {values.sort(sortCondition).map(i => (<option value={i.trackerName} key={i.trackerName}>{i.trackerName}</option>))}
                </optgroup>
              )
            })}
          </select>
        </div>

        <Button shape="round" type="primary" disabled={isEmpty(props.predefinedTrackerSelected)} onClick={() => props.onCreate(props.trackerType, props.configuration)}>Add tracker</Button>
      </Box>
      <hr/>
      {content}
    </div>
  )
}

export default Step2
