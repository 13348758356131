import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose, combineReducers} from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import Honeybadger from '@honeybadger-io/js'
import ErrorBoundary from '@honeybadger-io/react'

import insightReducer  from './store/reducers/insight'
import monthReducer  from './store/reducers/month'
import weekReducer  from './store/reducers/week'
import authReducer  from './store/reducers/auth'
import newTrackerReducer  from './store/reducers/newTracker'
import dayReducer from './store/reducers/day'
import watchersReducer from './store/reducers/watchers'
import newWatcherReducer from './store/reducers/newWatcher'
import editWatcherReducer from './store/reducers/editWatcher'
import wizardReducer from './store/reducers/wizard'
import commonReducer from './store/reducers/common'

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
  insight: insightReducer,
  month: monthReducer,
  week: weekReducer,
  auth: authReducer,
  newTracker: newTrackerReducer,
  day: dayReducer,
  watchers: watchersReducer,
  newWatcher: newWatcherReducer,
  editWatcher: editWatcherReducer,
  wizard: wizardReducer,
  common: commonReducer
})

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

Honeybadger.configure({
  apiKey: 'hbp_3ozXbAsbIilSdAOOfZPlYzrrUidSEg15j4fd',
  environment: process.env.NODE_ENV
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary honeybadger={Honeybadger}>
        <App />
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
