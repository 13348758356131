import { message } from 'antd'
import { isUndefined, toLower, uniq, trim } from 'lodash'

import * as actionTypes from './actionTypes'
import { db } from '../../utils/firebase'
import client from '../../services/client'
import { setAuthHasTrackers } from './auth'

export const newTrackerOnCheckListFinished = (values) => {
  return {
    type: actionTypes.NEW_TRACK_ON_CHECK_LIST_FINISHED,
    values:values
  }
}

export const newTrackerOnNumbersFinished = (values) => {
  return {
    type: actionTypes.NEW_TRACK_ON_NUMBERS_FINISHED,
    values:values
  }
}

export const newTrackerOnOptionsFinished = (values) => {
  return {
    type: actionTypes.NEW_TRACK_OPTIONS_FINISHED,
    values:values
  }
}

export const newTrackerOnRemoveOption = (index) => {
  return {
    type: actionTypes.NEW_TRACK_ON_REMOVE_OPTION,
    index: index
  }
}

export const newTrackerOnAddOption = (values) => {
  return {
    type: actionTypes.NEW_TRACK_ON_ADD_OPTION,
    values: values
  }
}

export const newTrackOnPrev = () => {
  return {
    type: actionTypes.NEW_TRACKER_ON_PREV
  }
}

export const newTrackerOnNext = () => {}

export const newTrackerOnResetValues = () => {
  return {
    type: actionTypes.NEW_TRACKE_ON_RESET_VALUES
  }
}

export const newTrackerOnStep1Clicked = (value) => {
  return {
    type: actionTypes.TRACKER_STEP_ONE_CLICKED,
    value: value
  }
}

export const newTrackerResetSteps = () => {
  return {
    type: actionTypes.NEW_TRACKER_RESET_STEPS
  }
}

export const newTrackOnCreate = (uid, type, options, history) => {
  return dispach => {
    window.gtag('event', 'tracker_created', { 'tracker_name': options.trackerName })

    client.createTrackerForType(uid, type, options).then(() =>{
      dispach(newTrackerResetSteps())
      dispach(setAuthHasTrackers())
      history.push('/')
      message.info('New tracker created')
    })
  }
}

export const newTrackerSetField = (field, value) => {
  return {
    type: actionTypes.NEW_TRACKER_SET_FIELD,
    field: field,
    value: value
  }
}

/* NOTE: Not used removed*/
export const newTrackOnLoadTrackers = (uid) => {
  return dispach => {
    db.collection(`accounts/${uid}/trackers`)
    .where('type','==','options')
    .get()
    .then( snaps => {
      const data = []
      snaps.forEach(doc => data.push(doc.data()))
      dispach(newTrackerSetField('trackers', data))
    })
  }
}

export const newTrackOnSelectOptionsTrack = (track) => {
  return {
    type: actionTypes.NEW_TRACK_ON_SELECT_OPTIONS_TRACK,
    track:track
  }
}

export const newTrackLoadCategories = (uid) => {
  return dispach => {
    db.collection(`accounts/${uid}/trackers`)
    .get()
    .then( snaps => {
      let data = []
      snaps.forEach(doc => {
        const { category } = doc.data().options
        if (!isUndefined(category)){
          data.push(category)
        }
      })

      data = uniq(data)
      data = data.map (category => {
        return { value: category, label: category }
      })

      dispach(newTrackerSetField('categories', uniq(data)))
    })
  }
}


export const newTrackerLoadTrackerNames = (uid) => {
  return dispach => {
    db.collection(`accounts/${uid}/trackers`)
    .get()
    .then(snaps => {
        let d = []
        snaps.forEach(doc => {
          d.push(toLower(trim(doc.data().options.trackerName)))
        })

        dispach(newTrackerSetField('trackerNames', uniq(d)))
    })
  }
}

export const newTrackerTypeUpdate = (trackerType, values) => {
  return {
    type: actionTypes.NEW_TRACKER_ON_CHECK_LIST_UPDATE,
    values:values,
    trackerType: trackerType
  }
}

export const newTrackerSelectPredefinedTracker = (value) => {
  return {
    type: actionTypes.NEW_TRACKER_SET_PREDEFINED_TRACKER,
    value: value
  }
}
