import React, {useState} from 'react'
import moment from 'moment'
import {Calendar, Modal, Button} from 'antd';
import ScrollableToolbar from '../ScrollableToolbar/ScrollableToolbar'
import {DATE_FORMAT_SHORT, DATE_FORMAT_WITH_DAY} from '../../../utils/constants'
import './ScrollableDate.css'

const getTitle = (type, date, onBackToToday) => {
  switch (type) {
    case 'day':
      return date.format('D MMMM YYYY')
    case 'week':
      // return (
      //   <span>
      //     <span className="ScrollableDate-Week">
      //       {/*`Week ${date.week()}`*/}
      //     </span>
      //     <span className="ScrollableDate-Dates">
      //       {`${date.startOf('week').format(DATE_FORMAT_SHORT)} to ${date.endOf('week').format(DATE_FORMAT_SHORT)}, ${date.year()}`}
      //     </span>
      //     {/*<span className="ScrollableDate-Today">Today is {moment().format(DATE_FORMAT_WITH_DAY)}</span>*/}
      //     {/*isNotThisWeek && <span class="ScrollableDate-BackButton"><Button onClick={() => onBackToToday()}>Back to this Week</Button></span>*/}
      //   </span>)
      return `${date.startOf('week').format(DATE_FORMAT_SHORT)} to ${date.endOf('week').format(DATE_FORMAT_SHORT)}, ${date.year()}`
    case 'month':
      return date.format('MMMM YYYY')
    case 'year':
      return date.format('YYYY')
    default:
      return ''
  }
}

const getIsOutOfScope= (type, date) => {
  switch (type) {
    case 'day':
      return date.format('YYY-MM-DD') != moment().format('YYY-MM-DD')
    case 'week':
      return date.week() !== moment().week()
    case 'month':
      return date.format('MM-YYYY') !== moment().format('MM-YYYY')
    case 'year':
      return date.format('YYYY') !== moment().format('YYYY')
    default:
      return false
  }
}

const ScrollableDate = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [date, setDate] = useState(null)

  const onPanelChange = (value, mode) => {}

  const onSelect = (value) => {
    setDate(value)
  }

  const onChange = (value) => {}

  const onOk = () => {
    props.onDateSelected && props.onDateSelected(date)
    setShowModal(false)
  }
  const isOutOfScope = getIsOutOfScope(props.type, props.date)

  return (<React.Fragment>
    <ScrollableToolbar
      date={props.date}
      isOutOfScope={isOutOfScope}
      title={(
        <span className='ScrollableToolbar-Title'>
          {getTitle(props.type, props.date, props.onBackToToday)}
        </span>)}
      onForward={props.onForward}
      onBackward={props.onBackward}
      onBackToToday={props.onBackToToday}
      onTitleClick={() => setShowModal(true)} />
    <Modal
      visible={showModal}
      onOk={onOk}
      onCancel={() => setShowModal(false)}>
      <Calendar fullscreen={false} onPanelChange={onPanelChange} onSelect={onSelect} onChange={onChange}/>
    </Modal>
  </React.Fragment>)
}

export default ScrollableDate
