import React, { useState } from 'react'
import { Popover, Tooltip } from 'antd'
import { map, uniq } from 'lodash'

import { DATE_FORMAT_LONG } from '../../../../../utils/constants'
import { colorFor } from '../../../../../utils/utils'
import './NumberEntry.css'

const generateNumericOptions = (min, max, step) => {
  let i = 0
  let arr = []

  arr.push(min)
  for (i = (min - 1); i <= max; i += step){
    if (i >= min) {
      arr.push(i)
    }
  }

  arr = uniq(arr)

  return arr
}

const generateBooleanOptions = () => {
  return [[10,'Yes'],[0,'No']]
}

const formatOptionList = (number, theLessTheBest, idx, listLength) => {
  let formatedNumner = formatOption(number)

  if (theLessTheBest) {
    if (idx === 0) {
      formatedNumner = formatedNumner + " best"
    } else if (idx === listLength - 1) {
      formatedNumner = formatedNumner + " worst"
    }
  } else {
    if (idx === 0) {
      formatedNumner = formatedNumner + " worst"
    } else if (idx === listLength - 1) {
      formatedNumner = formatedNumner + "+ best"
    }
  }

  return formatedNumner
}

const formatOption = (number) => {
  if (number === 0) {
    return '0'
  } else if (number) {
    const decimal = (number % 1).toFixed(2)
    let fraction = ""
    switch (decimal.toString()) {
      case "0.25":
        fraction = "1/4"
        break;
      case "0.50":
        fraction = "1/2"
        break;
      case "0.75":
        fraction = "3/4"
        break;
      default:
          fraction = ""
          break;
    }

    return `${parseInt(number)} ${fraction}`
  } else {
    return ''
  }
}

const NumberEntry = (props) => {
  const [hovered, setHovered ] = useState(false)
  const handleHoverChange = visible => {
    setHovered(visible)
  };

  const onChangeHandler = (e) => {
    const value = e.target.value
    setHovered(false)
    props.onClick('numbers', {date: props.date, name: props.value, value: value, type: props.amPm } )
  }

  const selectOptions = props.listType == 'boolean' ? generateBooleanOptions() : generateNumericOptions(props.min,props.max,props.step)

  const selectContent = props.listType == 'boolean' ?
    (
      <select className="Form-Select" placeholder={props.units} onChange={onChangeHandler} style={{ width: 120 }}>
        <option value="" disabled selected>Yes/No</option>
        {map(selectOptions,([value, label]) => <option key={value} value={value}>{label}</option> )}
      </select>
    )
    :
    (
      <select className="Form-Select" placeholder={props.units} onChange={onChangeHandler} style={{ width: 120 }}>
        <option value="" disabled selected>{props.units}</option>
        {map(selectOptions,(i, idx) => <option key={i} value={i}>{formatOptionList(i, props.theLessTheBest, idx, selectOptions.length)}</option> )}
      </select>
    )

  const content = (
    <ul className="NumberEntry-List">
      {selectContent}
    </ul>
  )

  if (props.disabled) {
    return (
        <div>
          {props.displayValue && (
            <Tooltip title={props.date.format(DATE_FORMAT_LONG)}>
            <div className="NumberEntry-Value">{props.displayValue}</div>
            </Tooltip>
          )}
          <div className="NumberEntry-Bullet Bullet">
            {formatOption(parseFloat(props.label))}
          </div>
        </div>
    )
  } else {
    return (
        <div>
          {props.displayValue && (
            <Tooltip title={props.date.format(DATE_FORMAT_LONG)}>
            <div className="NumberEntry-Value">{props.displayValue}</div>
            </Tooltip>
          )}
          <Popover
            content={content}
            title={null}
            trigger="hover"
            placement="top"
            visible={hovered}
            onVisibleChange={handleHoverChange}
            >
          <div className="NumberEntry-Bullet Bullet" style={{backgroundColor:colorFor(parseFloat(props.label), props.min, props.max, props.theLessTheBest)}}>
            {formatOption(parseFloat(props.label))}
          </div>
          </Popover>
        </div>

    )
  }


}

export default NumberEntry
