export const WEEK_MOVE_FORWARD    = 'WEEK_MOVE_FORWARD'
export const WEEK_MOVE_BACKWARD   = 'WEEK_MOVE_BACKWARD'
export const WEEK_SELECT_DATE     = 'WEEK_SELECT_DATE'
export const WEEK_LOAD_TRACKERS   = 'WEEK_LOAD_TRACKERS'
export const WEEK_LOADING_TRACKERS_START = 'WEEK_LOADING_TRACKERS_START'
export const WEEK_LOAD_TRACKERS_DATA_START = 'WEEK_LOAD_TRACKERS_DATA_START'
export const WEEK_RESET           = 'WEEK_RESET'
export const TRACKER_CLICKED      = 'TRACKER_CLICKED'
export const DAY_RATE_CLICKED     = 'DAY_RATE_CLICKED'
export const MOOD_CLICKED         = 'MOOD_CLICKED'
export const INIT_MOOD_TRACKER    = 'INIT_MOOD_TRACKER'
export const WEEK_LOAD_DAY_RATES  = 'WEEK_LOAD_DAY_RATES'
export const WEEK_SET_COUNTERS_LOADED = 'WEEK_SET_COUNTERS_LOADED'
export const CHANGE_LOG_ENTRY       = 'CHANGE_LOG_ENTRY'
export const WEEK_LOG_ENTRY_SET   = 'WEEK_LOG_ENTRY_SET'
export const WEEK_REMOVE_TRACKER  = 'WEEK_REMOVE_TRACKER'
export const WEEK_UPDATE_TRACKER_NAME = 'WEEK_UPDATE_TRACKER_NAME'
export const WEEK_SET_FIELD         = 'WEEK_SET_FIELD'
export const WEEK_ON_BACK_TO_TODAY = 'WEEK_ON_BACK_TO_TODAY'
export const WEEK_FILTER_TRACKER_CHANGE = 'WEEK_FILTER_TRACKER_CHANGE'
export const WEEK_SET_MODAL_FEEDBACK = "WEEK_SET_MODAL_FEEDBACK"
export const WEEK_LOAD_TRACKER_JOURNAL = "WEEK_LOAD_TRACKER_JOURNAL"
export const WEEK_LOAD_TRACKER_JOURNAL_TODAY = "WEEK_LOAD_TRACKER_JOURNAL_TODAY"
export const WEEK_SET_JOURNAL_LIST = "WEEK_SET_JOURNAL_LIST"

export const MONTH_MOVE_FORWARD   = 'MONTH_MOVE_FORWARD'
export const MONTH_MOVE_BACKWARD  = 'MONTH_MOVE_BACKWARD'
export const GENERATE_MONTHS      = 'GENERATE_MONTHS'
export const MONTH_SET_NOW        = 'MONTH_SET_NOW'
export const MONTH_LOAD_TRACKERS  = 'MONTH_LOAD_TRACKERS'
export const MONTH_SET_SELECTORL  = 'MONTH_SET_SELECTORL'
export const MONTH_SET_SELECTOR_OPTIONS = 'MONTH_SET_SELECTOR_OPTIONS'
export const MONTH_SET_TRACKERS_DATA = 'MONTH_SET_TRACKERS_DATA'
export const MONTH_SET_FIELD = 'MONTH_SET_FIELD'
export const MONTH_ADD_TRACKER  = 'MONTH_ADD_TRACKER'
export const MONTH_ON_BACK_TO_TODAY = 'MONTH_ON_BACK_TO_TODAY'
export const MONTH_SET_COMPARE_TRACKER = 'MONTH_SET_COMPARE_TRACKER'

export const INSIGHT_MOVE_FORWARD = 'INSIGHT_MOVE_FORWARD'
export const INSIGHT_MOVE_BACKWARD = 'INSIGHT_MOVE_BACKWARD'
export const INSIGHT_DATE_SELECTED = 'INSIGHT_DATE_SELECTED'
export const INSIGHT_SET_DATA       = 'INSIGHT_SET_DATA'
export const INSIGHT_UNLOAD_DATA = 'INSIGHT_UNLOAD_DATA'
export const INSIGHT_ON_BACK_TO_TODAY = 'INSIGHT_ON_BACK_TO_TODAY'

export const AUTH_SIGN_IN_USER    = 'AUTH_SIGN_IN_USER'
export const AUTH_SUCCESS         = 'AUTH_SUCCESS'
export const AUTH_LOGOUT          = 'AUTH_LOGOUT'
export const AUTH_FIRST_TIME_SETUP = 'AUTH_FIRST_TIME_SETUP'
export const AUTH_HIDE_WELCOME_MESSAGE = 'AUTH_HIDE_WELCOME_MESSAGE'
export const AUTH_SET_ACCOUNT_PROPERTIES = "AUTH_SET_ACCOUNT_PROPERTIES"
export const AUTH_SET_HAS_TRACKERS = "AUTH_SET_HAS_TRACKERS"

export const TRACKER_STEP_ONE_CLICKED = 'TRACKER_STEP_ONE_CLICKED'
export const NEW_TRACKER_ON_PREV = 'NEW_TRACKER_ON_PREV'
export const NEW_TRACK_ON_CHECK_LIST_FINISHED = 'NEW_TRACK_ON_CHECK_LIST_FINISHED'
export const NEW_TRACK_ON_ADD_OPTION = 'NEW_TRACK_ON_ADD_OPTION'
export const NEW_TRACK_ON_NUMBERS_FINISHED = 'NEW_TRACK_ON_NUMBERS_FINISHED'
export const NEW_TRACK_ON_REMOVE_OPTION = 'NEW_TRACK_ON_REMOVE_OPTION'
export const NEW_TRACK_OPTIONS_FINISHED = 'NEW_TRACK_OPTIONS_FINISHED'
export const NEW_TRACKER_RESET_STEPS = 'NEW_TRACKER_RESET_STEPS'
export const NEW_TRACKER_SET_FIELD = 'NEW_TRACKER_SET_FIELD'
export const NEW_TRACK_ON_SELECT_OPTIONS_TRACK = 'NEW_TRACK_ON_SELECT_OPTIONS_TRACK'
export const NEW_TRACKE_ON_RESET_VALUES = 'NEW_TRACKE_ON_RESET_VALUES'
export const NEW_TRACKER_ON_CHECK_LIST_UPDATE = 'NEW_TRACKER_ON_CHECK_LIST_UPDATE'
export const NEW_TRACKER_SET_PREDEFINED_TRACKER = 'NEW_TRACKER_SET_PREDEFINED_TRACKER'

export const DAY_SET_FIELD = 'DAY_SET_FIELD'
export const DAY_SET_TRACKER_VALUES = 'DAY_SET_TRACKER_VALUES'
export const DAY_MOOD_CLICKED = 'DAY_MOOD_CLICKED'
export const DAY_INIT_MOOD_TRACKER = 'DAY_INIT_MOOD_TRACKER'
export const DAY_TRACKER_CLICKED = 'DAY_TRACKER_CLICKED'
export const DAY_CHANGE_LOG_ENTRY = 'DAY_CHANGE_LOG_ENTRY'
export const DAY_LOG_ENTRY_SET = 'DAY_LOG_ENTRY_SET'
export const DAY_MOVE_FORWARD = 'DAY_MOVE_FORWARD'
export const DAY_MOVE_BACKWARD = 'DAY_MOVE_BACKWARD'
export const DAY_DAY_RATE_CLICKED = 'DAY_DAY_RATE_CLICKED'
export const DAY_LOAD_DAY_RATES = 'DAY_LOAD_DAY_RATES'
export const DAY_UPDATE_TRACKER_NAME = 'DAY_UPDATE_TRACKER_NAME'
export const DAY_REMOVE_TRACKER = 'DAY_REMOVE_TRACKER'
export const DAY_ON_BACK_TO_TODAY = 'DAY_ON_BACK_TO_TODAY'


export const NEW_WATCHER_SET_TRACKERS = 'NEW_WATCHER_SET_TRACKERS'

export const WATCHERS_SET_FIELD = "WATCHERS_SET_FIELD"
export const WATCHERS_SET_WATCHER_RESULT = "WATCHERS_SET_WATCHER_RESULT"
export const NEW_WATCHER_SET_FIELD = "NEW_WATCHER_SET_FIELD"

export const WIZARD_SET_QUESTION = "WIZARD_SET_QUESTION"
export const WIZARD_INIT = "WIZARD_INIT"
export const WIZARD_ON_BACK = "WIZARD_ON_BACK"
export const WIZARD_START_OVER_QUESTIONNARE = "WIZARD_START_OVER_QUESTIONNARE"

export const EDIT_WATCHER_SET_FIELD = 'EDIT_WATCHER_SET_FIELD'

export const COMMON_SET_TOUR = 'COMMON_SET_TOUR'
