import React from 'react'
import { Progress, Button } from 'antd'
import { map } from 'lodash'
import Question from './Question/Question'

import { Box } from '../UI'

const Questionnaire = (props) => {
  const {
    onSetQuestion,
    onBack,
    questions,
    position,
    title,
    disabled,
    completed,
    questionareId,
    startOverQuestionairee
  } = props
  const totalQuestions = questions.length
  const positionInt = position ? position : 0
  const percent = parseInt(positionInt * 100 / totalQuestions)

  const questionsList = map(questions,((question, idx) => {
    if (idx === positionInt) {
      return (<Question
                key={question.question}
                question={question.question}
                answer={question.answer}
                value={question.value}
                position={positionInt}
                onBack={onBack}
                onClick={(value) => onSetQuestion(questionareId, title, question.value, value)} />)
    } else {
      return null
    }
  }))

  let markAsDisabled = false
  let boxStyle = {}
  if (completed) {
    boxStyle.border = '1px solid green';
  } else {
    markAsDisabled = disabled
  }

  return (
    <Box title={title} disabled={markAsDisabled} style={boxStyle}>
      <Progress percent={percent} />
      {!markAsDisabled && questionsList}
      {/*completed && (<Button shape="round" onClick={startOverQuestionairee}>Start over</Button>)*/}
    </Box>
  )
}

export default Questionnaire
