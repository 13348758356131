import React from 'react'
import { Button, Form, Input, InputNumber, Checkbox } from 'antd'
import { groupBy, map } from 'lodash'

import './WatcherForm.scss'

const WatcherForm = (props) => {
  const {
    trackers,
    buttonLabel,
    trackersInitialValue,
    watcherNameInitialValue,
    daysInitialValue,
    onFinish,
    history
  } = props
  const trackersGrouped = groupBy(trackers, t => t.category)

  return (
    <div className="WatcherForm">
    <Form layout='vertical' initialValues={ {layout: 'vertical', trackers: {} }} onFinish={onFinish}>
      <Form.Item name="name" label="Watcher Name" initialValue={watcherNameInitialValue} rules={[{ required: true,  message:'Please enter a watcher name!' }]}>
        <Input maxLength={20}/>
      </Form.Item>

      <Form.Item name="days" label="Days to watch" initialValue={daysInitialValue || 15} rules={[{ required: true,  message:'Please enter days to watch!' }]}>
        <InputNumber min={14} max={30} defaultValue={15}/>
      </Form.Item>

      {trackers && (
        <Form.Item name="checkbox-group" label="Trackers" initialValue={trackersInitialValue} rules={[
            { required: true, message: "Trackers is required!" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value && value.length < 2) {
                  return Promise.reject('Min number trackers selected should be two!');
                } else {
                  return Promise.resolve();
                }
              }
            })
          ]}>
          <Checkbox.Group>
          {
            map(trackersGrouped, (categoryTrackers, category ) => {
              return(
              <div>
                {category}
                  <ul>
                    {categoryTrackers.map(categoryTracker => {
                      return ( categoryTracker.id && (
                      <li key={categoryTracker.id}>
                        <Checkbox value={categoryTracker.id}>
                          <div className="WatcherForm-SwitchName">{categoryTracker.trackerName}</div>
                        </Checkbox>
                      </li>))
                    })}
                  </ul>
              </div>
              )
            })
          }
          </Checkbox.Group>
        </Form.Item>
      )}

      <div className="WatcherForm-Footer">
        <Button type='danger' shape='round' onClick={() => history.push('/watchers')} >Cancel</Button>
        <Button type="primary" htmlType="submit" shape="round">{buttonLabel}</Button>
      </div>
    </Form>
  </div>
  )
}

export default WatcherForm
