import React from 'react'
import { find } from 'lodash'

import { Popover,Button } from 'antd';

import './MoodSelector.scss'

class MoodSelector extends React.Component {
  state = {
    clicked: false,
    hovered: false,
  };

  moodClickHandler = (mood) => {
    this.props.onMoodClick(this.props.text, this.props.week, mood, this.props.date)
    this.hide()
  }

  hide = () => {
    this.setState({
      clicked: false,
      hovered: false,
    });
  };

  handleHoverChange = visible => {
    this.setState({
      hovered: visible,
      clicked: false,
    });
  };

  handleClickChange = visible => {
    this.setState({
      clicked: visible,
      hovered: false,
    });
  };



  render () {
    const liContent = this.props.moods.map(mood => {
      return (
        <li key={mood.value}>
          <Button
            className={`MoodSelector-MoodButton MoodSelector-MoodButton-${mood.label}`}
            onClick={() => this.moodClickHandler(mood.value)}>
            {mood.label}
          </Button>
        </li>
      )
    })
    const content = (
      <ul className="MoodSelector-Moods">
      {liContent}
      </ul>
    )
    const moodEntryClasses = ['MoodSelector']

    const mood = find(this.props.moods, (mood) => mood.value===this.props.value)

    if (mood) {
      moodEntryClasses.push(`MoodSelector-${mood.label}`)
    }

    // switch (this.props.value) {
    //   case 1:
    //     moodEntryClasses.push('MoodSelector-Awesome')
    //     break;
    //   case 2:
    //     moodEntryClasses.push('MoodSelector-Good')
    //     break;
    //   case 3:
    //     moodEntryClasses.push('MoodSelector-Alright')
    //     break;
    //   case 4:
    //     moodEntryClasses.push('MoodSelector-Meh')
    //     break;
    //   case 5:
    //     moodEntryClasses.push('MoodSelector-Horrible')
    //     break;
    //   default:
    // }

    if (this.props.disabled) {
      moodEntryClasses.push('MoodSelector-Disabled')
      return (
        <div className={moodEntryClasses.join(' ')}>
        {this.props.text}
        </div>
      )
    } else {
      return (
        <Popover
          content={content}
          title="Moods"
          trigger="hover"
          placement={this.props.placement}
          visible={this.state.hovered}
          onVisibleChange={this.handleHoverChange}
          >
          <div className={moodEntryClasses.join(' ')}>
          {this.props.text}
          </div>
        </Popover>
      )
    }


  }
}

export default MoodSelector;
