
export const trackers = [
  {
    trackerName: "Headache",
    category: "Mental Health",
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    amPm: false,
    theLessTheBest: true,
    units: "Intensity",
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Fatigue",
    category: "Mental Health",
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    amPm: false,
    theLessTheBest: true,
    units: "Intensity",
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Meditation",
    category: "Activities",
    units: "Minutes",
    amPm: false,
    minNumber: 1,
    maxNumber: 30,
    steps: 5,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 5
  },
  {
    trackerName: "Yoga",
    category: "Activities",
    units: "Hours",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 3
  },
  {
    trackerName: "Social life",
    category: "Activities",
    units: "Hours",
    amPm: false,
    minNumber: 1,
    maxNumber: 5,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 3
  },
  {
    trackerName: "Stress",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Mania",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Hypomania",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Risky behavior",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Elevated mood",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Intrusive thoughts",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Self-harm",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Mood swing",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Lack of pleasure",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Irritability",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Rumination",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Apathy",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Guilt",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Concentration",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Swimming",
    category: "Activities",
    units: "Minutes",
    amPm: false,
    minNumber: 1,
    maxNumber: 60,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 2
  },
  {
    trackerName: "Running",
    category: "Activities",
    units: "Minutes",
    amPm: false,
    minNumber: 1,
    maxNumber: 60,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 2
  },
  {
    trackerName: "Walking",
    category: "Activities",
    units: "Minutes",
    amPm: false,
    minNumber: 1,
    maxNumber: 60,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 3
  },
  {
    trackerName: "Intermittent fasting",
    category: "Activities",
    units: "True/False",
    amPm: false,
    minNumber: 1,
    maxNumber: 2,
    steps: 1,
    theLessTheBest: false,
    listType: 'boolean',
    timesAWeek: 7
  },
  {
    trackerName: "Drink water",
    category: "Activities",
    units: "Hours",
    amPm: false,
    minNumber: 1,
    maxNumber: 100,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Read",
    category: "Activities",
    units: "Minutes",
    amPm: false,
    minNumber: 1,
    maxNumber: 60,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Coffee",
    category: "Activities",
    units: "Cups",
    amPm: false,
    minNumber: 1,
    maxNumber: 5,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Exercise",
    category: "Activities",
    units: "Minutes",
    amPm: false,
    minNumber: 1,
    maxNumber: 60,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 3
  },
  {
    trackerName: "Physical Pain",
    category: "Life Style",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Sleep",
    category: "Life Style",
    units: "Hours",
    amPm: false,
    minNumber: 1,
    maxNumber: 8,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Television",
    category: "Activities",
    units: "Hours",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Work",
    category: "Activities",
    units: "Hours",
    amPm: false,
    minNumber: 1,
    maxNumber: 8,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 5
  },
  {
    trackerName: "Anxiety",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Mood",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Sadness",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Worry",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Intrusive Thought",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Panic",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Insomnia",
    category: "Mental Health",
    units: "Yes/No",
    amPm: false,
    minNumber: 1,
    maxNumber: 5,
    steps: 1,
    theLessTheBest: true,
    listType: 'boolean',
    timesAWeek: 7
  },
  {
    trackerName: "Restlessness",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Energized",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'boolean',
    timesAWeek: 7
  },
  {
    trackerName: "Euphoria",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Fear",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Intense Worry",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Hopelessness",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Helplessness",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Self-worth",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Engaged in Hobbies",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Pleasure",
    category: "Mental Health",
    units: "Intensity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Mindfulness",
    category: "Activities",
    units: "Minutes",
    amPm: false,
    minNumber: 1,
    maxNumber: 30,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 3
  },
  {
    trackerName: "Activities of Daily Living",
    category: "Activities",
    units: "Productivity",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Smoking",
    category: "Life Style",
    units: "Cigarettes",
    amPm: false,
    minNumber: 1,
    maxNumber: 20,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Alcohol",
    category: "Life Style",
    units: "Glasses",
    amPm: false,
    minNumber: 1,
    maxNumber: 5,
    steps: 1,
    theLessTheBest: true,
    listType: 'numbers',
    timesAWeek: 7
  },
  {
    trackerName: "Healthy Eating",
    category: "Life Style",
    units: "Rate",
    amPm: false,
    minNumber: 1,
    maxNumber: 10,
    steps: 1,
    theLessTheBest: false,
    listType: 'numbers',
    timesAWeek: 7
  }
]


export const getPredefinedTrackers = () => { return trackers }
