import { isUndefined } from 'lodash'

export const perc2color = (perc) => {
	var r, g, b = 0;
	if(perc < 50) {
		r = 255;
		g = Math.round(5.1 * perc);
	}
	else {
		g = 255;
		r = Math.round(510 - 5.10 * perc);
	}
	var h = r * 0x10000 + g * 0x100 + b * 0x1;
	return '#' + ('000000' + h.toString(16)).slice(-6);
}

export const colorFor = (value, min, max, theLessTheBest) => {
  if (isUndefined(value)) {
    return 'white'
  }

  let x = null
  if (theLessTheBest) {
    x = (max-value) * 100 / ((1 + max)-min)
  } else {
    x = value * 100 / ((1 + max)-min)
  }

	if (x > 100) {
    x = 100
  }
  return perc2color(x)
}

export const colorForTracker = (value, options) => {
  return colorFor(value, options.minNumber, options.maxNumber, options.theLessTheBest)
}

export const formatNumber = (number) => {
  if (number) {
    return number.toLocaleString(undefined,{ minimumFractionDigits: 2,  maximumFractionDigits: 2 })
  } else {
    return 0
  }
}

export const getWatcherScoreColor = (score) => {
  if (score == 0 || !score) {
    return "#eee"
  } else {
    return perc2color((10-score) * 10)
  }
}

export const std = (arr) => {
  // Creating the mean with Array.reduce
  let mean = arr.reduce((acc, curr)=>{
    return acc + curr
  }, 0) / arr.length;

  // Assigning (value - mean) ^ 2 to every array item
  arr = arr.map((k)=>{
    return (k - mean) ** 2
  })

  // Calculating the sum of updated array
 let sum = arr.reduce((acc, curr)=> acc + curr, 0);

 // Calculating the variance
 let variance = sum / arr.length

 // Returning the Standered deviation
 return Math.sqrt(variance)
}
