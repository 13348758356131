import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mergeClassNames from 'merge-class-names';
import {isNil,startsWith} from 'lodash'

import { tileProps } from './shared/propTypes';

function getValue(nextProps, prop) {
  const { activeStartDate, date, view } = nextProps;

  return typeof prop === 'function'
    ? prop({ activeStartDate, date, view })
    : prop;
}

export default class Tile extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { tileClassName, tileContent } = nextProps;

    const nextState = {};

    if (tileClassName !== prevState.tileClassNameProps) {
      nextState.tileClassName = getValue(nextProps, tileClassName);
      nextState.tileClassNameProps = tileClassName;
    }

    if (tileContent !== prevState.tileContentProps) {
      nextState.tileContent = getValue(nextProps, tileContent);
      nextState.tileContentProps = tileContent;
    }

    return nextState;
  }

  state = {};

  render() {
    const {
      activeStartDate,
      children,
      classes,
      date,
      formatAbbr,
      locale,
      maxDate,
      maxDateTransform,
      minDate,
      minDateTransform,
      onClick,
      onMouseOver,
      style,
      tileDisabled,
      view,
    } = this.props;
    const { tileClassName, tileContent } = this.state;

    let buttonClasses = null
    let color = null

    if (tileClassName && tileClassName.pm) {
      buttonClasses = mergeClassNames(classes)
    } else {
      if (startsWith(tileClassName, '#')) {
        color = tileClassName
        buttonClasses = mergeClassNames(classes)
      } else {
        buttonClasses = mergeClassNames(classes, tileClassName)
      }
    }

    if (tileClassName && tileClassName.pm) {
      const colorPm = isNil(tileClassName.pm) ? 'white' : tileClassName.pm
      const colorAm = isNil(tileClassName.am) ? 'white' : tileClassName.am

      style.background = `linear-gradient(to right, ${colorAm} 50%, ${colorPm} 50%)`
    }

    if (color) {
      style.background = color
    }

    return (
      <div
        className={buttonClasses}
        disabled={
          (minDate && minDateTransform(minDate) > date)
          || (maxDate && maxDateTransform(maxDate) < date)
          || (tileDisabled && tileDisabled({ activeStartDate, date, view }))
        }
        onClick={onClick && (event => onClick(date, event))}
        onFocus={onMouseOver && (() => onMouseOver(date))}
        onMouseOver={onMouseOver && (() => onMouseOver(date))}
        style={style}
        type="button"
      >
      {/*
        tileClassName.pm &&
        (
        <div className='react-calendar_pm'>
          <div className={`react-calendar-tile-mood ${tileClassName.pm}`} >
          </div>
          <div className={`react-calendar-tile-mood ${tileClassName.am}`}>
        </div>
      </div>)
      */}
        {formatAbbr
          ? (
            <abbr aria-label={formatAbbr(locale, date)} className="react-calendar__number">
              {children}
            </abbr>
          )
          : children}
        {tileContent}

      </div>
    );
  }
}

Tile.propTypes = {
  ...tileProps,
  children: PropTypes.node.isRequired,
  formatAbbr: PropTypes.func,
  maxDateTransform: PropTypes.func.isRequired,
  minDateTransform: PropTypes.func.isRequired,
};
