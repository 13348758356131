import * as actionTypes from './actionTypes'
import { commonSetTour } from './common'

import { auth } from '../../utils/firebase'

import client from '../../services/client'

export const authFirstTimeSetup = (value) => {
  return {
    type: actionTypes.AUTH_FIRST_TIME_SETUP,
    value: value
  }
}

export const authSignInUserInit = (authResult) => {
  return dispach => {
    if (authResult.additionalUserInfo.isNewUser) {
      window.gtag("first_visit")
    }
    window.gtag('event','session_start')
    client.setAccountTimestamp(authResult.user)
    // dispach(authFirstTimeSetup(authResult.additionalUserInfo.isNewUser))
    dispach(authSignInUser(authResult))
    window.gtag('config', 'G-63HVRYEYPZ', { user_id: authResult.user.id });
    client.getAccountProperties(authResult.user.uid).then(properties => {
      dispach(setAccountProperties(properties))
      if (properties) {
        dispach(commonSetTour(properties.isTourActive))
      }
    })
  }
}

export const setAccountProperties = (properties) => {
  return {
    type: actionTypes.AUTH_SET_ACCOUNT_PROPERTIES,
    properties: properties
  }
}

export const setAuthHasTrackers = () => {
  return {
    type: actionTypes.AUTH_SET_HAS_TRACKERS
  }
}

export const authSignInUser = (authResult) => {
  localStorage.setItem('uid', authResult.user.uid)
  localStorage.setItem('username', authResult.user.displayName)
  localStorage.setItem('email', authResult.user.email)

  return {
    type: actionTypes.AUTH_SIGN_IN_USER,
    authResult: authResult
  }
}

export const authSuccess = (uid, username, email) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    uid: uid,
    username: username,
    email: email
  }
}

export const logout = () => {
  return dispach => {
    auth.signOut().then(() => {
      localStorage.removeItem('uid')
      localStorage.removeItem('username')
      localStorage.removeItem('email')
      dispach(initLogout())
    })
  }
}

export const initLogout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

export const authInitSignInUser = () => {
  return dispach => {
    const uid  = localStorage.getItem('uid')
    const username = localStorage.getItem('username')
    const email = localStorage.getItem('email')
    if (!uid) {
      dispach(logout())
    } else {
      client.getAccountProperties(uid).then(properties => {
        dispach(setAccountProperties(properties))
        if (properties) {
          dispach(commonSetTour(properties.isTourActive))
        }
      })
      window.gtag('config', 'G-63HVRYEYPZ', { user_id: uid });
      dispach(authSuccess(uid, username, email))
    }
  }
}

export const authHideWelcomeMessage = () => {
  return {
    type: actionTypes.AUTH_HIDE_WELCOME_MESSAGE
  }
}
