import * as actionTypes from '../actions/actionTypes'

const initialState = {
  trackers: [],
  watcher: null
}

const editWatcherSetField = (state, action) => {
  return {
    ...state,
    [action.field]: action.value
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EDIT_WATCHER_SET_FIELD: return editWatcherSetField(state, action)
    default: return state;
  }
}

export default reducer
