import React from 'react'
import { map, capitalize } from 'lodash'
import { Line} from 'react-chartjs-2'
import moment from 'moment'
import { iterateDates } from '../../utils/dates'

const COLORS = ['green','blue','red','yellow','fuchsia','aqua','lime','black','purple','olive']

const COLORS_BG = ['rgb(0, 128, 0, 0.2)','rgb(0, 0, 255, 0.2)','rgb(255, 0, 0,0.2)','rgb(255, 255, 0,0.2)','rgb(255, 0, 255,0.2)','rgb(0, 255, 255,0.2)','rgb(0, 255, 0,0.2)','rgb(0, 0, 0,0.2)','rgb(128, 0, 128,0.2)','rgb(128, 128, 0, 0.2)']

const getDataSet = (days, { options, values}, idx) => {
  return {
          label: `${capitalize(options.trackerName)} ${capitalize(options.units)}`,
          data: values,
          backgroundColor: COLORS_BG[idx],
          borderColor: COLORS[idx],
          borderWidth: 1,
      }
}

const convertToData = (props) => {
  const dates = iterateDates(moment().subtract(props.days,'days'),moment(), d => d)
  const scores = map(props.trackers.score, s => s)
  const dataSets = map(scores, (data, idx) => {
    return getDataSet(props.days, data, idx)
  })

  return {
    labels: dates.map(i => i.date()),
    datasets: dataSets
  }
}

const converToOptions = (props) => {
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMax: 10
          },
        },
      ],
    },
  }

  return options
}

const WatcherChart = (props) => {
  const data = convertToData(props)
  const options = converToOptions(props)

  return (
    <Line data={data} options={options} />
  )
}

export default WatcherChart
