import React from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'

import Layout from '../Layout/Layout'
import { Box } from '../../components/UI'

import './Account.scss'

const Value = ({ name, value }) => {
  return (
    <div className="Account-Value">
      <label>{name}:</label>
      {value}
    </div>
  )
}

class Account extends React.Component {

  render() {
    const {
      uid,
      username,
      email
    } = this.props
    return (
      <Layout>
        <div className="Container">
              <Row justify="center" style={{marginTop:10}}>
                <Col xl={14} lg={14} md={24} xs={24} sm={24}>
                  <Box title="Account Information">
                    <div className="Account">
                      {/*<Value name="User ID" value={uid} />*/}
                      <Value name="Username" value={username} />
                      <Value name="Email" value={email} />
                    </div>
                  </Box>
                </Col>
              </Row>
        </div>
      </Layout>
    )
  }
}

const mapStateToProp = state => {
  return {
    uid: state.auth.uid,
    accountProperties: state.auth.accountProperties,
    username: state.auth.username,
    email: state.auth.email
  }
}

export default connect(mapStateToProp)(Account)
