export const firebaseConfig = {
    apiKey: "AIzaSyALxwCoJ532tgBUyXnzAUk-X-903fYgCX0",
    authDomain: "moods-webapp.firebaseapp.com",
    databaseURL: "https://moods-webapp.firebaseio.com",
    projectId: "moods-webapp",
    storageBucket: "moods-webapp.appspot.com",
    messagingSenderId: "979143392629",
    appId: "1:979143392629:web:a0002635bfb4a29bdde73c"
};

window.firebase.initializeApp(firebaseConfig);

export const db = window.firebase.firestore()

export const auth = window.firebase.auth()

export const analytics = window.firebase.analytics()

export async function deleteCollection(db, collectionPath, batchSize) {
  const collectionRef = db.collection(collectionPath);
  const query = collectionRef.orderBy('__name__').limit(batchSize);

  return new Promise((resolve, reject) => {
    deleteQueryBatch(db, query, resolve).catch(reject);
  });
}

export async function deleteQueryBatch(db, query, resolve) {
  const snapshot = await query.get();

  const batchSize = snapshot.size;
  if (batchSize === 0) {
    // When there are no documents left, we are done
    resolve();
    return;
  }

  // Delete documents in a batch
  const batch = db.batch();
  snapshot.docs.forEach((doc) => {
    batch.delete(doc.ref);
  });
  await batch.commit();

  // Recurse on the next process tick, to avoid
  // exploding the stack.
  process.nextTick(() => {
    deleteQueryBatch(db, query, resolve);
  });
}
