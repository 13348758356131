import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button } from 'antd'
import { every } from 'lodash'
import { Link } from "react-router-dom";

import * as actions from '../../store/actions'
import QuestionnaireList from '../../components/QuestionnaireList/QuestionnaireList'
import AnswerRecomendations from '../../components/AnswersRecomendations/AnswersRecomendations'


// const PrettyPrintJson = ({data}) => (<div><pre>{JSON.stringify(data, null, 2) }</pre></div>);

class Wizard extends React.Component {

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  componentDidMount() {
    this.props.onInit()
  }

  onRouteChanged() {
    this.props.onInit()
  }

  handleOnCreate = (values, trackers) => {
    this.props.onCreate(this.props.uid, values, trackers)
  }

  handleOnSkip = () => {
    this.props.onSkip(this.props.uid)
  }

  render() {
    const {
      uid,
      questions,
      current,
      onSetQuestion,
      questionnairePosition,
      onBack,
      startOverQuestionairee,
      username,
      onSkip
    } = this.props

    const isCompleted = every(questions, q => questionnairePosition[q.title] && questionnairePosition[q.title] == q.questions.length)

    return (
        <div className="Container">
          <Row justify="center" style={{marginTop:10}}>
            <Col xl={14} lg={14} md={24} xs={24} sm={24}>
              <div style={{height:50}}>
                <div className="pull-left"><Link to="/logout">Logout {username}</Link></div>
                <div className="pull-right" style={{textAlign:'right'}}>
                  <Button shape="round" onClick={this.handleOnSkip}>Skip and create a blank template</Button>
                </div>
              </div>

              {!isCompleted && (
                <QuestionnaireList
                  questions={questions}
                  current={current}
                  onSetQuestion={onSetQuestion}
                  questionnairePosition={questionnairePosition}
                  onBack={onBack}
                  startOverQuestionairee={startOverQuestionairee}
                />)}

              {isCompleted && (
                <AnswerRecomendations
                  answers={this.props.answers}
                  onStartOver={this.props.onInit}
                  onCreate={this.handleOnCreate}
                  />
              )}

              {process.env.NODE_ENV === 'development' && (
                <div>{this.props.uid}</div>
              )}
            </Col>
          </Row>
        </div>
      )
  }
}

const mapStateToProp = state => {
  return {
    uid: state.auth.uid,
    username: state.auth.username,
    current: state.wizard.current,
    questions: state.wizard.questions,
    answers: state.wizard.answers,
    questionnairePosition: state.wizard.questionnairePosition
  }
}

const mapActionsToProps = dispach => {
  return {
    onNext: () => dispach(actions.wizardNext()),
    onPrev: () => dispach(actions.wizardPrev()),
    onInit: () => dispach(actions.wizardOnInit()),
    onSetQuestion: (idx, title, question, answer) => dispach(actions.wizardSetQuestion(idx, title, question, answer)),
    onBack: (questionareId, title, position) => dispach(actions.wizardOnBack(questionareId, title, position)),
    startOverQuestionairee: (idx, questionaare, current) => dispach(actions.wizardStartOverQuestionairee(idx, questionaare, current)),
    onCreate: (uid, values, trackers) => dispach(actions.wizardOnCreate(uid, values, trackers)),
    onSkip: (uid) => dispach(actions.wizardOnSkip(uid))

  }
}

export default connect(mapStateToProp, mapActionsToProps)(Wizard);
