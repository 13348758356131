import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Layout from '../Layout/Layout'
import { Row, Col } from 'antd';
import { Container } from '../../components/UI'
import Step1 from '../../components/NewTracker/Step1/Step1'
import Step2 from '../../components/NewTracker/Step2/Step2'
import Step3 from '../../components/NewTracker/Step3/Step3'
import * as actions from '../../store/actions'
import { Tracker, Box, Alert } from '../../components/UI'

import './NewTracker.css'

// const { Step } = Steps;
//
// const steps = [
//   {
//     title: 'Chose Type',
//     content: <Step1/>,
//   },
//   {
//     title: 'Configure',
//     content: 'Second-content',
//   },
//   {
//     title: 'Preview',
//     content: 'Last-content',
//   },
// ];


class NewTracker extends React.Component {
  state = { selected: {} }

  resetValues = () => {
    this.setState({
      options: { selections:[], trackerName: ''},
      checklist: { trackerName: '' }
    })
  }

  componentDidMount() {
    this.props.onLoadCategories(this.props.uid)
    this.props.onLoadTrackerNames(this.props.uid)
  }

  componentWillUnmount() {
    this.props.onResetValues()
  }

  onClickHandler = (type, values) => {
    const selected = this.state.selected
    switch (type) {
      case 'checklist':
          const { name, value, type } = values
          if (type) {
            selected[name] = {...selected[name], [type]: !value }
            this.setState({selected:{...selected}})
          } else {
            selected[name] = !value
            this.setState({selected:{...selected}})
          }
        break;
      case 'options':
        if (values.type) {
          selected[values.name] = {...selected[values.name], [values.type]: values.value }
          this.setState({selected:{...selected}})
        } else {
          selected[values.name] = values.value
          this.setState({selected:{...selected}})
        }
        break;
      case 'numbers':
        if (values.type) {
          const type = values.type
          selected[values.name] = { ...selected[values.name], [type]: values.value }
        } else {
          selected[values.name] = values.value
        }

        this.setState({selected:{...selected}})
        break;
      default:
    }

  }

  render () {
    const { current, uid } = this.props;
    const configuration = this.props[this.props.trackerType]

    return (
      <Layout>
      <Container>
      <div>
        {/*<h1>Create a new tracker</h1>*/}
        <Alert style={{margin:20}} message="A tracker helps you follow and note the progress of the variable you want to observe" type="info"/>
          {/*<Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>*/}
          <Row>
            <Col xl={12} lg={12} md={24} xs={24} sm={24}>
              <div className="NewTracker-StepsContent">
                {current === 0 && <Step1
                                    onClick={this.props.onStep1Clicked}
                                    predefinedTrackers={this.props.predefinedTrackers}/>}
                {current === 1 && <Step2
                                      trackerType={this.props.trackerType}
                                      onOptionAdded={this.props.onAddOption}
                                      onRemoveOption={this.props.onRemoveOption}
                                      onNumbersFinished={this.props.onNumbersFinished}
                                      onChecklistFinished={this.props.onChecklistFinished}
                                      onOptionsFinished={this.props.onOptionsFinished}
                                      options={this.props.options}
                                      checklist={this.props.checklist}
                                      numbers={this.props.numbers}
                                      prev={this.props.onPrev}
                                      onLoadTrackers={() => this.props.onLoadTrackers(uid)}
                                      trackers={this.props.trackers}
                                      onSelectOptionsTrack={this.props.onSelectOptionsTrack}
                                      categories={this.props.categories}
                                      trackerNames={this.props.trackerNames}
                                      onChecklistUpdate={this.props.onChecklistUpdate}
                                      onOptionsUpdate={this.props.onOptionsUpdate}
                                      onNumbersUpdate={this.props.onNumbersUpdate}
                                      configuration={this.props[this.props.trackerType]}
                                      onCreate={(type, values) => this.props.onCreate(uid, type, values, this.props.history)}
                                      onSelectPredefinedTracker={this.props.onSelectPredefinedTracker}
                                      predefinedTrackerSelected={this.props.predefinedTrackerSelected}
                                      />}
                {current === 2 && <Step3
                                    trackerType={this.props.trackerType}
                                    configuration={this.props[this.props.trackerType]}
                                    onCreate={(type, values) => this.props.onCreate(uid, type, values, this.props.history)}
                                    prev={this.props.onPrev}
                                    />}
                {/*steps[current].content*/}
              </div>
        </Col>
        <Col xl={12} lg={12} md={24} xs={24} sm={24}>
          <div className="Steps-Preview">
            <h1>Preview</h1>

            { this.props.trackerType ? (
            <Box title={configuration && configuration.category}>
              <Tracker demo type={this.props.trackerType} configuration={{onClick: this.onClickHandler, entries:this.state.selected,...configuration}} date={moment().startOf('week')}/>
            </Box>): <p>Nothing selected yet</p>}
          </div>
        </Col>
        </Row>
      </div>

      </Container>
      </Layout>
    )
  }
}

const mapStateToProp = state => {
  return {
    current: state.newTracker.current,
    trackerType: state.newTracker.trackerType,
    options: state.newTracker.options,
    numbers: state.newTracker.numbers,
    checklist: state.newTracker.checklist,
    uid: state.auth.uid,
    trackers: state.newTracker.trackers,
    categories: state.newTracker.categories,
    trackerNames: state.newTracker.trackerNames,
    predefinedTrackers: state.newTracker.predefinedTrackers,
    predefinedTrackerSelected: state.newTracker.predefinedTrackerSelected
  }
}

const mapActionsToProps = dispach => {
  return {
    onChecklistFinished: (values) => dispach(actions.newTrackerOnCheckListFinished(values)),
    onNumbersFinished: (values) => dispach(actions.newTrackerOnNumbersFinished(values)),
    onOptionsFinished: (values) => dispach(actions.newTrackerOnOptionsFinished(values)),
    onRemoveOption:(index) => dispach(actions.newTrackerOnRemoveOption(index)),
    onAddOption:(values) => dispach(actions.newTrackerOnAddOption(values)),
    onPrev: () => dispach(actions.newTrackOnPrev()),
    onNex: () => dispach(actions.newTrackerOnNext()),
    onResetValues: ()=> dispach(actions.newTrackerOnResetValues()),
    onStep1Clicked: (value) => dispach(actions.newTrackerOnStep1Clicked(value)),
    onCreate: (uid, type, values, history) => dispach(actions.newTrackOnCreate(uid, type, values, history)),
    onLoadTrackers: (uid) => dispach(actions.newTrackOnLoadTrackers(uid)),
    onSelectOptionsTrack: (track) => dispach(actions.newTrackOnSelectOptionsTrack(track)),
    onLoadCategories: (uid) => dispach(actions.newTrackLoadCategories(uid)),
    onLoadTrackerNames: (uid) => dispach(actions.newTrackerLoadTrackerNames(uid)),
    onChecklistUpdate: (values) => dispach(actions.newTrackerTypeUpdate('checklist',values)),
    onOptionsUpdate: (values) => dispach(actions.newTrackerTypeUpdate('options',values)),
    onNumbersUpdate: (values) => dispach(actions.newTrackerTypeUpdate('numbers',values)),
    onSelectPredefinedTracker: (value) => dispach(actions.newTrackerSelectPredefinedTracker(value))
  }
}

export default connect(mapStateToProp,mapActionsToProps)(NewTracker);

// <div className="NewTracker-StepsAction">
//   {current < steps.length - 1 && (
//     <Button type="primary" onClick={() => this.next()}>
//       Next
//     </Button>
//   )}
//   {current === steps.length - 1 && (
//     <Button type="primary" onClick={() => message.success('Processing complete!')}>
//       Done
//     </Button>
//   )}
//   {current > 0 && (
//     <Button style={{ margin: '0 8px' }} onClick={() => this.prev()}>
//       Previous
//     </Button>
//   )}
// </div>
