import * as actionTypes from '../actions/actionTypes'

const initialState = {
  uid: null,
  isAuthLoading: true
}

const authSignInUser = (state, action) => {
  return {
    ...state,
    uid: action.authResult.user.uid,
    username: action.authResult.user.displayName,
    email: action.authResult.user.email
  }
}

const authSuccess = (state, action) => {
  return {
    ...state,
    uid: action.uid,
    username: action.username,
    email: action.email,
    isAuthLoading: false
  }

}

const authLogout = (state) => {
  return {
    ...state,
    uid: null,
    isAuthLoading: false
  }
}

const firstTimeSetup = (state, action) => {
  return  {
    ...state,
    firstTimeSetup: action.value,
    welcomeMessage: action.value
  }
}

const authHideWelcomeMessage = (state) => {
  return {
    ...state,
    welcomeMessage: false,
    firstTimeSetup: false
  }
}

const setAccountProperties = (state, action) => {
  return {
    ...state,
    accountProperties: action.properties
  }
}

const setAuthHasTrackers = (state) => {
  return  {
    ...state,
    accountProperties: {
      ...state.accountProperties,
      hasTrackers: true
    }
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.AUTH_SIGN_IN_USER: return authSignInUser(state, action)
  case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
  case actionTypes.AUTH_LOGOUT: return authLogout(state, action)
  case actionTypes.AUTH_FIRST_TIME_SETUP: return firstTimeSetup(state, action)
  case actionTypes.AUTH_HIDE_WELCOME_MESSAGE: return authHideWelcomeMessage(state, action)
  case actionTypes.AUTH_SET_ACCOUNT_PROPERTIES: return setAccountProperties(state, action)
  case actionTypes.AUTH_SET_HAS_TRACKERS: return setAuthHasTrackers(state, action)
  default: return state
  }
}

export default reducer
