import React, { useState } from 'react'
import { Popover, Button } from 'antd'
import './OptionsEntry.css'

const OptionsEntry = (props) => {
  // const [clicked, setClicked ] = useState(false)
  const [hovered, setHovered ] = useState(false)
  const handleHoverChange = visible => {
    setHovered(visible)
    // setClicked(false)
  };

  const handleClickChange = (visible, label) => {
    // setClicked(visible)
    setHovered(false)
    if (props.amPm) {
      props.onClick && props.onClick('options', { date: props.date, name: props.value, value: label, type: props.displayValue })
    } else {
      props.onClick && props.onClick('options', { date: props.date, name: props.value, value: label })
    }
  };

  const keys = Object.keys(props.selections)

  const liContent = keys.map(k => (<li key={props.selections[k].label}>
                                    <Button
                                        style={{backgroundColor:`${props.selections[k].color}`}}
                                        className="OptionsEntry-Button OptionsEntry-Button-Awesome"
                                        onClick={() => handleClickChange(false, props.selections[k].label)}>{
                                          props.selections[k].label}
                                    </Button>
                                  </li>))

  let color = null;
  for (let key in props.selections) {
    if (props.selections[key].label === props.selection) {
      color = props.selections[key].color
    }
  }


  const content = (
    <ul className="OptionsEntry-List">
      {liContent}
    </ul>
  )

  if (props.disabled) {
    return (<div style={{backgroundColor:color}} className="Bullet">
      {props.displayValue}
    </div>)
  } else {
    return (
      <Popover
        content={content}
        title={null}
        trigger="hover"
        placement="top"
        visible={hovered}
        onVisibleChange={handleHoverChange}
        >
        <div style={{backgroundColor:color}} className="Bullet">
          {props.displayValue}
        </div>
      </Popover>
    )
  }
}

export default OptionsEntry
