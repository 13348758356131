import React from 'react'

import './BulletSelector.css'

const BulletSelector = (props) => {
  // const n = [1,2,3,4,5,6,7,8,9,10]
  return (
      <ul className="BulletSelector-List">
        {props.items.map((n) =>{
          const classes = ["Item"]

          if (props.value === n) {
            classes.push("Item-Active")
          }

          return <li key={n}><div onClick={() =>props.onClick && props.onClick(n)} className={classes.join(' ')}>{n}</div></li>
        })}
      </ul>
  )
}

export default BulletSelector
