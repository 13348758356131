import React from 'react'
import { Link } from 'react-router-dom'
import { TwitterOutlined, FacebookOutlined } from '@ant-design/icons';

import './Footer.scss'

const Footer = () => {
  return (
    <footer>
      <div className="Footer">
        <div>
          <ul>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li><a href="mailto:contact@trackerbuddy.io">Contact</a></li>
          </ul>
        </div>
        <div>
          <ul>
            <li><a href="/terms.html" target="_blank">Terms of Service</a></li>
            <li><a href="/privacy.html" target="_blank">Privacy policy</a></li>
            <li><a href="/cookies.html" target="_blank">Cookies policy</a></li>
          </ul>
        </div>
        <div>
          <ul>
            <li><a href="https://www.facebook.com/profile.php?id=100073490073816" target="_blank" rel="noreferrer"><FacebookOutlined />Facebook</a></li>
            {/*<li><a href="/privacy.html" target="_blank"><TwitterOutlined />Twitter</a></li>*/}
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
