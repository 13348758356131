import React from 'react'
import { sortBy, groupBy, toLower, map } from 'lodash'

import TrackerFastEntry from './TrackerFastEntry/TrackerFastEntry'
import './TrackersFastEntry.scss'
import { Box } from '../UI'

const sortByName = (trackers) => sortBy(trackers, t => t.trackerName )


const Category = ({ category, trackers, onTrackerClicked, uid }) => {
  const list = sortByName(trackers).map(tracker => {
    return <TrackerFastEntry
              key={tracker.id}
              tracker={tracker}
              onTrackerClicked={(trackerType, values, index, id) => onTrackerClicked(uid, id,values, trackerType, index, id)}
              />
  })

  return (
    <Box title={`${category} Trackers`}>
      <div style={{textAlign:'left'}}>{list}</div>
    </Box>
  )
}


const TrackersFastEntry = (props) => {
  const { trackers, onTrackerClicked, uid } = props
  const trackersWithIndex = trackers.map((tracker, index) => { return {...tracker, index: index } })
  const tracksGrouped = groupBy(trackersWithIndex, t => toLower(t.category))

  if (trackers) {
    return (
      <div className="TrackersFastEntry-List">
        {map(tracksGrouped, (trackers, category) => {
          return <Category
                    category={category}
                    trackers={trackers}
                    onTrackerClicked={onTrackerClicked}
                    uid={uid}
                  />
        })}
      </div>
    )
  } else {
    return null
  }
}

export default TrackersFastEntry
