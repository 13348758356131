import React from 'react'
import moment from 'moment'
import { Tooltip } from 'antd'

import './Options.css'
import { DATE_FORMAT_LONG, FORMAT_DAY, DATE_FORMAT} from '../../../../utils/constants'
import { generateWeekDates } from '../../../../utils/dates'

import OptionsEntry from './OptionsEntry/OptionsEntry'

const Options = (props) => {
  let entries = []
  if (props.format === 'day') {
    entries = [props.date]
  } else {
    entries = generateWeekDates(props.date)
  }
  let content = null

  if (props.amPm) {
    content = (
      <ul className="Options">
        {entries.map(entry => {
          const displayValue = entry.format(FORMAT_DAY)
          const entryValue = entry.format('YYYY-MM-DD')
          let isCurrentDate = false
          let isFutureDate = false
          if (entry.format(DATE_FORMAT) === moment().format(DATE_FORMAT)) {
            isCurrentDate = true
          }
          isFutureDate = entry.diff(moment()) > 0

          const cssClasses = []

          if (isCurrentDate) {
            cssClasses.push('Tracker-IsCurrentDate')
          }
          if (isFutureDate) {
            cssClasses.push('Tracker-isFutureDate')
          }

          return (<li key={entryValue} className={cssClasses.join(' ')}>
                      <div>
                        <div>
                          <Tooltip title={entry.format(DATE_FORMAT_LONG)}>{displayValue}</Tooltip>
                        </div>
                        <div>
                        <OptionsEntry
                            disabled={isFutureDate}
                            date={entry}
                            amPm
                            displayValue="AM"
                            value={entryValue}
                            selection={props.entries[entryValue] && props.entries[entryValue]['AM']}
                            selections={props.selections}
                            onClick={isFutureDate ? null : props.onClick}/>
                        </div>
                        <div>
                        <OptionsEntry
                            disabled={isFutureDate}
                            date={entry}
                            amPm
                            displayValue="PM"
                            value={entryValue}
                            selection={props.entries[entryValue] && props.entries[entryValue]['PM']}
                            selections={props.selections}
                            onClick={isFutureDate ? null : props.onClick}/>
                        </div>
                      </div>
                  </li>)
                }
              )
      }
    </ul>
    )
  } else {
    content = (<ul className="Options">
      {entries.map((entry) => {
        const displayValue = parseInt(entry.format('DD'))
        const entryValue =  entry.format('YYYY-MM-DD')
        let isCurrentDate = false
        let isFutureDate = false
        if (entry.format(DATE_FORMAT) === moment().format(DATE_FORMAT)) {
          isCurrentDate = true
        }
        isFutureDate = entry.diff(moment()) > 0

        const cssClasses = []

        if (isCurrentDate) {
          cssClasses.push('Tracker-IsCurrentDate')
        }
        if (isFutureDate) {
          cssClasses.push('Tracker-isFutureDate')
        }

        return (<li key={entryValue} className={cssClasses.join(' ')}>
                    <div><Tooltip title={entry.format(DATE_FORMAT_LONG)}><label>{entry.format(FORMAT_DAY)}</label></Tooltip></div>
                    <OptionsEntry
                        disabled={isFutureDate}
                        date={entry}
                        displayValue={displayValue}
                        value={entryValue}
                        selection={props.entries[entryValue]}
                        selections={props.selections}
                        onClick={isFutureDate ? null : props.onClick}/>
                </li>)
      })}
    </ul>)
  }

  return (
      <div>
        {/*<label className="Options-Label">{props.trackerName}</label>*/}
        <div>{content}</div>
      </div>
  )
}

export default Options
