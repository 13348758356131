import React from 'react'
import moment from 'moment'

import { Button, Tooltip } from 'antd'
import { StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';

import './ScrollableToolbar.css'

const ICON_SIZE = ''

const ScrollableToolbar = (props) => {
  return (
    <div className="ScrollableToolbar tour-scrolltoolbar">
      <Tooltip title="Move backwards">
        <div className="ScrollableToolbar-Bakckward Pointer">
          <Button onClick={props.onBackward} shape="circle"  icon={<StepBackwardOutlined style={{fontSize:ICON_SIZE}} />} />
        </div>
      </Tooltip>
      <div className={`ScrollableToolbar-Title`} onClick={props.onTitleClick && props.onTitleClick}><h1>{props.title}</h1></div>
      <Tooltip title="Move forward">
        <div className="ScrollableToolbar-Forward Pointer" >
          <Button onClick={props.onForward} shape="circle" icon={<StepForwardOutlined style={{fontSize:ICON_SIZE}}/>} />
        </div>
      </Tooltip>
      {props.isOutOfScope && <span class="ScrollableDate-BackButton"><Button shape="round" onClick={() => props.onBackToToday()}>Back to today</Button></span>}
    </div>
  )
}

export default ScrollableToolbar
