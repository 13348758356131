import React from 'react'
import { Modal, Button, List } from 'antd'

const ViewJournalModal = (props) => {
  const {
    openViewJournal,
    onViewJournalClose,
    journalList
  } = props

  return (
    <Modal
      visible={openViewJournal}
      onCancel={onViewJournalClose}
      footer={[
        <Button key="primary" shape="round" type="primary" onClick={onViewJournalClose}>
          Close
        </Button>,
      ]}
      >
      <h2>Journal</h2>
      <List
        bordered
        pagination={true}
        dataSource={journalList}
        renderItem={item => (
        <List.Item>
           {item.log}
        </List.Item>
      )}
      />
    </Modal>
  )
}

export default ViewJournalModal
