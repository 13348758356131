import React from 'react'
import { isEmpty, some } from 'lodash'

import WatcherCard from '../WatcherCard/WatcherCard'
import { WATCHERS_HIGH_SCORE } from '../../models/watchers'
import { Alert } from '../UI'

import './WatchersList.css'

const isScoreHigh = (score) => {
  return score > WATCHERS_HIGH_SCORE
}

const WatchersList = (props) => {
  const { items, history, onWatcherDelete } = props

  if (items == null) {
    return null
  } else if (!isEmpty(items)) {
    const isHigh = some(items, (i) => isScoreHigh(i.watcherResult.score.total))
    return (
      <div className='WatchersList'>
        { isHigh && <Alert message="Some watchers seems to be high in score, that's okay, this is a message so you can take action to improve" /> }
        {items.map( i => {
          return <WatcherCard
                    history={history}
                    onWatcherDelete={onWatcherDelete}
                    watcherResult={i.watcherResult}
                    key={i.watcherResult.name} />
        })}
      </div>
    )
  } else {
    return (
      <div className="WatchersList-Empty">
        <Alert message="There are no watchers, create a new one. It's simple" type="info"/>
        <p>
          Creating a new watcher is simple. You just need:
        </p>
        <ul>
          <li>A name for your watcher</li>
          <li>How many days far back you want to watch your trackers</li>
          <li>Add the trackers you want to watch. You need at least 2</li>
        </ul>
    </div>
      )
  }
}

export default WatchersList
