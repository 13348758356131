/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, { Component } from 'react';

import { analytics } from './firebase'

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
    // if (process.env.NODE_ENV !== "development") {
      // console.log('Sending pageview', page)

      window.gtag('set', 'page_path', page)
      window.gtag('event', 'page_view')

      // analytics.logEvent('page_view', { page_location: page, page_title: options.title });

      // window.dataLayer.push({
      //   event: 'pageview',
      //   page: {
      //     url: location,
      //     title: options.title
      //   }
      // });
    // }
  };

  const HOC = class extends Component {
    componentDidMount() {
      const {
        location: { pathname: page }
      } = this.props;
      trackPage(page);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
      const {
        location: { pathname: currentPage }
      } = this.props;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}
