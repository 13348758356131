import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import { isEmpty } from 'lodash'
import Tour from 'reactour'
import Feedback from "feeder-react-feedback"; // import Feedback component
import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet


import NavigationMenu from '../../components/Navigation/NavigationMenu/NavigationMenu'
import Footer from '../../components/Footer/Footer'
import CookiePolicy from '../../components/CookiePolicy/CookiePolicy'
import { PRIMARY_COLOR, GOOGLE_ANALYTICS_ID, COOOKIES_STORAGE } from '../../utils/constants'
import * as actions from '../../store/actions'
import './Layout.css'
import { MAP_PAGES_TO_STEPS } from '../../data/tour'

class Layout extends React.Component {

  state = { acceptCookies: null}

  componentDidMount() {
    const isCookiesEnabled = localStorage.getItem(COOOKIES_STORAGE)
    this.setState({acceptCookies: isCookiesEnabled})

    if (isCookiesEnabled != null && !isCookiesEnabled) {
      window[`ga-disable-${GOOGLE_ANALYTICS_ID}`] = true;
    }
  }

  handleOnRequestClose = () => {
    this.props.onSetTour(false)
    if (this.props.accountProperties.isTourActive) {
      this.props.disableTour(this.props.uid)
    }
  }

  handleAccept = () => {
    localStorage.setItem(COOOKIES_STORAGE, true)
    this.setState({acceptCookies: true })
  }

  handleReject = () => {
    localStorage.setItem(COOOKIES_STORAGE, false)
    window[`ga-disable-${GOOGLE_ANALYTICS_ID}`] = true;
    this.setState({acceptCookies: false })
  }

  render () {
    const {
      uid,
      trackers,
      username,
      onSetTour,
      children,
      location,
      isTourOpen,
      email
    } = this.props

    return (
      <React.Fragment>
        <NavigationMenu
          username={username}
          onSetTour={onSetTour}
          hasTrackers={!isEmpty(trackers)}
          />
        <main className="Main-Container">{children}</main>

          <Tour
            steps={MAP_PAGES_TO_STEPS[location.pathname]}
            isOpen={isTourOpen}
            onRequestClose={this.handleOnRequestClose}
          />

          <Feedback
            defaultEmailValue={email}
            projectId="6127b0dde80a4e000493916b"
            primaryColor={PRIMARY_COLOR}
          />

        {process.env.NODE_ENV === 'development' && (
          <div>{uid}</div>
        )}
        <CookiePolicy
          show={this.state.acceptCookies}
          onAccept={this.handleAccept}
          onReject={this.handleReject}/>
        <Footer />
      </React.Fragment>
  )
  }
}

const mapStateToProp = state => {
  return {
    uid: state.auth.uid,
    isTourOpen: state.common.tour,
    username: state.auth.username,
    email: state.auth.email,
    accountProperties: state.auth.accountProperties,
    trackers: state.week.trackers
  }
}


const mapActionsToProps = dispach => {
  return {
    onSetTour: (value) => dispach(actions.commonSetTour(value)),
    disableTour: (uid) => dispach(actions.commonDisableTour(uid))
  }
}

export default connect(mapStateToProp, mapActionsToProps)(withRouter(Layout));
