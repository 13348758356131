
import React from 'react'
import moment from 'moment'
import { Tooltip } from 'antd'
import { DATE_FORMAT_LONG, FORMAT_DAY, DATE_FORMAT } from '../../../../utils/constants'
import { generateWeekDates } from '../../../../utils/dates'

import './Checklist.css'

const Checklist = (props) => {
  let entries = []
  if (props.format === 'day') {
    entries = [props.date]
  } else {
    entries = generateWeekDates(props.date)
  }
  return (
      <React.Fragment>
      {/*<label className="Checklist-Label">{props.trackerName}</label>*/}
      <ul className="Checklist">
        {entries.map((entry) => {
          const classes = ['Bullet']
          const amClasses = ['Bullet']
          const pmClasses = ['Bullet']
          const entryDisplayValue = entry.format('DD')
          const entryDisplayValueTop = entry.format('dd D')
          const entryValue = entry.format('YYYY-MM-DD')
          let isCurrentDate = false
          let isFutureDate = false
          if (entry.format(DATE_FORMAT) === moment().format(DATE_FORMAT)) {
            isCurrentDate = true
          }
          isFutureDate = entry.diff(moment()) > 0

          if (props.amPm) {
            if (props.entries && props.entries[entryValue] && props.entries[entryValue]['AM']) {
              amClasses.push('Checklist-Bullet-Active')
            } else if (props.entries && props.entries[entryValue] && props.entries[entryValue]['AM'] === false ) {
              amClasses.push('Checklist-Bullet-Inactive')
            }
            if (props.entries && props.entries[entryValue] && props.entries[entryValue]['PM']) {
              pmClasses.push('Checklist-Bullet-Active')
            } else if (props.entries && props.entries[entryValue] && props.entries[entryValue]['PM'] === false) {
              pmClasses.push('Checklist-Bullet-Inactive')
            }
          } else {
            if (props.entries && props.entries[entryValue]) {
              classes.push('Checklist-Bullet-Active')
            } else if (props.entries && props.entries[entryValue] === false) {
              classes.push('Checklist-Bullet-Inactive')
            }
          }

          let content = null

          const cssClasses = []

          if (isCurrentDate) {
            cssClasses.push('Tracker-IsCurrentDate')
          }

          if (isFutureDate) {
            cssClasses.push('Tracker-isFutureDate')
          }

          if (props.amPm) {
            content = (
              <li className={cssClasses.join(' ')} key={entryValue}>
                  <div>
                    <Tooltip title={entry.format(DATE_FORMAT_LONG)}>
                      {entryDisplayValueTop}
                    </Tooltip>
                  </div>
                  <div>
                  <div
                    onClick={() => !isFutureDate && props.onClick && props.onClick('checklist',{ date:entry, title:props.trackerName, name: entryValue, value: props.entries[entryValue] && props.entries[entryValue]['AM'], type: 'AM' })}
                    className={amClasses.join(' ')}>
                      AM
                    </div>
                  </div>
                  <div
                    onClick={() => !isFutureDate && props.onClick && props.onClick('checklist',{ date:entry, title:props.trackerName, name: entryValue, value: props.entries[entryValue] && props.entries[entryValue]['PM'], type: 'PM'})}
                    className={pmClasses.join(' ')}>
                      PM
                  </div>
              </li>)
          } else {
                content = (
                  <li className={cssClasses.join(' ')} key={entryValue}>
                      <div>
                        <Tooltip title={entry.format(DATE_FORMAT_LONG)}><abbr>{entry.format(FORMAT_DAY)}</abbr></Tooltip>
                      </div>
                      <div
                        onClick={() => !isFutureDate && props.onClick && props.onClick('checklist',{ date:entry, title:props.trackerName, name: entryValue, value: props.entries[entryValue]})}
                        className={classes.join(' ')}>
                        <Tooltip title={entry.format(DATE_FORMAT_LONG)}>
                          {entryDisplayValue}
                        </Tooltip>
                      </div>
                  </li>)
          }

          return content
        })}
      </ul>

      </React.Fragment>
  )
}

export default Checklist
