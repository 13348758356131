import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, Form, Input, InputNumber, Checkbox } from 'antd'
import { groupBy, map, isEmpty } from 'lodash'

import Layout from '../Layout/Layout'
import { Box, Alert } from '../../components/UI'
import WatcherForm from '../../components/WatcherForm/WatcherForm'
import * as actions from '../../store/actions'
import './NewWatcher.css'

import { MAX_NUMBER_OF_WATCHERS } from '../../models/watchers'

class NewWatcher extends React.Component {

  componentDidMount() {
    const { uid } = this.props
    this.props.onLoadTrackers(uid)
    this.props.onLoadWatchers(uid)
  }

  handleOnFinish = (value) => {
    const entry = {
      days: value.days,
      name: value.name,
      trackers: value['checkbox-group']
    }

    this.props.onCreateWatcher(this.props.uid, entry, this.props.history)
  }

  render() {
    const { trackers, history, watchers } = this.props
    const trackersGrouped = groupBy(trackers, t => t.category)


    return (
      <Layout>
        <div className="Container">
          <Row justify="center" style={{marginTop:10}}>
            <Col xl={14} lg={14} md={24} xs={24} sm={24}>
              {
                isEmpty(trackers) ?
                (<Alert message="You cannot create a Watcher if you don't have trackers. Create at least 2 trackers first" />)
                :
                ((watchers && watchers.length < MAX_NUMBER_OF_WATCHERS) ?
                (
                  <>
                  <Alert message="A Watcher observes a groups of trackers for a period of days and it gives an overall score." />
                  <Box title="Add Watcher">
                      <WatcherForm
                        daysInitialValue={15}
                        buttonLabel="Create Watcher"
                        onFinish={this.handleOnFinish}
                        trackers={trackers}
                        history={history}
                        />
                  </Box>
                  </>
                )
                :
                (
                  <Alert message="You cannot create more watchers. You already reach the max number of watcher you can have." />
                ))
              }
            </Col>
          </Row>
        </div>
      </Layout>
    )
  }
}

const mapStateToProp = state => {
  return {
    uid: state.auth.uid,
    trackers: state.newWatcher.trackers,
    watchers: state.newWatcher.watchers
  }
}

const mapActionToProps = dispach => {
  return {
    onLoadTrackers: (uid) => dispach(actions.newWatcherOnLoadTrackers(uid)),
    onCreateWatcher: (uid, entry, history) => dispach(actions.newWatcherCreateWatcher(uid, entry, history)),
    onLoadWatchers: (uid) => dispach(actions.newWatcherLoadWatchers(uid))
  }
}

export default connect(mapStateToProp, mapActionToProps)(NewWatcher);
