import React from 'react'
import { Progress } from 'antd'
import { map, sum } from 'lodash'

import Questionnaire from '../Questionnaire/Questionnaire'

const getActiveQuestionnaire = (questions, position) => {
  let p = 0
  let questionnairePos = 0
  questions.forEach( questionnaires => {
    if (p === (position + 1)) {
      return
    }
    questionnairePos = questionnairePos + 1
    questionnaires.questions.forEach( () => {
      if (p === (position + 1)) {
        return
      }
      p = p + 1
    })
  })

  questionnairePos = questionnairePos - 1

  if (questionnairePos < 0) {
    questionnairePos = 0
  }

  return questionnairePos
}

const QuestionnaireList = (props) => {
  const {
    questions,
    current,
    onSetQuestion,
    questionnairePosition,
    onBack,
    startOverQuestionairee
  } = props

  const activeQuestionnaire = getActiveQuestionnaire(questions, current)
  const totalQuestionsSize = sum(questions.map( q => q.questions.length ))
  const percent = parseInt(current * 100 / totalQuestionsSize)

  return(
    <div>
     <div><Progress percent={percent} /></div>
     <div>We are going to collect some information to build your trackers and watchers</div>
     {map(questions,(q, idx) => {
      return <Questionnaire
              disabled={activeQuestionnaire !== idx}
              completed={questionnairePosition[q.title] == q.questions.length}
              key={q.title}
              title={q.title}
              questions={q.questions}
              position={questionnairePosition[q.title]}
              onSetQuestion={onSetQuestion}
              questionareId={idx}
              startOverQuestionairee={() => startOverQuestionairee(idx, q, current)}
              onBack={()=> onBack(idx, q.title, current)}
              />
      })}
    </div>)
}

export default QuestionnaireList
