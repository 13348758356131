import * as actionTypes from './actionTypes'
import client from '../../services/client'
import api from '../../services/api'
import { message } from 'antd'

export const setWatchersField = (field, value) => {
  return {
    type: actionTypes.WATCHERS_SET_FIELD,
    field: field,
    value: value
  }
}

export const setWatcherResult = (watcher, result) => {
  return {
    type: actionTypes.WATCHERS_SET_WATCHER_RESULT,
    watcher: watcher,
    result: result
  }
}

export const watchersLoadWatchers = (uid) => dispach => {
  dispach(setWatchersField('watcherLoading', true))
  // dispach(setWatchersField('watcherResultsSecond', []))
  return client.fetchWatchers(uid)
  .then(watchers => {
      dispach(setWatchersField('watchers', watchers))
      dispach(setWatchersField('watcherResultsSecond', []))
      const p = watchers.map(watcher => {
      return api.fetchWatcherResultForUser(uid, watcher.id)
      .then(response => {
        dispach(setWatcherResult(watcher, response.data))
      })
    })

    return Promise.all(p)
  }).then( () => dispach(setWatchersField('watcherLoading', false)))
}


export const watchersDeleteWatcher = (uid, watcherId) => dispach => {
  client.deleteWatcher(uid, watcherId)
  .then(() => {
    message.info('Watcher deleted')
    dispach(watchersLoadWatchers(uid))
  })
}
