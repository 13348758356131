import React from 'react'
import { isArray, capitalize } from "lodash"

import { Button } from 'antd'

import './Question.css'

const getAnswerType = (value) => {
  if (value === 'boolean') {
    return 'boolean'
  } else if (isArray(value)) {
    return 'array'
  }
}

const QuestionAnswersBoolean = (props) => {
  return (
    <div className="Question-Buttons">
      <Button onClick={() => props.onClick(props.value)} type="primary" shape="round">Yes</Button>
      <Button onClick={() => props.onClick(false)} type="danger" shape="round">No</Button>
    </div>
  )
}

const QuestionAnswersArray = (props) => {
  return (
    <div className="Question-Buttons">
      {props.answer.map( a => {
        return <Button shape="round" key={a} onClick={() => props.onClick(a)}>{a}</Button>
      })}
      <Button shape="round" type="danger" onClick={() => props.onClick(null)}>Other</Button>
    </div>
  )
}

const QuestionAnswers = (props) => {
  const answerType = getAnswerType(props.answer)
  switch (answerType) {
    case 'boolean': return <QuestionAnswersBoolean {...props} />
    case 'array': return <QuestionAnswersArray {...props} />
    default: throw new Error(`Unknown type ${answerType} for QuestionAnswers`)
  }
}

const Question = (props) => {
  const { question, position } = props

  return (
    <div className="Question">
        <h3>{ capitalize(question) }</h3>
        <QuestionAnswers {...props} />
        {
          ((position > 0) && (
            <div className="Question-BackButton">
              <Button shape="round" size="small" onClick={props.onBack}>Back</Button>
            </div>))
        }
    </div>
  )
}

export default Question
