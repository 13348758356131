import React, { useState } from 'react'
import moment from 'moment'
import { Alert, Button, Tag, Tooltip, Modal, Input, Form, AutoComplete } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { sum, map, size, filter, isEmpty, compact } from 'lodash'
import classnames from 'classnames'
import TextLoop from "react-text-loop";

import { formatNumber, std } from '../../../utils/utils'
import { DATE_FORMAT } from '../../../utils/constants'
import Number from './Number/Number'
import Options from './Options/Options'
import Checklist from './Checklist/Checklist'
import { generateWeekDates } from '../../../utils/dates'

import './Tracker.css'

const getAverage = (values) => {
  const total = sum(values)

  return total / size(values)
}

const getStd = (values) => {
  return std(values)
}

const selectTrackerType = (type, configuration, date, format, tour) => {
  switch (type) {
    case 'numbers': return <Number key={configuration.id} {...configuration} date={date} format={format} tour={tour}/>
    case 'options': return <Options key={configuration.id} {...configuration} date={date} format={format}/>
    case 'checklist': return <Checklist key={configuration.id} {...configuration} date={date} format={format}/>
    default: return null
  }
}

const getWatchers = (trackerId, watchers) => {
  return filter(watchers, w => w.trackers.incldues(trackerId))
}

const calculateScore = (configuration) => {
  const { entries, theLessTheBest, maxNumber } = configuration
  const todayFormatted = moment().format(DATE_FORMAT)
  let value = entries[todayFormatted]

  if (!theLessTheBest) {
    value = maxNumber - value
  }

  const score = value * 10 / maxNumber

  return score
}

const isTrackerInWatcher = (trackerId, watchers) => {
  const watcher = watchers.find( w => w.trackers.includes(trackerId))

  if (watcher) {
    return true
  } else {
    return false
  }
}

const Tracker = (props) => {
  const {
    type,
    configuration,
    date,
    format,
    tour,
    onTrackerDelete,
    onLoadCategories,
    onEditTrackName,
    demo,
    categories,
    streak,
    watchers,
    onOpenFeedback,
    journals,
    journalToday,
    onViewJournalOpen
  } = props

  const [showModal, setShowModal ] = useState(false)
  const [config, setConfig] = useState(null)
  const [form] = Form.useForm();

  const content = selectTrackerType(type, configuration, date, format, tour)
  const isInWatcher = watchers ? isTrackerInWatcher(configuration.id, watchers) : null

  const deleteTracker = (trackerId, inWatcher) => {
    let text = ""

    if (inWatcher) {
      text = "This tracker belongs to watcher and all it's data will be lost."
    } else {
      text = "All its data will be lost."
    }

    Modal.confirm({
      title: "Are you sure you want to delete this tracker?",
      content: text,
      onOk: () => { onTrackerDelete && onTrackerDelete(trackerId) }
    })
  }

  const editTrackName = (config) => {
    setShowModal(true)
    setConfig(config)
    onLoadCategories()
  }

  const onFinish = (values) => {
    onEditTrackName(config, values)
    setShowModal(false)
  }

  const handleOpenFeedback = () => {
    onOpenFeedback(configuration)
  }

  const values = map(configuration.entries, (value) => parseFloat(value))
  const score = calculateScore(configuration)
  const isScoreLow = score <= 3
  const isScoreHigh = score >= 8
  const entries =  generateWeekDates(date)
  const goalSize = size(compact(map(entries, (entry) => configuration.entries[entry.format('YYYY-MM-DD')] )))
  const goalReach = goalSize >= configuration.timesAWeek


  return (
    <div className={classnames('Tracker',{'Tracker-Day':format == 'day', 'Tracker-Demo': demo})}>
    { !demo &&
        (
          <>
          <Tooltip title="Delete this tracker">
            <DeleteOutlined onClick={()=> deleteTracker(configuration.id, isInWatcher)} className={classnames("Pointer",{"tour-tracker-delete": tour})} style={{fontSize:22, float:'right'}}/>
          </Tooltip>
          <Tooltip title="Edit tracker">
            <EditOutlined onClick={() => editTrackName(configuration) } className={classnames("Pointer",{"tour-tracker-edit": tour})} style={{fontSize:22, float:'right', marginRight:5}}/>
          </Tooltip>
          </>
        )
      }
      {isScoreHigh && !isEmpty(journals) && (
          <div className="Tracker-Feedback-Red">
            <div>Looks like your are having a bad day on your <span className="Tracker-Feedback-TrackerName">{configuration.trackerName}</span>. You can try:</div>
            <div>
              <TextLoop>
                {map(journals, j => {
                  return (<div><i>{j.log}</i></div>)
                })}
              </TextLoop>
              <div><Button shape="round" size="small" onClick={onViewJournalOpen}>View more</Button></div>
            </div>
          </div>
        )
      }
      <div className="Tracker-Label">
        <span className="Tracker-Label-Name">{configuration.trackerName}</span>
      </div>
      <ul className="Tracker-Metrics">
          {streak && (
            <>
            <li className="Tracker-Streak"><Tag>Currrent streak:{streak.currentStreak}</Tag></li>
            <li className="Tracker-Streak"><Tag>Longest streak:{streak.longestStreak}</Tag></li>
            </>
          )}
          <li className="Tracker-Average"><Tag>30 days average:{formatNumber(getAverage(values))}</Tag></li>
          <li className="Tracker-Std"><Tag>30 days std:{formatNumber(getStd(values))}</Tag></li>
          { configuration.timesAWeek && (<li><Tag color={goalReach ? "green": ""}>Goal {goalSize}/{configuration.timesAWeek}</Tag></li>)}
      </ul>
      <div className="Tracker-Content">{content}</div>
      {isScoreLow &&(
        !isEmpty(journalToday) ?
        (
          <div>You already save a journal for this tracker for today.</div>
        )
        :
        (
          <div className="Tracker-Feedback">
            Looks like your are having a good day on your <span className="Tracker-Feedback-TrackerName">{configuration.trackerName}</span>. You want to <Button shape="round" onClick={handleOpenFeedback}>Tell us what worked today</Button>
            <br/>
            <i>With this data we are going to help you later</i>
          </div>
        ))
      }
      <Modal
        visible={showModal}
        onOk={() => form.submit()}
        onCancel={() => setShowModal(false)}
        title="Edit tracker"
        >
        <Form form={form} onFinish={(values) => onFinish(values)}>
            <Form.Item
             label="Tracker Name"
             name="trackerName"
             initialValue={configuration.trackerName}
             rules={[{ required: true, message: 'Please input tracker name!' }]}
             >
              <Input />
            </Form.Item>

            <Form.Item
             label="Group Name"
             name="category"
             initialValue={configuration.category}
             rules={[{ required: true, message: 'Please input group name!' }]}
             >
              <AutoComplete options={categories} />
            </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

Tracker.Number = Number

export default Tracker
