import { filter, remove, forEach, clone, map } from 'lodash'

import * as actionTypes from './actionTypes'
import { db } from '../../utils/firebase'
import { deleteCollection } from '../../utils/firebase'
import client from '../../services/client'
import api from '../../services/api'

export const weekMoveForward = () => {
  return {
    type: actionTypes.WEEK_MOVE_FORWARD
  }
}

export const weekMoveBackward = () => {
  return {
    type: actionTypes.WEEK_MOVE_BACKWARD
  }
}

export const weekSelectDate = (date) => {
  return {
    type: actionTypes.WEEK_SELECT_DATE,
    date: date
  }
}

export const sendTrackerValue = (uid, trackerId, values, trackerType, index, id) => {
  return dispach => {
    const { value, type, date } = values

    let saveValue = null
    switch (trackerType) {
      case 'checklist':
        if (type) {
          saveValue = { [type]: !value }
        } else {
          saveValue = !value
        }
        break;
      case 'options':
        if (type) {
          saveValue = { [type]: value }
        } else {
          saveValue = value
        }
        break;
      case 'numbers':
        if (type) {
          saveValue = { [type]: value }
        } else {
          saveValue = value
        }
        break;
      default:
    }

    client.setTrackerValue(uid, trackerId, date, saveValue)
    .then(() => dispach(trackerClicked(trackerType, values, index)))
    .then(() => dispach(weekFetchStreaks(uid)))
  }
}

export const trackerClicked = (trackerType, values, index) => {
  return {
    type: actionTypes.TRACKER_CLICKED,
    trackerType: trackerType,
    values: values,
    index: index
  }
}

export const dayRateClickedSend = (uid, date, day, value) => {
  return dispach => {
    client.setDayRate(uid, date, day, value)
    .then(() => {
      dispach(dayRateClicked(day, value))
    })
  }
}

export const weekLoadDayRates = (values) => {
  return {
    type: actionTypes.WEEK_LOAD_DAY_RATES,
    values: values
  }
}

export const fetchDayRates = (uid, year, week) => {
  return dispach => {
    client.fetchDayRates(uid, year, week)
    .then((entries) => {
        dispach(weekLoadDayRates(entries))
    })
  }
}

export const dayRateClicked = (day, value) => {
  return {
    type: actionTypes.DAY_RATE_CLICKED,
    day: day,
    value: value
  }
}

export const moodClicked = (time, week, mood, date) => {
  return {
    type: actionTypes.MOOD_CLICKED,
    time: time,
    week: week,
    mood: mood,
    date: date
  }
}

export const moodClickedStarted = (uid, date, time, week, mood) => {
  return dispach => {
    // db.collection(`accounts/${uid}/moodTrackers`).doc(date.format('YYYY-MM-DD')).set({
    //     [time]: mood,
    //     day: week,
    //     year: parseInt(date.format('YYYY')),
    //     month: parseInt(date.format('MM')),
    //     week: date.week()
    // }, { merge: true }).then(() => {
    //   dispach(moodClicked(time, week, mood, date))
    // })

    client.setMood(uid, date, time, week, mood)
    .then(() =>  dispach(moodClicked(time, week, mood, date)))
  }
}


const weekLoadTrackers = (trackers) => {
  return {
    type: actionTypes.WEEK_LOAD_TRACKERS,
    trackers: trackers
  }
}

const weekLoadingTrackersStart = () => {
  return {
    type: actionTypes.WEEK_LOADING_TRACKERS_START
  }
}

export const weekLoadTrackersDataStart = (trackerId, values) => {
  return {
    type: actionTypes.WEEK_LOAD_TRACKERS_DATA_START,
    trackerId: trackerId,
    values: values
  }
}

export const weekLoadTrackerJournalToday = (trackerId, data) => {
  return {
    type: actionTypes.WEEK_LOAD_TRACKER_JOURNAL_TODAY,
    trackerId: trackerId,
    data: data
  }
}

export const weekLoadTrackerJournal = (trackerId, entries) => {
  return {
    type: actionTypes.WEEK_LOAD_TRACKER_JOURNAL,
    trackerId: trackerId,
    entries: entries
  }
}

export const weekLoadTrackersData = (uid, trackers, year, week) => {
  return dispach => {
      for(let idx in trackers) {
        const trackerId = trackers[idx].id
        client.fetchTrackerValuesByYearAndWeek(uid, trackerId, year, week)
        .then(entries => {
          dispach(weekLoadTrackersDataStart(trackerId, entries))
          client.fetchTrackerJournalToday(uid, trackerId)
          .then(data => {
            dispach(weekLoadTrackerJournalToday(trackerId, data))
          })
          .then(() => {
            client.fetchTrackerLatestJournal(uid, trackerId)
            .then(entries => {
              dispach(weekLoadTrackerJournal(trackerId, entries))
            })
          })
        })
      }
    }
}

export const weekInitMoodTracker = (entries) => {
  return {
    type: actionTypes.INIT_MOOD_TRACKER,
    values: entries
  }
}

export const weekLoadMoodTrackerData = (uid, year, week) => {
  return dispach => {
    dispach(weekSetField('moodTrackerLoading', true))
    client.fetchMoods(uid, year, week)
      .then((entries) => {
        dispach(weekInitMoodTracker(entries))
        dispach(weekSetField('moodTrackerLoading', false))
      })
  }
}

export const weekSetCounterLoaded = (value) => {
  return {
    type: actionTypes.WEEK_SET_COUNTERS_LOADED,
    value: value
  }
}

export const weekInitTrackers = (uid, year, week, storeTrackers, firstTimeSetup) => {
  return dispach => {
    if (storeTrackers && storeTrackers.length > 0 ) {
      dispach(weekLoadTrackersData(uid, storeTrackers, year, week))
    } else {
      dispach(weekLoadingTrackersStart())
      // const trackers = []
      let timeout = 0
      if (firstTimeSetup) {
        timeout = 4000
      }
      setTimeout(function () {
        // db.collection(`accounts/${uid}/trackers`).get()
        // .then( querySnapshot => {
        //   querySnapshot.forEach((doc) => {
        //     const data = doc.data()
        //     trackers.push({id: doc.id, type: data.type, ...data.options, entries: {}})
        //   });
        //   return trackers
        // }).then(()=> {
        //   dispach(weekLoadTrackers(trackers))
        //   dispach(weekLoadTrackersData(uid, trackers, year, week))
        // })

        client.fetchTrackers(uid)
        .then((trackers) => {
          dispach(weekLoadTrackers(trackers))
          dispach(weekLoadTrackersData(uid, trackers, year, week))
        })
      }, timeout);
    }
  }
}

export const weekReset = () => {
  return {
    type: actionTypes.WEEK_RESET
  }
}

export const weekSaveLogEntry = (uid, date, label, value) => {
  return dispach => {
    // const logId = date.format(DATE_FORMAT)
    // db.collection(`accounts/${uid}/logs/`)
    // .doc(logId)
    // .set({
    //   text: value,
    //   year: parseInt(date.format('YYYY')),
    //   month: parseInt(date.format('DD')),
    //   week: date.week()
    // }).then(() => {
    //   // message.info('Journal saved')
    // })
    client.saveLogEntry(uid, date, value)
  }
}

export const weekInitLogEntries = (uid, year, week) => {
  return dispach => {
    client.fetchLogEntries(uid,year,week)
    .then(entries => dispach(weekSetLogEntries(entries)))
  }
}

export const weekChangeLogEntry = (date, label, value) => {
  return {
    type: actionTypes.CHANGE_LOG_ENTRY,
    date: date,
    value: value
  }
}

export const weekSetLogEntries = (entries) => {
  return {
    type: actionTypes.WEEK_LOG_ENTRY_SET,
    entries: entries
  }
}

export const weekRemoveTracker = (trackerId) => {
  return {
    type: actionTypes.WEEK_REMOVE_TRACKER,
    trackerId: trackerId
  }
}

export const weekOnTrackDelete = (uid, trackerId, watchers) => {
  return dispach => {
    window.gtag('event','tracker_deleted')
    client.deleteFullTracker(uid,trackerId)
    .then(() => {
      const watchersFiltered = filter(watchers, w => w.trackers.includes(trackerId))

      const watchersTrackersMap = {}
      forEach(watchersFiltered, w => {
        const clonedTrackers = clone(w.trackers)
        remove(clonedTrackers, t => t == trackerId)
        watchersTrackersMap[w.id] = clonedTrackers
      })

      forEach(watchersTrackersMap, (trackerIds, watcherId) => {
        if (trackerIds.length <= 1) {
          client.deleteWatcher(uid, watcherId)
        } else {
          client.updateWatcher(uid, watcherId, { trackers: trackerIds })
        }
      })
      dispach(weekRemoveTracker(trackerId))
    })
  }
}

export const weekUpdateTrackerName = (trackerId, trackerName, category) => {
  return {
    type: actionTypes.WEEK_UPDATE_TRACKER_NAME,
    trackerId: trackerId,
    trackerName: trackerName,
    category: category
  }
}

export const weekEditTrackName = (uid, config, values) => {
  return dispach => {
    window.gtag('event','tracker_edited')
    client.updateTracker(uid, config, values)
    .then(([ config, values ])=> {
      dispach(weekUpdateTrackerName(config.id,values.trackerName, values.category))
    })
  }
}

export const weekSetField = (field, value) => {
  return {
    type: actionTypes.WEEK_SET_FIELD,
    field: field,
    value: value
  }
}

export const weekLoadCategories = (uid) => dispach => {
    client.fetchCategories(uid)
    .then(data => {
      dispach(weekSetField('categories', data))
    })
}

export const weekOnBackToToday = () => {
  return {
    type: actionTypes.WEEK_ON_BACK_TO_TODAY
  }
}

export const weekFilterTrackerChange = (value) => {
  return {
    type: actionTypes.WEEK_FILTER_TRACKER_CHANGE,
    value: value
  }
}

export const weekFetchStreaks = (uid) => dispach => {
  api.fetchAllStreaksForUser(uid)
  .then(response => {
    dispach(weekSetField('streaks', response.data['trackersStreaks']))
  })
}


export const weekFetchWatchersSummary = (uid) => dispach => {
  api.fetchWatcherSummaryForUser(uid)
  .then(response => {
    dispach(weekSetField('watcherSummaryList', response.data['watcherSummaryList']))
  })
}

export const weekFetchWatchers = (uid) => dispach => {
  client.fetchWatchers(uid)
  .then( watchers => dispach(weekSetField('watchers', watchers)))
}

export const weekOpenFeedback = (tracker) => {
  return {
    type: actionTypes.WEEK_SET_MODAL_FEEDBACK,
    feedbackTracker: tracker,
    openFeedback: true
  }
}

export const weekCloseFeedback = () => {
  return {
    type: actionTypes.WEEK_SET_MODAL_FEEDBACK,
    feedbackWeekSummary: null,
    openFeedback: false
  }
}

export const weekSaveFeedback = (uid, values) => dispach => {
  const p = map(values, (entry, trackerId) => {
    client.createTrackerJournal(uid, trackerId, entry)
    .then(() => {
      dispach(weekLoadTrackerJournalToday(trackerId, entry))
      return client.fetchTrackerLatestJournal(uid, trackerId)
      .then(entries => {
        dispach(weekLoadTrackerJournal(trackerId, entries))
      })
    })
  })

  Promise.all(p).then(() => dispach(weekCloseFeedback()))
}

const weekSetJournalList = (journals) => {
  return {
    type: actionTypes.WEEK_SET_JOURNAL_LIST,
    journals: journals
  }
}

export const weekViewJournalLoad = (uid, trackerId) => dispach => {
  client.fetchAllJournalsForTracker(uid, trackerId)
  .then(journals => {
    dispach(weekSetJournalList(journals))
  })
}

export const weekViewJournalClose = () => dispach => {
  dispach(weekSetField('openViewJournal', false))
}
