
const MENTAL_HEALTH_OPTIONS = ["Anxiety", "Depression", "Bipolar Disorder", "Panic Disorder"]

export const MENTAL_HEALTH_QUESTIONS = {
  title: "Mental Health",
  questions:[
    { question: 'Have you been diagnosed with a mental health issue?', value:'mental_health', answer:'boolean'},
    { question: 'Have you been diagnosed with any of these mental health issues?', value:'issues', answer:MENTAL_HEALTH_OPTIONS, depends:['mental_health']},
    { question: 'In the past two weeks have or experienced feeling nervous, irritable, or on edge? ',            value:'anxiety2', answer:'boolean', excludes:['issues']},
    { question: 'In the past two weeks have or experienced having a sense of impending danger, panic or doom?',  value:'panic', answer:'boolean', excludes:['issues']},
    { question: 'In the past two weeks have or experienced sleep disturbance (difficulty falling or staying asleep, or restless unsatisfying sleep)?', value:'sleep', answer:'boolean', excludes:['issues']},
    { question: 'In the past two weeks have or experienced increased energy, activity, and restlessness?',       value:'restlessness', answer:'boolean', excludes:['issues']},
    { question: 'In the past two weeks have or experienced excessively high, overly good, euphoric mood?',       value:'euphoria', answer:'boolean', excludes:['issues']},
    { question: 'In the past two weeks have or experienced fear of losing control or “going crazy”?',            value:'fear', answer:'boolean', excludes:['issues']},
    { question: 'In the past two weeks have or experienced fear of dying?',                                      value:'worry', answer:'boolean', excludes:['issues']},
    { question: 'In the past two weeks have or experienced feelings of hopelessness or pessimism?',              value:'hopelessness', answer:'boolean', excludes:['issues']},
    { question: 'In the past two weeks have or experienced feelings of guilt, worthlessness, or helplessness?',  value:'helplessness', answer:'boolean', excludes:['issues']},
    { question: 'In the past two weeks have or experienced loss of interest or pleasure in hobbies and activities?', value:'pleasure', answer:'boolean', excludes:['issues']}
  ]
}

export const ACTIVITIES_HEALTH_QUESTIONS = {
  title: "Activities",
  questions:[
    { question: 'Would you like to monitor your physical exercise?', answer:'boolean', value:'exercise' },
    { question: 'Are you interested in practicing mindfulness?', answer: 'boolean', value: 'mindfulness'},
    { question: 'Do you feel like you work too much?', answer:'boolean', value:'work'},
    { question: 'Do you have difficulties doing your activities of daily living (showering, eating, paying bills, etc) regularly?', answer:'boolean', value:'adl'},
    { question: 'Do you want to track your social activity?', answer:'boolean', value:'social_life'}
  ]
}

export const LIFESTYLE_QUESTIONS = {
  title: "Lifestyle",
  questions: [
    { question: 'Are you trying to quit smoking?', answer:'boolean', value:'smoking' },
    { question: 'Do you want to reduce/quit drinking alcohol?', answer:'boolean', value:'alcohol' },
    { question: 'Do you need to change your sleep patterns?', answer:'boolean', value:'sleep' },
    { question: 'Do you experience frequent physical pain?', answer:'boolean', value:'physical_pain' },
    { question: 'Do you want to monitor your healthy eating habits?', answer:'boolean', value:'healthy_eating' },
  ]
}

export default [
  MENTAL_HEALTH_QUESTIONS,
  ACTIVITIES_HEALTH_QUESTIONS,
  LIFESTYLE_QUESTIONS,
]
