import React from 'react'
import { toLower } from 'lodash'

import LogEntry from './LogEntry/LogEntry'

const DATE_FORMAT = 'YYYY-MM-DD'
const size= 3

const Journal = (props) => {
  const { date, values, format} = props

  const dates = [
    date.clone().add(0,'day'),
    date.clone().add(1,'day'),
    date.clone().add(2,'day'),
    date.clone().add(3,'day'),
    date.clone().add(4,'day'),
    date.clone().add(5,'day'),
    date.clone().add(6,'day'),
  ]

  let content = null
  if (format === 'day') {
    const dateName = toLower(date.format('dddd'))
    content = <LogEntry format='day' label={date.format('dddd')} value={values[date.format(DATE_FORMAT)]}  date={date}  size={size} onDayRateClick={(value) => props.onDayRate(dateName,value, date)} dayRateValue={props.dayRates[dateName]} onSaveLogEntry={props.onSaveLogEntry} onChangeLogEntry={props.onChangeLogEntry}/>
  } else {
    content = (
      <>
      <LogEntry label="Monday" value={values[dates[0].format(DATE_FORMAT)]}  date={dates[0]}  size={size} onDayRateClick={(value) => props.onDayRate('monday',value, dates[0])} dayRateValue={props.dayRates['monday']} onSaveLogEntry={props.onSaveLogEntry} onChangeLogEntry={props.onChangeLogEntry}/>
      <LogEntry label="Tuesday" value={values[dates[1].format(DATE_FORMAT)]} date={dates[1]}  size={size} onDayRateClick={(value) => props.onDayRate('tuesday',value, dates[1])} dayRateValue={props.dayRates['tuesday']} onSaveLogEntry={props.onSaveLogEntry} onChangeLogEntry={props.onChangeLogEntry}/>
      <LogEntry label="Wednesday"value={values[dates[2].format(DATE_FORMAT)]}  date={dates[2]}  size={size} onDayRateClick={(value) => props.onDayRate('wednesday',value, dates[2])} dayRateValue={props.dayRates['wednesday']} onSaveLogEntry={props.onSaveLogEntry} onChangeLogEntry={props.onChangeLogEntry}/>
      <LogEntry label="Thursday"value={values[dates[3].format(DATE_FORMAT)]}  date={dates[3]}  size={size} onDayRateClick={(value) => props.onDayRate('thursday',value, dates[3])} dayRateValue={props.dayRates['thursday']} onSaveLogEntry={props.onSaveLogEntry} onChangeLogEntry={props.onChangeLogEntry}/>
      <LogEntry label="Friday" value={values[dates[4].format(DATE_FORMAT)]} date={dates[4]}  size={size} onDayRateClick={(value) => props.onDayRate('friday',value, dates[4])} dayRateValue={props.dayRates['friday']} onSaveLogEntry={props.onSaveLogEntry} onChangeLogEntry={props.onChangeLogEntry}/>
      <LogEntry label="Saturday"value={values[dates[5].format(DATE_FORMAT)]}  date={dates[5]}  size={size} onDayRateClick={(value) => props.onDayRate('saturday',value, dates[5])} dayRateValue={props.dayRates['saturday']} onSaveLogEntry={props.onSaveLogEntry} onChangeLogEntry={props.onChangeLogEntry}/>
      <LogEntry label="Sunday" value={values[dates[6].format(DATE_FORMAT)]} date={dates[6]}  size={size} onDayRateClick={(value) => props.onDayRate('sunday',value, dates[6])} dayRateValue={props.dayRates['sunday']} onSaveLogEntry={props.onSaveLogEntry} onChangeLogEntry={props.onChangeLogEntry}/>
      </>
    )
  }

  return (
    <div className="Box">
      <h2>Journal</h2>
      {content}
    </div>
  )
}

export default Journal
