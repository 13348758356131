import { keyBy, find } from "lodash"
import { monthRange } from "./dates"
import moment from "moment"

export const monthLabelsDays = (date) => {
  const dates = monthRange(date, d => d)
  const keys = dates.map(i => i.date())
  return keys
}

const getValue = (key, valuesMap, key1, key2, tracker) => {
  if (!valuesMap[key]) {
    return 0
  }
  const [, value] = valuesMap[key]
  if (value) {
    if (tracker && tracker.type === 'options') {
      const selection = find(tracker.options.selections, s => s.label === value[key2])
      return (selection && selection.value) || 0
    } else {
      return value[key1] || value[key2]
    }
  } else {
    return 0
  }
}

const buildDataSetWithValueMap = (amPm, title, valuesMap, keys, tracker) => {
  if (amPm) {
    return [
      {
        label: "AM",
        data: keys.map(key => getValue(key, valuesMap, 'am', 'AM', tracker)),
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
        yAxisID: 'y-axis-1',
      },
      {
        label: "PM",
        data: keys.map(key => getValue(key, valuesMap, 'pm', 'PM', tracker)),
        fill: false,
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgba(54, 162, 235, 0.2)',
        yAxisID: 'y-axis-2'
      }
    ]
  } else {
    return [
      {
        label: title,
        data: keys.map(key => {
          if (!valuesMap[key]) {
            return 0
          }
          const [, value] = valuesMap[key]
          if (tracker && tracker.type === 'options') {
            const selection = find(tracker.options.selections, s => s.label === value)
            return (selection && selection.value) || 0
          } else {
            return value || 0
          }
        }),
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
      },
    ]
  }
}

export const buildDataSet = (amPm, title, data, labels, tracker) => {
  const values = data.map(d => [d.date, d.value])
  const valuesMap = keyBy(values, ([date]) => moment(date).format('D'))

  return buildDataSetWithValueMap(amPm, title, valuesMap, labels, tracker)
}

const isAmPm = (value, tracker) => {
  if (value === 'moodsTracker') {
    return true
  } else if (value === 'dayRate') {
    return false
  } else {
    if (tracker) {
      return tracker.options.amPm
    } else {
      return false
    }
  }
}

const buildOptions = (amPm, opts = {}) => {
  let options = {
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Days'
        }
      }],
      yAxes: [
        {
          type:'linear',
          display:true,
          position:'left',
          id:'y-axis-1',
          ticks: {
            beginAtZero: true,
            suggestedMax: opts['max']
          },
        },
      ],
    },
  }

  if (amPm) {
    options = {
      scales: {
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Days'
          }
        }],
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-1',
            ticks: {
              beginAtZero: true,
              suggestedMax: opts['max']
            }
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-2',
            gridLines: {
              drawOnArea: false,
            },
            ticks: {
              beginAtZero: true,
              suggestedMax: opts['max']
            },
          },
        ],
      },
    }
  }

  return options
}

export const buildChart = (value, date, title, tracker, data) => {
  const amPm = isAmPm(value, tracker)
  const labels = monthLabelsDays(date)
  const datasets = buildDataSet(amPm, title, data, labels, tracker)

  const chartData = {
    labels: labels,
    datasets: datasets
  }

  const options = buildOptions(amPm)

  return [options, chartData]
}
