import React from 'react'

const Unknown = () => {
  return (
    <div>
      <h1>404 Unknow page</h1>
    </div>
  )
}

export default Unknown
