import * as actionTypes from './actionTypes'
import { isEmpty } from 'lodash'
import client from '../../services/client'
import { getTrackers, buildWatchers } from '../../utils/wizard'

const TRACKERS_ID = 'checkbox-group'

const WATCHERS_ID = 'checkbox-group2'

export const wizardNext = () => {

}

export const wizardPrev = () => {

}

export const wizardSetQuestion = (idx, title, question, answer) => {
 return {
   type: actionTypes.WIZARD_SET_QUESTION,
   question: question,
   answer: answer,
   title: title,
   idx: idx
 }
}

export const wizardOnInit = () => {
  return {
    type: actionTypes.WIZARD_INIT
  }
}

export const wizardOnBack = (questionareId, title, position) => {
  return {
    type: actionTypes.WIZARD_ON_BACK,
    questionareId: questionareId,
    title: title,
    position: position
  }
}

export const wizardStartOverQuestionairee = (idx, questionnaire, current) => {
  return {
    type: actionTypes.WIZARD_START_OVER_QUESTIONNARE,
    idx: idx,
    questionnaire: questionnaire,
    current: current
  }
}

export const wizardOnCreate = (uid, values, trackers) => dispach => {
  // const trackers = getTrackers(values[TRACKERS_ID])
  const p = trackers.map( tracker => {
    const entry = { type: 'numbers', options: tracker }
    return client.createTracker(uid, entry)
  })

  Promise.all(p).then( () => {
    return client.fetchTrackers(uid)
      .then( createdTrackers => {
        const watchers = buildWatchers(values[WATCHERS_ID], createdTrackers)

        if (!isEmpty(watchers)) {
          const p2 = watchers.map( watcher => {
            return client.createWatcher(uid, watcher)
          })

          return Promise.all(p2)
        } else {
          return null
        }
      })
  }).then((ret) => {
    window.gtag('event','wizard_completed', {
      'trackers_count': (trackers ? trackers.length : 0),
      'watchers_count': (ret ? ret.length : 0)
    })
  })
  .then(() => {
    return client.setAccountActive(uid)
  })
  .then(() => {
    window.location.reload()
  })

}

export const wizardOnSkip = (uid) => dispach => {
  window.gtag('event','wizard_skipped')

  client.setAccountActive(uid)
  .then(() => {
    return client.setAccountProperties(uid, {isTourActive:false})
  }).then(() => {
    window.location.reload()
  })
}
