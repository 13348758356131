import React from 'react'
import moment from 'moment'

import './TrackerFastEntry.scss'
import { Tracker } from '../../UI'

const { Number } = Tracker

const TrackerFastEntry = (props) => {
  const { tracker, onTrackerClicked } = props

  const handleOnClick = (trackerType, value) => {
    onTrackerClicked(trackerType, value, tracker.index, tracker.id)
  }

  const numberProps = {...tracker, onClick: handleOnClick }
  return (
    <div className="TrackerFastEntry">
      <label>{tracker.trackerName}</label>
      <div className="TrackerFastEntry-Entry">
        <Number {...numberProps} date={moment()} format="day"/>
      </div>
    </div>
  )
}

export default TrackerFastEntry
