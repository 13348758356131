import { includes, toLower, trim } from 'lodash'

export const validateTrackNameUniqueness = (trackerNames, getFieldValue, rule, value) => {
  if (includes(trackerNames.map(trim), toLower(trim(value)))) {
    return Promise.reject('Tracker name already exists!')
  } else {
    return Promise.resolve()
  }
}

export const trackNameUniquenesValidator = (trackerNames) => ({getFieldValue}) => ({
  validator(rule,value) {
    return validateTrackNameUniqueness(trackerNames, getFieldValue,rule,value)
  }
})
