import React, { useState } from 'react'
import { Button, Tooltip, Modal } from 'antd'
import { map } from 'lodash'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import WatcherChart from '../WatcherChart/WatcherChart'

import { Box } from '../UI'
import { getWatcherScoreColor, formatNumber, colorFor } from '../../utils/utils'

export const colorForTracker = (value, options) => {
  if (value == 0) {
    return 'white'
  } else {
    return colorFor(value, options.minNumber, options.maxNumber, true)
  }
}

const TrackersPixelTable = (props) => {
  const {
    watcherResult
  } = props
  return (
    <div className="Watcher-Table">
    <table>
      {map(watcherResult.score.trackers.score,({ score, values, options}, trackerId) => (
        <tr key={trackerId} className="Watcher-Table-Tr">
          <td className="Watcher-Table-Header" nowrap>
            {options.trackerName}({formatNumber(score)})
          </td>

          {values.map((value) => {
            return (<td key={value} style={{backgroundColor:colorForTracker(value, options), width:22,height:22}}>
                      <Tooltip title={formatNumber(value)}>
                        <div style={{width:22, height:'100%'}}></div>
                      </Tooltip>
                    </td>)
          })}
        </tr>
      ))}
    </table>
  </div>
  )
}

const WatcherCard = (props) => {
  const { watcherResult } = props
  const [ showChart, setShowChart ] = useState(false)

  const editWatcher = (watcherId) => {
    props.history.push(`/watchers/${watcherId}/edit`)
  }

  const deleteWatcher = (watcherId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this watcher?",
      content: "None of the tracker data will be deleted",
      onOk: () => { props.onWatcherDelete(watcherId) }
    })
  }

  return (<Box title={watcherResult.name} key={watcherResult.name}>
            <div>{watcherResult.id}</div>
            <div className="Watcher-Body-Header">
              <>
              <Tooltip title="Delete this watcher">
                <DeleteOutlined onClick={()=> deleteWatcher(watcherResult.id)} className="Pointer" style={{fontSize:22, float:'right'}}/>
              </Tooltip>
              <Tooltip title="Edit watcher">
                <EditOutlined onClick={() => editWatcher(watcherResult.id) } className="Pointer" style={{fontSize:22, float:'right', marginRight:5}}/>
              </Tooltip>
              </>
            </div>
            <div className="Watcher-Body">
              <label>Score: <span className="Watcher-Score" style={{backgroundColor:getWatcherScoreColor(watcherResult.score.total)}}>{formatNumber(watcherResult.score.total)}</span></label>
              <label>Days watching: <span>Last {watcherResult.days} days</span></label>
              {/*<label>Result: <span>{watcherResult.result}</span></label>*/}
              <hr/>
              <label className="Watcher-Body-Trackers">Trackers<span>The lower the score, the better</span></label>

              <TrackersPixelTable watcherResult={watcherResult} />

              <div className="Watcher-Chart">
                <Button shape="round" onClick={() => setShowChart(!showChart)}>Show chart</Button>
                {showChart &&
                  <WatcherChart trackers={watcherResult.score.trackers} days={watcherResult.days}/>
                }
              </div>
            </div>
          </Box>)
}

export default WatcherCard
