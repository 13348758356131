import moment from 'moment'
import { find } from 'lodash'

import * as actionTypes from '../actions/actionTypes'

const initialState = {
  now: moment(),
  months:[],
  trackersData: {},
  selector:'',
  selectorOptions: [],
  moodTrackerSummary: {},
  trackersSummary: [],
  journalSummary: '',
  trackers:[],
  renderType:'calendar'
}

const monthsForYear = (year) => {
  return [
      new Date(year,0,1),
      new Date(year,1,1),
      new Date(year,2,1),
      new Date(year,3,1),
      new Date(year,4,1),
      new Date(year,5,1),
      new Date(year,6,1),
      new Date(year,7,1),
      new Date(year,8,1),
      new Date(year,9,1),
      new Date(year,10,1),
      new Date(year,11,1),
    ]
}

const mothMoveForward = (state) => {
  const n = state.now.clone().add('year',1)
  return {
    ...state,
    now: n,
    months: monthsForYear(n.year()),
    needsUpdate: true
  }
}

const monthMoveBackward = (state) => {
  const n  = state.now.clone().subtract('year',1)
  return {
    ...state,
    now: n,
    months: monthsForYear(n.year()),
    needsUpdate: true
  }
}

const generateMonths = (state, action) => {
  const year = action.year
  const months = [
      new Date(year,0,1),
      new Date(year,1,1),
      new Date(year,2,1),
      new Date(year,3,1),
      new Date(year,4,1),
      new Date(year,5,1),
      new Date(year,6,1),
      new Date(year,7,1),
      new Date(year,8,1),
      new Date(year,9,1),
      new Date(year,10,1),
      new Date(year,11,1),
    ]

  return {
    ...state,
    months: months
  }
}

const monthSetNow = (state, action) => {
  return {
    ...state,
    now: action.date,
    needsUpdate: true
  }
}

// const monthLoadTrackers = (state, action) => {
//   const trackersData = {}
//   console.log('monthLoadTrackers', action.value)
//   switch (action.value) {
//     case 'moodTracker':
//       forEachDayOfTheYear(state.now,(date) => {
//         const randomElementPm = array[Math.floor(Math.random() * array.length)]
//         const randomElementAm = array[Math.floor(Math.random() * array.length)]
//
//         trackersData[date.format(DATE_FORMAT)] = { pm: randomElementPm, am: randomElementAm }
//       })
//       // this.setState({trackersData})
//       console.log('trackersData',trackersData)
//       break;
//     case 'meditation':
//       forEachDayOfTheYear(state.now,(date) => {
//         const colorClass = whiteGreenArray[Math.floor(Math.random() * whiteGreenArray.length)]
//         trackersData[date.format(DATE_FORMAT)] = colorClass
//       })
//       // this.setState({trackersData})
//       break;
//     case 'excersise':
//       forEachDayOfTheYear(state.now,(date) => {
//         const colorClass = whiteGreenArray[Math.floor(Math.random() * whiteGreenArray.length)]
//         trackersData[date.format(DATE_FORMAT)] = colorClass
//       })
//       // this.setState({trackersData})
//       break;
//     default:
//       // return ''
//     }
//
//   return {
//     ...state,
//     trackersData: trackersData
//   }
// }

const monthSetSelector = (state, action) => {
  let renderType = null
  let track = null
  if (action.selector === 'dayRates') {
    renderType = 'dayRatesCharts'
  } else {
    track = find(state.trackers, t => t.id === action.selector)

    if (track && track.type === 'numbers') {
      renderType ='trackerCharts'
    } else {
      renderType = 'calendar'
    }
  }

  return {
    ...state,
    selector:action.selector,
    trackSelected: track,
    renderType: renderType
  }
}

const monthSetSelectorOptions = (state, action) => {
  return {
    ...state,
    selectorOptions: action.options
  }
}

const monthSetTrackersData = (state, action) => {
  return {
    ...state,
    trackersData: action.data,
    needsUpdate: false
  }
}

const monthSetField = (state, action) => {
  return {
    ...state,
    [action.field]: action.value
  }
}

const monthAddTracker = (state, action) => {
  const newTrackers = [...state.trackers, action.tracker]
  return {
    ...state,
    trackers:newTrackers
  }
}

const monthOnBackToToday = (state) => {
  const n = moment()
  return {
    ...state,
    now: n,
    months: monthsForYear(n.year())
  }
}

const monthSetCompareTracker = (state, action) => {
  const tracker = find(state.trackers, t => t.id === action.value)
  return {
    ...state,
    compareTrackerName: action.value,
    compareTrackerData: action.data,
    compareTracker: tracker
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.MONTH_MOVE_FORWARD: return mothMoveForward(state, action)
  case actionTypes.MONTH_MOVE_BACKWARD: return monthMoveBackward(state, action)
  case actionTypes.GENERATE_MONTHS: return generateMonths(state, action)
  case actionTypes.MONTH_SET_NOW: return monthSetNow(state,action)
  // case actionTypes.MONTH_LOAD_TRACKERS: return monthLoadTrackers(state, action)
  case actionTypes.MONTH_SET_SELECTORL: return monthSetSelector(state, action)
  case actionTypes.MONTH_SET_SELECTOR_OPTIONS: return monthSetSelectorOptions(state, action)
  case actionTypes.MONTH_SET_TRACKERS_DATA: return monthSetTrackersData(state, action)
  case actionTypes.MONTH_SET_FIELD: return monthSetField(state, action)
  case actionTypes.MONTH_ADD_TRACKER: return monthAddTracker(state, action)
  case actionTypes.MONTH_ON_BACK_TO_TODAY: return monthOnBackToToday(state, action)
  case actionTypes.MONTH_SET_COMPARE_TRACKER: return monthSetCompareTracker(state, action)
  default: return state
  }
}

export default reducer
