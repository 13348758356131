import client from '../../services/client'
import * as actionTypes from './actionTypes'

const setTrackers = (value) => {
  return {
    type: actionTypes.NEW_WATCHER_SET_TRACKERS,
    value: value
  }
}

const setNewWatcherField = (field, value) => {
  return {
    type: actionTypes.NEW_WATCHER_SET_FIELD,
    field: field,
    value:value
  }
}

export const newWatcherOnLoadTrackers = (uid) => dispach => {
  client.fetchTrackers(uid)
  .then( data => dispach(setTrackers(data)))
}

export const newWatcherCreateWatcher = (uid, entry, history) => dispach => {
  window.gtag('event', 'watcher_created', { 'watcher_name': entry.name, 'trackers_count': entry.trackers.length })
  client.createWatcher(uid, entry)
  .then( () => {
    history.push('/watchers')
  })
}

export const newWatcherLoadWatchers = (uid) => dispach => {
  client.fetchWatchers(uid)
  .then((watchers) =>  {
    dispach(setNewWatcherField('watchers', watchers))
  })
}
