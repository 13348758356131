import React from 'react'
import { Modal, Row, Col, Button, Input, Tabs, Spin, Form, Alert } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment'
import { Link } from 'react-router-dom'
import { isEmpty, lowerCase } from 'lodash'
import { connect } from 'react-redux'

import Layout from '../Layout/Layout'
import GroupedTrackers from '../../components/GroupedTrackers/GroupedTrackers'
import WatchersSummary from '../../components/WatchersSummary/WatchersSummary'
import TrackersFastEntry from '../../components/TrackersFastEntry/TrackersFastEntry'
import TrackersEmpty from '../../components/TrackersEmpty/TrackersEmpty'
import TrackersFeedbackModal from '../../components/TrackersFeedbackModal/TrackersFeedbackModal'
import ViewJournalModal from '../../components/ViewJournalModal/ViewJournalModal'

import { ScrollableDate, Box } from '../../components/UI'
import './Week.scss'
import * as actions from '../../store/actions'
import { countMissingDays, isInThisWeek } from '../../utils/week'

const { TabPane } = Tabs;

class Week extends React.Component {

  loadData() {
      const { now, uid, dataLoaded } = this.props
      const { year, month, day } = this.props.match.params
      let date = null
      if (year && month && day) {
        date = moment(`${year}-${month}-${day}`)
      } else {
        date = now
      }

      if (!dataLoaded) {
        this.props.onWeekSelectDate(date)
        this.props.onInitTrackers(uid, date.format('YYYY'), date.week(), this.props.trackers, this.props.firstTimeSetup)
        this.props.onFetchStreaks(uid)
        this.props.onFetchWatchers(uid)
        this.props.onFetchWatcherSummary(uid)
      }
    }

  componentWillUnmount() {
    this.props.onResetWeek()
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate() {
    this.loadData()
  }

  handleOnTrackerClicked = (trackerType, values, index, id) => {
    const tracker = trackers.find(t => t.id === id)
    window.gtag('event','tracker_clicked', { 'tracker_name': tracker ? tracker.trackerName : '' })

    this.props.onTrackerClicked(trackerType, values, index, id)
  }

  handleOnClickFeedback = (watcherSummary) => {
    this.props.onOpenFeedback(watcherSummary)
  }

  render () {
    const {
      uid,
      now,
      trackers,
      onTrackDelete,
      onEditTrackName,
      onTrackerClicked,
      trackersLoading,
      categories,
      onForward,
      onBackward,
      onWeekSelectDate,
      onLoadCategories,
      onBackToToday,
      onFilterTrackerChange,
      streaks,
      watcherSummaryList,
      watchers,
      accountProperties,
      feedbackTracker,
      openFeedback,
      trackerJournals,
      trackerJournalsToday,
      openViewJournal,
      onViewJournalClose,
      journalList,
      onViewJournalOpen
    } = this.props

    const isThisWeek = isInThisWeek(now)
    const missingDays = countMissingDays(trackers)

    if (!accountProperties.hasTrackers) {
      return (
        <Layout>
           <div className="Container">
                 <Row justify="center" style={{marginTop:10}}>
                    <Col xl={14} lg={14} md={24} xs={24} sm={24}>
                        <TrackersEmpty />
                    </Col>
                </Row>
          </div>
      </Layout>)
    }

    return (
      <Layout>
         <div className="Container">
               <Row justify="center" style={{marginTop:10}}>
                <Col xl={14} lg={14} md={24} xs={24} sm={24}>

                  <div className="Week-Button-Toolbar">
                    <Button type='primary' shape='round' className="trackers-step">
                      <Link to="/trackers/new"><PlusOutlined /> Add Tracker</Link>
                    </Button>
                    <Button type="primary" shape="round" className="watchers-step">
                      <Link to="/watchers/new"><PlusOutlined /> Add Watcher</Link>
                    </Button>

                    {/*
                    <Button type="danger" shape="round">
                      <Link to="/wizard">Start wizard</Link>
                    </Button>
                    */}
                  </div>

                {!isEmpty(watchers) && (
                  <>
                    <h3 className="Week-Title">
                      <label>Watchers</label>
                    </h3>
                    {
                      !isEmpty(this.props.watcherSummaryList) ?
                      (
                        <WatchersSummary
                          trackers={trackers}
                          items={watcherSummaryList}
                          onClickFeedback={this.handleOnClickFeedback}
                          />
                      )
                      :
                      (
                        <Spin />
                      )
                    }
                  </>
                )}

                <h3 className="Week-Title"><label>Trackers</label></h3>
                <ScrollableDate
                  type='week'
                  date={now.clone()}
                  onForward={onForward}
                  onBackward={onBackward}
                  onDateSelected={onWeekSelectDate}
                  onBackToToday={onBackToToday} />

                  <Row className="Week-Toolbar">
                    <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                      <div>{trackers.length} trackers</div>
                    </Col>
                    <Col xl={16} lg={16} md={16} xs={24} sm={24}>
                      <Input placeholder="Find tracker" onChange={onFilterTrackerChange}/>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      {
                        isThisWeek &&
                          (missingDays <= 0
                          ?
                          (<Alert style={{margin:10}} message="Congrats ! You completed your entries for today" type="warning" />)
                          :
                          (<Alert style={{margin:10}} message={`You have ${missingDays} trackers to complete`} type="error" />)
                          )
                      }

                      <Tabs defaultActiveKey="1" centered>
                        <TabPane tab="Week" key="1">
                          <GroupedTrackers
                            uid={uid}
                            trackers={trackers}
                            onTrackDelete={onTrackDelete}
                            now={now}
                            onEditTrackName={onEditTrackName}
                            onTrackerClicked={onTrackerClicked}
                            loading={trackersLoading}
                            categories={categories}
                            onLoadCategories={() => onLoadCategories(uid)}
                            streaks={streaks}
                            watchers={watcherSummaryList}
                            onOpenFeedback={this.props.onOpenFeedback}
                            trackerJournalsToday={trackerJournalsToday}
                            trackerJournals={trackerJournals}
                            onViewJournalOpen={(trackerId) => onViewJournalOpen(uid, trackerId)}
                            />
                          </TabPane>
                          <TabPane tab="Today" key="2">
                            <TrackersFastEntry
                              uid={uid}
                              trackers={trackers}
                              onTrackerClicked={onTrackerClicked}
                            />
                          </TabPane>
                        </Tabs>
                      </Col>
                  </Row>
                </Col>
                </Row>
         </div>

         <TrackersFeedbackModal
           openFeedback={openFeedback}
           onFeedbackClose={this.props.onFeedbackClose}
           feedbackTracker={feedbackTracker}
           trackers={trackers}
           onFeedbackSave={(values) => this.props.onFeedbackSave(uid, values)}
           />

         <ViewJournalModal
           openViewJournal={openViewJournal}
           onViewJournalClose={onViewJournalClose}
           journalList={journalList}
          />
      </Layout>
    )
  }
}

const mapStateToProp = state => {
  return {
    now: state.week.now,
    trackers: isEmpty(state.week.filterTrackerText) ? state.week.trackers : state.week.trackers.filter(e => lowerCase(e.trackerName).includes(lowerCase(state.week.filterTrackerText))) ,
    dayRates: state.week.dayRates,
    moods: state.week.moods,
    uid: state.auth.uid,
    trackersLoading: state.week.trackersLoading,
    dataLoaded: state.week.dataLoaded,
    countersLoaded: state.week.countersLoaded,
    firstTimeSetup: state.auth.firstTimeSetup,
    logEntryValues: state.week.logEntryValues,
    categories: state.week.categories,
    welcomeMessage: state.auth.welcomeMessage,
    moodTrackerLoading: state.week.moodTrackerLoading,
    dayChartData: state.day.dayChartData,
    dayChartSelect: state.day.dayChartSelect,
    filterTrackerText: state.week.filterTrackerText,
    streaks: state.week.streaks,
    watcherSummaryList: state.week.watcherSummaryList,
    watchers: state.week.watchers,
    accountProperties: state.auth.accountProperties,
    feedbackTracker: state.week.feedbackTracker,
    openFeedback: state.week.openFeedback,
    trackerJournalsToday: state.week.trackerJournalsToday,
    trackerJournals: state.week.trackerJournals,
    journalList: state.week.journalList,
    openViewJournal: state.week.openViewJournal
  }
}

const mapActionsToProps = dispach => {
  return {
    onForward: () => dispach(actions.weekMoveForward()),
    onBackward: () => dispach(actions.weekMoveBackward()),
    onWeekSelectDate: (date) => dispach(actions.weekSelectDate(date)),
    onTrackerClicked: (uid, trackerId, values, trackerType, index, id) => dispach(actions.sendTrackerValue(uid, trackerId, values, trackerType, index, id)),
    onDayRateClicked: (uid, date, day, value) => dispach(actions.dayRateClickedSend(uid, date, day,value)),
    onMoodClicked: (time, week, mood, date) => dispach(actions.moodClicked(time,week,mood, date)),
    onInitTrackers: (uid, year, week, trackers, firstTimeSetup) => dispach(actions.weekInitTrackers(uid, year, week, trackers, firstTimeSetup)),
    onResetWeek: () => dispach(actions.weekReset()),
    moodClickedStarted: (uid, date, time, week, mood) => dispach(actions.moodClickedStarted(uid, date, time, week, mood)),
    onInitMoodTracker: (uid, year, week) => dispach(actions.weekLoadMoodTrackerData(uid, year, week)),
    onInitDayRates: (uid, year, week) => dispach(actions.fetchDayRates(uid, year, week)),
    onSaveLogEntry: (uid, date, label, value) => dispach(actions.weekSaveLogEntry(uid, date, label, value)),
    onInitLogEntries: (uid, year, week) => dispach(actions.weekInitLogEntries(uid, year, week)),
    onChangeLogEntry: (date, label, value) => dispach(actions.weekChangeLogEntry(date, label, value)),
    onTrackDelete: (uid, trackerId, watchers) => dispach(actions.weekOnTrackDelete(uid, trackerId, watchers)),
    onEditTrackName: (uid, config, values) => dispach(actions.weekEditTrackName(uid, config, values)),
    onLoadCategories: (uid) => dispach(actions.weekLoadCategories(uid)),
    onBackToToday: () => dispach(actions.weekOnBackToToday()),
    onWelcomeMessageDone: () => dispach(actions.authHideWelcomeMessage()),
    onDayChartChange: (uid, value, year, month) => dispach(actions.dayOnDayChartChange(uid,value, year, month)),
    onFilterTrackerChange: (e) => dispach(actions.weekFilterTrackerChange(e.target.value)),
    onFetchStreaks: (uid) => dispach(actions.weekFetchStreaks(uid)),
    onFetchWatcherSummary: (uid) => dispach(actions.weekFetchWatchersSummary(uid)),
    onFetchWatchers: (uid) => dispach(actions.weekFetchWatchers(uid)),
    onOpenFeedback: (tracker) => dispach(actions.weekOpenFeedback(tracker)),
    onFeedbackClose: () => dispach(actions.weekCloseFeedback()),
    onFeedbackSave: (uid, values) => dispach(actions.weekSaveFeedback(uid, values)),
    onViewJournalOpen: (uid, trackerId) => dispach(actions.weekViewJournalLoad(uid, trackerId)),
    onViewJournalClose: () => dispach(actions.weekViewJournalClose())
  }
}

export default connect(mapStateToProp,mapActionsToProps)(Week);
