import React from 'react'
import { connect } from 'react-redux'

import * as actions from '../../../store/actions'

class LoginAs extends React.Component {
  render () {
    return <div></div>
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    const p = {}
    for (let params of query.entries()) {
      p[params[0]] = params[1]
    }
    const authResult = {
      user: {
        uid: p['uid'],
        displayName: p['username']
      }
    }
    this.props.onLogin(authResult)
    this.props.history.push('/')
  }
}

const mapDispachToProps = dispach => {
  return {
    onLogin: (authResult) => dispach(actions.authSignInUser(authResult))
  }
}

export default connect(null, mapDispachToProps)(LoginAs)
