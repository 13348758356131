const DEVELOPMENT_ENDPOINT = 'http://localhost:5001/moods-webapp/us-central1/getApi'
const PRODUCTION_ENDPOINT = 'https://us-central1-moods-webapp.cloudfunctions.net/getApi'

const END_POINT = process.env.NODE_ENV == "development" ? DEVELOPMENT_ENDPOINT :  PRODUCTION_ENDPOINT
//const END_POINT =
import axios from 'axios'

export const get = (path) => {
  return axios.get(`${END_POINT}${path}`)
}

const fetchAllStreaksForUser = (uid) => {
  return get(`/streaks/${uid}`)
}

const fetchWatchersForUser = (uid) => {
  return get(`/watchers/${uid}`)
}

const fetchWatcherResultForUser = (uid, watcherId) => {
  return get(`/watchers/${uid}/${watcherId}`)
}

const fetchWatcherSummaryForUser = (uid) => {
  return get(`/watchersSummary/${uid}`)
}

export default {
  fetchAllStreaksForUser,
  fetchWatchersForUser,
  fetchWatcherResultForUser,
  fetchWatcherSummaryForUser
}
