import * as actionTypes from '../actions/actionTypes'

import { lowerCase, clone } from 'lodash'

import { getPredefinedTrackers } from '../../data/predefinedTrackers.js'

const resetSteps = (state) => {
  state.options = { selections: [], amPm: false }
  state.numbers = {
    amPm: false,
    minNumber: 1,
    maxNumber:10,
    steps: 1,
    timesAWeek: 7
  }
  state.checklist = { amPm: false }
  state.trackerType = 'numbers'
  state.current = 1
}

const initialState = {
  current: 1 ,
  trackerType: 'numbers',
  options: {
    amPm: false,
    selections: []
  },
  numbers: {
    amPm: false,
    minNumber: 1,
    maxNumber:10,
    steps: 1,
    timesAWeek: 7
  },
  checklist: { amPm: false },
  predefinedTrackers: [
    {id:1, options:{ trackerName: 'Excerise'}}
  ]
}

const newTrackerOnStep1Clicked = (state, action) => {
  return {
    ...state,
    trackerType: action.value,
    current: 1
  }
}

const newTrackOnPrev = (state) => {
  const newState = {
    ...state,
    current: state.current - 1
  }

  if (newState.current === 0) {
    resetSteps(newState)
  }

  return newState
}

const newTrackerOnCheckListFinished = (state, action) => {
  return {
    ...state,
    current: 2,
    checklist: action.values
  }
}

const newTrackerOnNumbersFinished = (state, action) => {
  return {
    ...state,
    current: 2,
    numbers: action.values
  }
}

const newTrackerOnOptionsFinished = (state, action) => {
  return {
    ...state,
    current: 2,
    options: {
      ...state.options,
      ...action.values
    }
  }
}

const newTrackerOnAddOption = (state, action) => {
  const selections = [...state.options.selections, action.values]
  return {
    ...state,
    options:{
      ...state.options,
      selections: selections
    }
  }
}

const newTrackerOnRemoveOption = (state, action) => {
  const selections = state.options.selections.filter((item,idx) => idx !== action.index)
  return {
    ...state,
    options:{
      ...state.options,
      selections: selections
    }
  }
}

const newTrackerResetSteps = (state) => {
  const newState = {...state}

  resetSteps(newState)

  return newState
}

const newTrackerSetField = (state, action) => {
  return {
    ...state,
    [action.field]: action.value
  }
}

const newTrackOnSelectOptionsTrack = (state, action) => {
  return {
    ...state,
    options: {
      ...state.options,
      selections: action.track.options.selections
    }
  }
}

const newTrackerOnResetValues = (state) => {
  const newState = {...state}

  resetSteps(newState)

  return newState
}

const newTrackerOnCheckListUpdate = (state, action) => {
  if (action.values && action.values[0] && action.values[0].name ) {
    return {
      ...state,
      [action.trackerType]: {
        ...state[action.trackerType],
        [action.values[0].name[0]]:action.values[0].value
      }
    }
  } else {
    return state
  }
}

const newTrackerSetPredefinedTracker = (state, action) => {
  const trackers = getPredefinedTrackers()
  const tracker = trackers.find( i => lowerCase(i.trackerName) === lowerCase(action.value) )

  // const numbers = tracker ? { trackerName: tracker.trackerName, category: tracker.category, amPm: tracker.amPm, minNumber: parseInt(tracker.minNumber), maxNumber:parseInt(tracker.maxNumber), steps: parseInt(tracker.steps), units: tracker.units, theLessTheBest: tracker.theLessTheBest } : {}
  const numbers = tracker ? clone(tracker) : {}

  return {
    ...state,
   predefinedTrackerSelected: action.value,
   numbers: numbers
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRACKER_STEP_ONE_CLICKED: return newTrackerOnStep1Clicked(state, action)
    case actionTypes.NEW_TRACKER_ON_PREV: return newTrackOnPrev(state, action)
    case actionTypes.NEW_TRACK_ON_CHECK_LIST_FINISHED: return newTrackerOnCheckListFinished(state, action)
    case actionTypes.NEW_TRACK_ON_NUMBERS_FINISHED: return newTrackerOnNumbersFinished(state, action)
    case actionTypes.NEW_TRACK_OPTIONS_FINISHED: return newTrackerOnOptionsFinished(state, action)
    case actionTypes.NEW_TRACK_ON_ADD_OPTION: return newTrackerOnAddOption(state, action)
    case actionTypes.NEW_TRACK_ON_REMOVE_OPTION: return newTrackerOnRemoveOption(state, action)
    case actionTypes.NEW_TRACKER_RESET_STEPS: return newTrackerResetSteps(state, action)
    case actionTypes.NEW_TRACKER_SET_FIELD: return newTrackerSetField(state, action)
    case actionTypes.NEW_TRACK_ON_SELECT_OPTIONS_TRACK: return newTrackOnSelectOptionsTrack(state, action)
    case actionTypes.NEW_TRACKE_ON_RESET_VALUES: return newTrackerOnResetValues(state, action)
    case actionTypes.NEW_TRACKER_ON_CHECK_LIST_UPDATE: return newTrackerOnCheckListUpdate(state, action)
    case actionTypes.NEW_TRACKER_SET_PREDEFINED_TRACKER: return newTrackerSetPredefinedTracker(state, action)
    default: return state
  }
}

export default reducer
