import React, { Component } from 'react'
import { Row, Col, Button } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Layout from '../Layout/Layout'
import GroupedTrackers from '../../components/GroupedTrackers/GroupedTrackers'
import MoodTracker from '../../components/MoodTracker/MoodTracker'
import Journal from '../../components/Journal/Journal'
import DayMonthChart from '../../components/DayMonthChart/DayMonthChart'
import { ScrollableDate } from '../../components/UI'
import * as actions from '../../store/actions'

class Day extends Component {
    loadData() {
      //    this.props.onLoadTrackers(this.props.uid) 
      const {
        uid,
        now,
        trackers,
        firstTimeSetup,
        dataLoaded,
        onInitTrackers,
        onInitMoodTracker,
        onInitLogEntries,
        onDataLoaded,
        onInitDayRates,
        onDayChartChange
      } = this.props

      if (!dataLoaded) {
        onDataLoaded()
        const year = now.format('YYYY')
        const week = now.week()
        onInitTrackers(uid, year, week, trackers, firstTimeSetup)
        onInitMoodTracker(uid, year, week)
        onInitLogEntries(uid, year, week)
        onInitDayRates(uid, year, week)
        onDayChartChange(uid, 'moodsTracker', year, parseInt(now.format('MM')))
      }
    }

    componentDidMount() {
      this.loadData()
    }

    componentDidUpdate() {
      this.loadData()
    }

    render () {
        const {
            trackers,
            uid,
            trackersLoading,
            categories,
            onLoadCategories,
            now,
            moods,
            logEntryValues,
            dayRates,
            moodClickedStarted,
            onDayRateClicked,
            onSaveLogEntry,
            onChangeLogEntry,
            onForward,
            onBackward,
            onWeekSelectDate,
            onBackToToday,
            onTrackDelete,
            onEditTrackName,
            onTrackerClicked,
            onDayChartChange,
            dayChartData,
            dayChartSelect
        } = this.props

        return (
        <Layout>
            <Row className="Container">
              <Col xl={{ span:7, offset: 0}} lg={{ span:7, offset: 0}} md={24} xs={24} sm={24}>
                <ScrollableDate
                  type='day'
                  date={now.clone()}
                  onForward={onForward}
                  onBackward={onBackward}
                  onDateSelected={onWeekSelectDate}
                  onBackToToday={onBackToToday} />
                <div style={{height:30, marginTop:25, marginBottom: 25}}>
                  <div style={{position:'absolute', left: 27, top: 121}}>{trackers && trackers.length} trackers</div>
                  <Button style={{float:'right', left:
                   -20}} type='primary' shape='round'>
                    <Link to="/trackers/new">Add new tracker</Link>
                  </Button>
                </div>
                <Journal
                  format='day'
                  values={logEntryValues}
                  date={now}
                  dayRates={dayRates}
                  onDayRate={(day, value, date) => onDayRateClicked(uid, date, day, value)}
                  onSaveLogEntry={(date, label, value) => onSaveLogEntry(uid, date, label, value)}
                  onChangeLogEntry={onChangeLogEntry}/>
                <MoodTracker
                  format='day'
                  date={now}
                  moods={moods}
                  onMoodClick={(time, week, mood, date) => moodClickedStarted(uid, date, time, week, mood)}/>
                  {trackers && 
                <GroupedTrackers
                  format='day'
                  uid={uid}
                  trackers={trackers}
                  onTrackDelete={onTrackDelete}
                  now={now}
                  onEditTrackName={onEditTrackName}
                  onTrackerClicked={onTrackerClicked}
                  loading={trackersLoading}
                  categories={categories}
                  onLoadCategories={() => onLoadCategories(uid)}
                  />}
              </Col>

              <Col span={17} style={{position:'relative'}}>
                  <DayMonthChart 
                    date={now.clone()}
                    trackers={trackers}
                    onChange={(value, year, month) => onDayChartChange(uid, value, year, month)}
                    data={dayChartData}
                    selectValue={dayChartSelect}
                  />
              </Col>
            </Row>
        </Layout>)
    }
}

const mapStateToProps = state => {
    return {
        uid: state.auth.uid,
        trackers: state.day.trackers,
        dataLoaded: state.day.dataLoaded,
        now: state.day.now,
        trackersLoading: state.day.trackersLoading,
        firstTimeSetup: state.auth.firstTimeSetup,
        moods: state.day.moods,
        dayRates: state.day.dayRates,
        logEntryValues: state.day.logEntryValues,
        categories: state.day.categories,
        dayChartData: state.day.dayChartData,
        dayChartSelect: state.day.dayChartSelect
    }
}

const mapActionToProps = dispach => {
    return {
        onInitDayRates: (uid, year, week) => dispach(actions.dayFetchDayRates(uid, year, week)),
        onDataLoaded: () => dispach(actions.setDayField('dataLoaded',true)),
        onInitLogEntries: (uid, year, week) => dispach(actions.dayInitLogEntries(uid, year, week)),
        onInitMoodTracker: (uid, year, week) => dispach(actions.dayLoadMoodTrackerData(uid, year, week)),
        onInitTrackers: (uid, year, week, trackers, firstTimeSetup) => dispach(actions.dayInitTrackers(uid, year, week, trackers, firstTimeSetup)),
        onLoadCategories: (uid) => dispach(actions.dayLoadCategories(uid)),
        moodClickedStarted: (uid, date, time, week, mood) => dispach(actions.dayMoodClickedStarted(uid, date, time, week, mood)),
        onDayRateClicked: (uid, date, day, value) => dispach(actions.dayOnDayRateClickedSend(uid, date, day,value)),
        onSaveLogEntry: (uid, date, label, value) => dispach(actions.daySaveLogEntry(uid, date, label, value)),
        onChangeLogEntry: (date, label, value) => dispach(actions.dayChangeLogEntry(date, label, value)),
        onForward: () => dispach(actions.dayMoveForward()),
        onBackward: () => dispach(actions.dayMoveBackward()),
        onWeekSelectDate: (date) => dispach(actions.daySelectDate(date)),
        onBackToToday: () => dispach(actions.dayOnBackToToday()),
        onTrackDelete: (uid, trackerId) => dispach(actions.dayOnTrackDelete(uid, trackerId)),
        onEditTrackName: (uid, config, values) => dispach(actions.dayEditTrackName(uid, config, values)),
        onTrackerClicked: (uid, trackerId, values, trackerType, index, id) => dispach(actions.daySendTrackerValue(uid, trackerId, values, trackerType, index, id)),
        onDayChartChange: (uid, value, year, month) => dispach(actions.dayOnDayChartChange(uid,value, year, month))
      }
}

 
export default connect(mapStateToProps, mapActionToProps)(Day);