import { getPredefinedTrackers } from '../data/predefinedTrackers'
import { map, compact, lowerCase } from 'lodash'
import { MAP_WATCHERS_TO_TRACKERS } from '../data/mapToTrackersAndWatchers'

export const getTrackers = (trackerNames) => {
  const predefinedTrackers = getPredefinedTrackers()
  const trackers = map(trackerNames, value => {
    return predefinedTrackers.find(t => lowerCase(t.trackerName) === lowerCase(value) )
  })

  return compact(trackers)
}

export const buildWatchers = (watcherNames, trackers) => {
  return map(watcherNames, watcherName => {
      let watcherTrackers = MAP_WATCHERS_TO_TRACKERS[watcherName].map(trackerName => {
        return trackers.find(t => lowerCase(t.trackerName) == lowerCase(trackerName))
      })

      watcherTrackers = compact(watcherTrackers)

      return {
        name: watcherName,
        days: 15,
        trackers: watcherTrackers.map(t => t.id)
      }
    })
}
