import React from 'react'
import './Box.scss'

const Box = (props) => {
  const styles = props.style || {}
  const boxProps = {}

  if (props.fixed) {
    styles.position = 'fixed';
    styles.width = '50%'
  }

  if (props.loading) {
    styles.opacity = '0.2';
  }

  if (props.disabled) {
    styles.opacity = '0.5';
    styles.pointerEvents = 'none';
    boxProps.disabled = true
  }

  if (!props.title) {
    styles.border = "0px"
  }

  return (
    <div className="Box-Container">
      {props.title ?
        (<div className="Box-Title">{props.title}</div>)
        :
        (<div className="Box-Top"></div>)
      }
      <div className="Box-Body" style={styles} {...boxProps}>
        {props.children}
      </div>
    </div>
  )
}

export default Box
