import React from 'react'
import { groupBy, isUndefined, defaultTo, toLower } from 'lodash'
import { Spin } from 'antd'

import Trackers from '../../components/Trackers/Trackers'
import { Box } from '../UI'

const GroupedTrackers = ({
  trackers,
  uid,
  onTrackDelete,
  now,
  onEditTrackName,
  onTrackerClicked,
  trackersLoading,
  categories,
  onLoadCategories,
  loading,
  format,
  streaks,
  watchers,
  onOpenFeedback,
  trackerJournals,
  trackerJournalsToday,
  onViewJournalOpen
}) => {
  if (loading) {
    return <Spin />
  } else {
    const trackersWithIndex = trackers.map((config, index) => { return {...config, index: index } })
    const groupedTrackers = groupBy(trackersWithIndex, t => {
      return isUndefined(t.category) ? 'Other' : toLower(t.category)
    })

    const keys = Object.keys(groupedTrackers)

    return keys.map( k => {
      return (
        <Box key={k} title={`${k} Trackers`}>
            <Trackers
              format={format}
              loading={trackersLoading}
              trackers={defaultTo(groupedTrackers[k],[])}
              onTrackerDelete={(trackerId) => onTrackDelete(uid, trackerId, watchers)}
              onTrackerClicked={(trackerType, values, index, id) => onTrackerClicked(uid, id,values, trackerType, index, id)}
              date={format === 'day' ? now.clone() : now.clone().startOf('week')}
              onEditTrackName={(config, values) => onEditTrackName(uid, config, values)}
              categories={categories}
              onLoadCategories={onLoadCategories}
              streaks={streaks}
              watchers={watchers}
              onOpenFeedback={onOpenFeedback}
              trackerJournals={trackerJournals}
              trackerJournalsToday={trackerJournalsToday}
              onViewJournalOpen={onViewJournalOpen}
              />
        </Box>
      )
    })
  }
}

export default GroupedTrackers
