import React from 'react'
import { connect } from 'react-redux'

import * as actions from '../../../store/actions'

class Today extends React.Component {
  render () {
    return null
    // return <Redirect to="/" />
  }

  componentDidMount() {
    this.props.onResetWeek()
    this.props.history.push('/')
  }
}

const mapActionsToProps = dispach => {
  return {
    onResetWeek: () => dispach(actions.weekReset())
  }
}

export default connect(null, mapActionsToProps)(Today);
