import { forEach, isEmpty, lowerCase, uniqBy, uniq, compact, take } from 'lodash'

import { getPredefinedTrackers } from '../../data/predefinedTrackers'
import { MAP_ANSWERS_TO_TRACKERS, MAP_WATCHERS_TO_TRACKERS } from '../../data/mapToTrackersAndWatchers'

const hasAnswer = (responses, answer) => {
  return responses.map(r => lowerCase(r.answer)).includes(lowerCase(answer))
}

const findTracker = (trackers, name) => {
  const trackerFound = trackers.find( t => lowerCase(t.trackerName) === lowerCase(name))
  if (trackerFound) {
    return trackerFound
  } else {
    throw new Error(`Cannot find predefined tracker ${name}`)
  }
}

export const getRecommendedTrackers = (answers) => {
  const trackers = getPredefinedTrackers()
  let recommenedTrackers = []
  // let recommendedWatchers = []
  const responses = []

  forEach(answers, (values) => {
    forEach(values, value => {
      if (value.answer == true || !isEmpty(value.answer)) {
        responses.push(value)
      }
    })
  })

  forEach(MAP_ANSWERS_TO_TRACKERS, (values, key) => {
    if (hasAnswer(responses, key)) {
      forEach(values, value => {
        recommenedTrackers.push(findTracker(trackers, value))
      })
    }
  })

  recommenedTrackers = compact(recommenedTrackers)
  recommenedTrackers = uniqBy(recommenedTrackers, t => t.trackerName)

  // forEach(MAP_WATCHERS_TO_TRACKERS, (trackers, watcherName) => {
  //   const trackersToCompare = take(trackers, 2)
  //   let matches = 0
  //   forEach(recommenedTrackers, (tracker) => {
  //     if (trackersToCompare.includes(lowerCase(tracker.trackerName))) {
  //       matches = matches + 1
  //     }
  //   })
  //   if (matches >= 2) {
  //     recommendedWatchers.push(watcherName)
  //   }
  // })
  //
  // recommendedWatchers = uniq(recommendedWatchers)

  return recommenedTrackers
}

export const getRecommendedWatchers = (recommenedTrackers) => {
  let recommendedWatchers = []

  forEach(MAP_WATCHERS_TO_TRACKERS, (trackers, watcherName) => {
    // const trackersToCompare = take(trackers, 2)
    let matches = 0
    forEach(recommenedTrackers, (trackerName) => {
      if (trackers.includes(lowerCase(trackerName))) {

        matches = matches + 1
      }
    })
    if (matches >= 2) {
      recommendedWatchers.push(watcherName)
    }
  })

  recommendedWatchers = uniq(recommendedWatchers)

  return recommendedWatchers
}
