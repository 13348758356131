import React from 'react'
import { Row, Col } from 'antd'

import Layout from '../Layout/Layout'
import './About.scss'
import { UnorderedListOutlined, CheckCircleOutlined, NotificationOutlined } from '@ant-design/icons';

const About = () => {
  return (
    <Layout>
      <div className="Container">
        <Row justify="center" style={{marginTop:10}}>
          <Col xl={14} lg={14} md={24} xs={24} sm={24}>

            <div className="About">
              <div style={{textAlign:'center'}}><NotificationOutlined style={{fontSize:55, margin:20, padding:20}} /></div>
              <p className="About-Title">
                Tracker Buddy is a web app that helps you track your behaviors.
                Use it to identify your triggers and set goals to improve and take control of your mental and physical health.
              </p>

              <div className="About-Explain About-align-left">
                <div className="About-Text">
                  <h2>How does it work?</h2>
                  <p>
                    There are two components that are important to understand: Trackers and Watchers. Trackers are where you input your everyday data about the issues you are monitoring. Inputting data can be in the form of a 1-10 scale, hours or quantities. Watchers will be monitoring a group of trackers for a specified period of time.The watcher will give you an average score (between 1-10) on your progress, so you can take action if needed.
                  </p>
                </div>
                <div className="About-Icon">
                  <UnorderedListOutlined style={{fontSize:60, margin:'auto'}} />
                </div>
              </div>

              <div className="About-Explain About-align-right">
                <div className="About-Icon">
                  <CheckCircleOutlined style={{fontSize:60, margin:'auto'}} />
                </div>
                <div className="About-Text">
                  <h2>How does it help?</h2>
                  <p>
                    By inputting your tracker data daily you can build your own database, and use tools like reports to compare multiple trackers or view your progress in calendar format. Get to know yourself better and improve your life.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default About
