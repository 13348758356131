import React from 'react'

import MoodEntry from './MoodEntry/MoodEntry'
import { Box } from '../UI' 

const DAY_FORMAT = 'dddd D'

const MoodTracker = (props) => {
  const { date } = props
  const moods = [
    {label:'Awesome',   value: 1},
    {label:'Good',      value: 2},
    {label:'Alright',   value: 3},
    {label:'Meh',       value: 4},
    {label:'Horrible',  value: 5},
  ]

  const dates = [
    date.clone().add(0,'day'),
    date.clone().add(1,'day'),
    date.clone().add(2,'day'),
    date.clone().add(3,'day'),
    date.clone().add(4,'day'),
    date.clone().add(5,'day'),
    date.clone().add(6,'day')
  ]

  let content = null
  if (props.format === 'day') {
    content = (<MoodEntry
                  format="day"
                  label={props.date.format(DAY_FORMAT)}
                  date={props.date}
                  value={props.moods}
                  onMoodClick={props.onMoodClick}
                  week={parseInt(props.date.format('e'))}
                  moods={moods} />)
  } else {
    content = (
      <>
      <MoodEntry label={dates[0].format(DAY_FORMAT)} date={dates[0]} value={props.moods[0]} onMoodClick={props.onMoodClick} week={0} moods={moods} />
      <MoodEntry label={dates[1].format(DAY_FORMAT)} date={dates[1]} value={props.moods[1]} onMoodClick={props.onMoodClick} week={1} moods={moods} />
      <MoodEntry label={dates[2].format(DAY_FORMAT)} date={dates[2]} value={props.moods[2]} onMoodClick={props.onMoodClick} week={2} moods={moods} />
      <MoodEntry label={dates[3].format(DAY_FORMAT)} date={dates[3]} value={props.moods[3]} onMoodClick={props.onMoodClick} week={3} moods={moods} />
      <MoodEntry label={dates[4].format(DAY_FORMAT)} date={dates[4]} value={props.moods[4]} onMoodClick={props.onMoodClick} week={4} moods={moods} />
      <MoodEntry label={dates[5].format(DAY_FORMAT)} date={dates[5]} value={props.moods[5]} onMoodClick={props.onMoodClick} week={5} moods={moods} />
      <MoodEntry label={dates[6].format(DAY_FORMAT)} date={dates[6]} value={props.moods[6]} onMoodClick={props.onMoodClick} week={6} moods={moods} />
      </>
    )
  }

  return (
    <Box title='Mood Tracker' loading={props.loading}>
      {content}
    </Box>
  )
}

export default MoodTracker
