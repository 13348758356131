import React from 'react'
import { map, isEmpty, some, sortBy, reverse } from 'lodash'
import { Button } from 'antd'
import { RiseOutlined, FallOutlined } from '@ant-design/icons';
import cx from 'classnames'

import { getWatcherScoreColor, formatNumber } from '../../utils/utils'
import { WATCHERS_HIGH_SCORE, calculateTodayScore, isWatchetTodayHigh, isWatcherTodaLow } from '../../models/watchers'
import { Alert } from '../UI'

import './WatchersSummary.scss'

const Score = ({ value, title }) => {
  return (
          <div className="WatcherSummary-Score">
            <div className="WatcherSummary-Score-Title">{title}</div>
            <div
              className='WatcherSummary-Score-Value'
              style={{backgroundColor:getWatcherScoreColor(value)}}>
              {formatNumber(value)}
            </div>
          </div>)
}

const WatcherSummaryCard = ({ watcherSummary }) => {
  const { name, score, days, todayScore } = watcherSummary
  return (
    <div className={cx("WatcherSummaryCard", { "WatcherSummaryCard-HightScore": score >= WATCHERS_HIGH_SCORE })}>
      <label>{name}</label>
      <Score title={`Last ${days} days`} value={score} />
      <Score title="Today" value={todayScore} />
    </div>)
}

const WatcherFeedback =  ({ watcherSummary, onClick }) => {
  const { todayScore, name } = watcherSummary
  if (isWatchetTodayHigh(todayScore) || isWatcherTodaLow(todayScore)) {
    return (
      <div className="WatcherFeedback">
        {/*isWatchetTodayHigh(todayScore) && (
          <div>Looks like your having a <span className="WatcherFeedback-Bad">bad</span>  day on your <span className="WatcherFeedback-WatcherName">{name}</span>. Do you want to <Button shape="round">Tells us what happened today</Button></div>
        )*/}
        {
          isWatcherTodaLow(todayScore) && (
            <div>Looks like your having a <span className="WatcherFeedback-Good">good</span> day on your <span className="WatcherFeedback-WatcherName">{name}</span>. Do you want to <Button shape="round" onClick={onClick}>Tells us what worked today</Button></div>
          )
        }
      </div>
    )
  } else {
    return null
  }
}

const isScoreHigh = ({ score }) => {
  return score > WATCHERS_HIGH_SCORE
}

const WatchersSummary = (props) => {
  const { items, trackers, onClickFeedback } = props

  const isHigh = some(items, isScoreHigh)
  const itemsWithTodayScore = items.map(item => {
    return {
      ...item,
      todayScore: calculateTodayScore(item, trackers)
    }
  })
  if (!isEmpty(items)) {
    const itemsSorted = reverse(sortBy(itemsWithTodayScore, i => i.score ))
    return (
      <div className="WatchersSummary">
        { isHigh && <Alert message="Some watchers seems to be high in score, that's okay, this is a message so you can take action to improve" /> }
        <div className="WatchersSummary-List-Container">
          <div className="WatchersSummary-List">
            {map(itemsSorted, (watcherSummary, idx) => {
              return <WatcherSummaryCard key={idx} watcherSummary={watcherSummary} />
            })}
          </div>

          {/*map(itemsSorted, (watcherSummary, idx) => {
            return (<WatcherFeedback
                        key={idx}
                        watcherSummary={watcherSummary}
                        onClick={() => onClickFeedback(watcherSummary)}
                    />)
          })*/}
        </div>
      </div>)
    } else {
      return null
    }
}

export default WatchersSummary
