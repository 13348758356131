import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons';


import './TrackersEmpty.scss'

const TrackersEmpty = () => {
  return (
    <div className="TrackersEmpty">
      <p>You don't have any trackers.<br/> Add a tracker to get started</p>
      <div>
        <Button type="primary" shape="round">
          <Link to="/trackers/new"><PlusOutlined /> Add Tracker</Link>
        </Button>
      </div>
    </div>
  )
}

export default TrackersEmpty
