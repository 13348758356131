import React, {useState} from 'react'
import { Button, Row, Col } from 'antd'

import { Tracker } from '../../UI'

const Step3 = (props) => {
  const [selected, setSelected ] = useState({})
  // const [label, setLabel ] = useState([])

  const onClickHandler = (type, values) => {
    switch (type) {
      case 'checklist':
          const { name, value, type } = values
          if (type) {
            selected[name] = {...selected[name], [type]: !value }
            setSelected({...selected})
          } else {
            selected[name] = !value
            setSelected({...selected})
          }
        break;
      case 'options':
        if (values.type) {
          selected[values.name] = {...selected[values.name], [values.type]: values.value }
          setSelected({...selected})
        } else {
          selected[values.name] = values.value
          setSelected({...selected})
        }
        break;
      case 'numbers':
        if (values.type) {
          const type = values.type
          selected[values.name] = { ...selected[values.name], [type]: values.value }
        } else {
          selected[values.name] = values.value
        }

        setSelected({...selected})
        break;
      default:
    }

  }

  return (
    <div>
      <h2>Preview</h2>
      <Row>
        <Col offset={8} span={12}>
          <Tracker demo type={props.trackerType} configuration={{onClick: onClickHandler, entries:selected,...props.configuration}} />

          <div className="Step-Buttons">
            <Button style={{ margin: '0 8px' }} onClick={props.prev}>
              Previous
            </Button>
            <Button type='primary' htmlType='button' onClick={() => props.onCreate(props.trackerType, props.configuration)} >Create it !</Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Step3
