import { DATE_FORMAT_SHORT, DATE_FORMAT } from './constants'
import moment from 'moment'

export const formatWeekTitle = (fromDate, toDate) => {
  return `Week ${fromDate.week()} ${fromDate.format(DATE_FORMAT_SHORT)} to ${toDate.format(DATE_FORMAT_SHORT)}`
}

export const monthRange = (date, callback) => {
 return iterateDates(date.clone().startOf('month'),date.clone().endOf('month').subtract(1,'day'), callback)
}


export const iterateDates = (from, to, callback) => {
  const a = from.clone()
  const b = to.clone()

  const arr = []
  for (var m = moment(a); m.diff(b, 'days') <= 0; m.add(1, 'days')) {
    arr.push(callback(m.clone()))
  }
  return arr
}


export function weekDays(month, year) {
  const endDate = moment().date(0).month(month).year(year);

  return Array(endDate.date()).fill(0).map((_, i) => moment().date(i + 1).month(month).year(year))
    .map((day) => ({ day, week: day.week() }))
    .filter(({ week }, i, arr) => arr.findIndex((info) => info.week === week) === i)
    .map(({ day, week }) => ({ week, days: Array(7).fill(0).map((_, i) => moment(day).week(week).startOf('week').add(i, 'day')) }));
}

export const forEachDayOfTheYear = (date, callback) => {
  const a = date.clone().startOf('year');
  const b = date.clone().endOf('year')

  for (var m = moment(a); m.diff(b, 'days') <= 0; m.add(1, 'days')) {
    callback(m)
  }
}

export const generateWeekDates = (date) => {
  return [0,1,2,3,4,5,6].map((value) => moment(date).add(value,'days'))
}

export const isCurrentDate = date => {
  return date.format(DATE_FORMAT) === moment().format(DATE_FORMAT)
}
