const MENTAL_HEALTH_TO_TRACKERS = {
  'anxiety': ['anxiety','irritability','stress','panic','insomnia','restlessness','intense worry'],
  'bipolar disorder':['anxiety', 'irritability','restlessness','elevated mood','energized','mania','hopelessness','self-worth','pleasure'],
  'depression': ['insomnia','sadness','hopelessness','self-worth','pleasure'],
  'panic disorder':['panic','fear','intense worry','sadness'],
  'anxiety2':['anxiety','irritability','stress'],
  'panic': ['intrusive thoughts','panic'],
  'sleep':['sleep','insomnia'],
  'restlessness': ['restlessness','elevated mood', 'energized'],
  'euphoria':['euphoria','mania'],
  'fear':['fear'],
  'worry':['intense worry','sadness'],
  'hopelessness':['hopelessness'],
  'helplessness': ['helplessness','guilt','self-worth'],
  'pleasure': ['engaged in hobbies', 'pleasure']
}

const ACTIVITIES_TO_TRACKERS = {
  'exercise':['exercise'],
  'mindfulness':['mindfulness'],
  'work': ['work'],
  'adl': ['activities of daily living'],
  'social_life':['social life']
}

const LIFE_STYLET_TRACKERS = {
  'smoking':['smoking'],
  'alcohol': ['alcohol'],
  'sleep': ['sleep'],
  'physical_pain':['physical pain'],
  'healthy_eating':['healthy eating']
}

export const MAP_ANSWERS_TO_TRACKERS = {
  ...MENTAL_HEALTH_TO_TRACKERS,
  ...ACTIVITIES_TO_TRACKERS,
  ...LIFE_STYLET_TRACKERS
}

export const MAP_WATCHERS_TO_TRACKERS = {
  'Anxiety tendencies':['anxiety','worry'],
  'Depression tendencies':['sadness','intruisve thought'],
}

// export const MAP_WATCHERS_TO_TRACKERS = {
//   'Bipolar 1 tendencies':['mania','sadness','elevated mood','sleep'],
//   'Bipolar 2 tendencies':['hypomania','sadness','elevated mood','sleep'],
//   'Anxiety tendencies':['anxiety','worry','sleep'],
//   'Depression tendencies':['sadness','intruisve thought','sleep'],
//   'Social life': ['social life','work']
// }
