import React from 'react'
import { Modal, Form, Button } from 'antd'

import TrackersFeedback from '../TrackersFeedback/TrackersFeedback'

const TrackersFeedbackModal = (props) => {
  const {
    openFeedback,
    onFeedbackClose,
    feedbackTracker,
    trackers,
    onFeedbackSave
  } = props
  const [feedbackModalForm] = Form.useForm()

  const handeOnFinish = (values) => {
    onFeedbackSave(values)
  }

  const handleOnSubmit = () => {
    feedbackModalForm.submit()
  }

  return (
    <Modal
      visible={openFeedback}
      onCancel={onFeedbackClose}
      footer={[
        <Button key="primary" shape="round" type="primary" onClick={handleOnSubmit}>
          Save in my journal
        </Button>,
      ]}
      >
      <h1>Tells us what worked today</h1>

      <TrackersFeedback
        tracker={feedbackTracker}
        trackers={trackers}
        onFinish={handeOnFinish}
        form={feedbackModalForm}
       />
    </Modal>
  )
}

export default TrackersFeedbackModal
