import React from 'react'

import TrackSummaryCell from './TrackSummaryCell/TrackSummaryCell'

const TrackSummary = (props) => {
  return props.data.map(t => {
    return <TrackSummaryCell data={t} />
  })
}

export default TrackSummary
