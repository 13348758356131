import React from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'

import Layout from '../Layout/Layout'
import WatcherForm from '../../components/WatcherForm/WatcherForm'
import { Box } from '../../components/UI'
import * as actions from '../../store/actions'

class EditWatcher extends React.Component {

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.onLoadTrackers(this.props.uid)
    this.props.onLoadWatcher(this.props.uid, id)
  }

  componentDidUpdate(prevProps) {
    console.log(this.props.match.params.id,this.props.watcherId)
    if (this.props.match.params.id !== this.props.watcherId) {
      const { id } = this.props.match.params
      this.props.onLoadTrackers(this.props.uid)
      this.props.onLoadWatcher(this.props.uid, id)
    }
  }

  handleOnFinish = (value) => {
    const { id } = this.props.match.params
    const { onEditWatcher, history, uid } = this.props
    const entry = {
      days: value.days,
      name: value.name,
      trackers: value['checkbox-group']
    }
    onEditWatcher(uid, id, entry, history)
  }

  render() {
    const { trackers, watcher, history } = this.props
    const isRightWatcher = this.props.match.params.id === this.props.watcherId
    return (
      <Layout>
        <div className="Container">
          <Row justify="center" style={{marginTop:10}}>
            <Col xl={14} lg={14} md={24} xs={24} sm={24}>
              { (watcher && isRightWatcher) && (
                <Box title="Edit Watcher">
                  <WatcherForm
                    daysInitialValue={watcher.days}
                    watcherNameInitialValue={watcher.name}
                    trackersInitialValue={watcher.trackers}
                    buttonLabel="Edit Watcher"
                    onFinish={this.handleOnFinish}
                    trackers={trackers}
                    history={history}
                    />
                  </Box>
                )}
              </Col>
            </Row>
          </div>
      </Layout>)
  }
}

const mapStateToProp = state => {
  return {
    uid: state.auth.uid,
    trackers: state.editWatcher.trackers,
    watcher: state.editWatcher.watcher,
    watcherId: state.editWatcher.watcherId
  }
}

const mapActionToProps = dispach => {
  return {
    onLoadTrackers: (uid) => dispach(actions.editWatcherOnLoadTrackers(uid)),
    onEditWatcher: (uid, id, entry, history) => dispach(actions.editWatcherEditWatcher(uid, id, entry, history)),
    onLoadWatcher: (uid, id) => dispach(actions.editWatcherLoadWatcher(uid, id))
  }
}

export default connect(mapStateToProp, mapActionToProps)(EditWatcher);
