import client from '../../services/client'
import * as actionTypes from './actionTypes'

const setEditWatcherField = (field, value) => {
  return {
    type: actionTypes.EDIT_WATCHER_SET_FIELD,
    field: field,
    value: value
  }
}


export const editWatcherOnLoadTrackers = (uid) => dispach => {
  client.fetchTrackers(uid)
  .then( data => dispach(setEditWatcherField('trackers', data)))
}

export const editWatcherEditWatcher = (uid, id, entry, history) => dispach => {
  client.editWatcher(uid, id,entry)
  .then( () => {
    history.push('/watchers')
  })
}

export const editWatcherLoadWatcher = (uid, id) => dispach => {
  console.log('editWatcherLoadWatcher')
  client.fetchWatcher(uid, id)
  .then( watcher => {
    console.log('watcher', watcher)
    dispach(setEditWatcherField('watcher', watcher))
    dispach(setEditWatcherField('watcherId', id))
  })
}
