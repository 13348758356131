import React from 'react';
import { connect } from 'react-redux'

import './App.less';
// import './antd.less'
import * as actions from './store/actions'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// import TagManager from 'react-gtm-module'
import withTracker from './utils/withTracker'
import Week from './containers/Week/Week'
import Today from './containers/Week/Today/Today'
import Month from './containers/Month/Month'
// import Insight from './containers/Insight/Insight'
import NewTracker from './containers/NewTracker/NewTracker'
import Auth from './containers/Auth/Auth'
import Logout from './containers/Auth/Logout/Logout'
import LoginAs from './containers/Auth/LoginAs/LoginAs'
import Unknown from './containers/Unknown/Unknown'
import Day from './containers/Day/Day'
import Watchers from './containers/Watchers/Watchers'
import NewWatcher from './containers/NewWatcher/NewWatcher'
import EditWatcher from './containers/EditWatcher/EditWatcher'
import Wizard from './containers/Wizard/Wizard'
// import Terms from './containers/Public/Terms'
// import Privacy from './containers/Public/Privacy'
import About from './containers/Public/About'
import Account from './containers/Account/Account'

// const tagManagerArgs = {
//   gtmId: 'GTM-PL9NBM7'
// }

class App extends React.Component {
  componentDidMount() {
    // TagManager.initialize(tagManagerArgs)
    this.props.initSignInUser()
  }

  render() {
    const {
      accountProperties,
      isAuth,
      isAuthLoading
    } = this.props
    let router = null

    if (!isAuthLoading) {
      if (isAuth) {
        if (accountProperties) {
          if (accountProperties.isActive) {
            router = (
              <Switch>
                <Route path='/day' component={withTracker(Day, { title: 'Day' })} exact />
                <Route path='/today' component={withTracker(Today, { title: 'Today' })} exact />
                <Route path='/auth' component={withTracker(Auth, { title: 'Auth' })} exact />
                <Route path='/logout' component={withTracker(Logout, { title: 'Logout' })} exact />
                <Route path="/week/:year/:month/:day" component={withTracker(Week, { title: 'WeekByDate' })} exact />
                <Route path="/reports" component={withTracker(Month, { title: 'Month' })} exact />
                {/*<Route path="/month" component={Insight} exact />*/}
                <Route path="/watchers" component={withTracker(Watchers, { title: 'Watchers' })} exact />
                <Route path="/watchers/new" component={withTracker(NewWatcher, { title: 'NewWatcher' })} exact />
                <Route path="/watchers/:id/edit" component={withTracker(EditWatcher, { title: 'EditWatcher' })} exact />
                <Route path="/trackers/new" component={withTracker(NewTracker, { title: 'NewTracker' })} exact />
                <Route path="/wizard" component={withTracker(Wizard, { title: 'Wizard' })} exact />
                <Route path="/loginAs" component={withTracker(LoginAs, { title: 'LoginAs' })} exact />
                <Route path="/" component={withTracker(Week, { title: 'Week' })} exact />
                <Route path="/about" component={withTracker(About, { title:'About'})} exact />
                <Route path="/account" component={withTracker(Account, { title:'Account'})} exact />
                <Route component={withTracker(Unknown, { title: 'Unknown' })} />
              </Switch>)
          } else {
            router = (
                <Switch>
                  <Route path="/" component={withTracker(Wizard, { title: 'Wizard' })} exact />
                  <Route path='/logout' component={withTracker(Logout, { title: 'Logout' })} exact />
                  <Route component={withTracker(Unknown, { title: 'Unknown' })} />
                </Switch>)
          }
        } else {
          router = null
        }
      } else {
        router = (
          <Switch>
            <Route path='/' component={withTracker(Auth, { title: 'Auth' })} exact />
            <Route path="/loginAs" component={withTracker(LoginAs, { title: 'LoginAs' })} exact />
            <Redirect to='/' />
            {/* <Route component={Unknown} /> */}
          </Switch>
        )
      }
    } else {
      router = null
    }

    return (
      <div className="App">
        <Router>
          {router}
        </Router>
      </div>
    );
  }

}

const mapStateToProp = state => {
  return {
    uid: state.auth.uid,
    isAuth: state.auth.uid !== null,
    isAuthLoading: state.auth.isAuthLoading,
    accountProperties: state.auth.accountProperties
  }
}

const mapActionsToProps = dispach => {
  return {
    initSignInUser: () => dispach(actions.authInitSignInUser())
  }
}

export default connect(mapStateToProp, mapActionsToProps)(App);
