import moment from 'moment'
import { cloneDeep } from 'lodash'
import * as actionTypes from '../actions/actionTypes'
import { DATE_FORMAT } from '../../utils/constants'

const initialState = {
  now: moment(),
  moods: { pm: null, am: null },
  dayRates: {
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null
  },
  logEntryValues: {},
  dataLoaded: false
}

const daySetField = (state, action) => {
  return {
    ...state,
    [action.field]: action.value
  }
}

const daySetTrackerValues = (state, action) => {
  let trackers = cloneDeep(state.trackers)
  trackers = trackers.map((tracker) => {
    if (tracker.id === action.trackerId) {
      tracker.entries = cloneDeep(action.values || {})
      return tracker
    } else {
      return tracker
    }
  })
  return {
    ...state,
    trackers: trackers
  }
}

const dayMoodClicked = (state, action) => {
  const moods = {...state.moods}

  moods[action.time] = action.mood

  return {
    ...state,
    moods: moods
  }
}

const dayInitMoodTracker = (state, action) => {
  const { values } = action

  return {
    ...state,
    moods: values[parseInt(state.now.format('e'))]
  }
}

const dayTrackerClicked = (state, action) => {
  const { name, value, type } = action.values
  const trackers = [...state.trackers]

  switch (action.trackerType) {
    case 'checklist':
      if (type) {
        trackers[action.index].entries[name] = {...trackers[action.index].entries[name] , [type]: !value }
      } else {
        trackers[action.index].entries[name] = !value
      }
      break;
    case 'options':
      if (type) {
        trackers[action.index].entries[name] = {...trackers[action.index].entries[name] , [type]: value }
      } else {
        trackers[action.index].entries[name] = value
      }
      break
    case 'numbers':
      if (type) {
        trackers[action.index].entries[name] = {...trackers[action.index].entries[name] , [type]: value }
      } else {
        trackers[action.index].entries[name] = value
      }
      break
    default:
  }

  return {
    ...state,
    trackers:trackers
  }
}

const dayChangeLogEntry = (state, action) => {
  return {
    ...state,
    logEntryValues: {
      ...state.logEntryValues,
      [action.date.format(DATE_FORMAT)]: action.value
    }
  }
}

const dayLogEntrySet = (state, action) => {
  return {
    ...state,
    logEntryValues: action.entries
  }
}

const dayMoveForward = (state) => {
  return {
    ...state,
    now: state.now.clone().add(1,'day'),
    dataLoaded: false
  }
}

const dayMoveBackward = (state) => {
  return {
    ...state,
    now: state.now.clone().subtract(1,'day'),
    dataLoaded: false
  }
}

const dayDayRateClicked = (state, action) => {
  const dayRates = {...state.dayRates}
  dayRates[action.day] = action.value

  return {
    ...state,
    dayRates: dayRates
  }
}

const dayLoadDayRates = (state, action) => {
  return {
    ...state,
    dayRates: action.values
  }
}

const dayUpdateTrackerName = (state, action) => {
  const newTrackers = state.trackers.map( tracker => {
    if (tracker.id === action.trackerId) {
      return {...tracker, trackerName: action.trackerName, category: action.category}
    } else {
      return tracker
    }
  })

  return {
    ...state,
    trackers: newTrackers
  }
}

const dayRemoveTracker = (state, action) => {
  const newTrackers = state.trackers.filter((item) => item.id !== action.trackerId )
  return {
    ...state,
    trackers: newTrackers
  }
}

const dayOnBackToToday = (state) => {
  return {
    ...state,
    now: moment(),
    dataLoaded: false
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DAY_SET_FIELD: return daySetField(state, action)
    case actionTypes.DAY_SET_TRACKER_VALUES: return daySetTrackerValues(state, action)
    case actionTypes.DAY_MOOD_CLICKED: return dayMoodClicked(state, action)
    case actionTypes.DAY_INIT_MOOD_TRACKER: return dayInitMoodTracker(state, action)
    case actionTypes.DAY_TRACKER_CLICKED: return dayTrackerClicked(state, action)
    case actionTypes.DAY_CHANGE_LOG_ENTRY: return dayChangeLogEntry(state, action)
    case actionTypes.DAY_LOG_ENTRY_SET: return dayLogEntrySet(state, action)
    case actionTypes.DAY_MOVE_FORWARD: return dayMoveForward(state, action)
    case actionTypes.DAY_MOVE_BACKWARD: return dayMoveBackward(state, action)
    case actionTypes.DAY_DAY_RATE_CLICKED: return dayDayRateClicked(state, action)
    case actionTypes.DAY_LOAD_DAY_RATES: return dayLoadDayRates(state, action)
    case actionTypes.DAY_UPDATE_TRACKER_NAME: return dayUpdateTrackerName(state, action)
    case actionTypes.DAY_REMOVE_TRACKER: return dayRemoveTracker(state, action)
    case actionTypes.DAY_ON_BACK_TO_TODAY: return dayOnBackToToday(state, action)
    default: return state
  }
}

export default reducer
