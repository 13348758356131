import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Form, Modal, Spin, Input, Row, Col } from 'antd'
import cx from 'classnames'
import { groupBy, map, isEmpty, size, forEach, filter } from 'lodash'

import { Box, Alert } from '../UI'
import { getRecommendedTrackers, getRecommendedWatchers } from './utils'
import './AnswersRecomendations.scss'

const MAX_TRACKERS = 5

// const getButtonLabel = (watchers) => {
//   if (isEmpty(watchers)) {
//     return "Create trackers"
//   } else {
//     return "Create trackers and watchers"
//   }
// }

const TrackersList = (props) => {
  const { trackers } = props

  if (isEmpty(trackers)) {
    return (
      <div>
        <Alert message="Based on your answers we can't recommend any trackers. You can start an empty template and start adding trackers yourself." />

          <div className="AnswersRecomendations-Buttons">
            <Button type='danger' shape='round' onClick={props.onStartOver}>Start over</Button>
            <Button type='primary' shape='round' >Create blank template</Button>
          </div>
      </div>
    )
  }

  const trackersGrouped = groupBy(trackers, t => t.category)
  return (
      <div className="AnswersRecomendations-Form">
        <Alert message="Based on your answeres we recommend the following trackers" />
        <div style={{color:'red'}}>Red means things that you want to reduce</div>
        <div style={{color:'green'}}>Green means things that you want to improve</div>

        {size(trackers) > MAX_TRACKERS && (
          <p className="AnswersRecomendations-ManyTrackers">It looks like you have a lot of trackers, we recommend you select the {MAX_TRACKERS} trackers you identifed the most.</p>
        )}
      <Form layout='vertical' initialValues={ {layout: 'vertical', trackers: {} }} onFinish={props.onCreate}>
          <h2>Trackers</h2>
          <Form.Item name="checkbox-group" initialValue={trackers.map( t => t.trackerName )}>
            <Checkbox.Group>
            {
              map(trackersGrouped, (categoryTrackers, category ) => {
                return(
                <div className="AnswerRecomendations-Group" key={category}>
                   <label className="AnswerRecomendations-Group-Label">{category}</label>
                    <ul>
                      {categoryTrackers.map(categoryTracker => {
                        return (
                        <li key={categoryTracker.trackerName} className={cx({'AnswerRecomendations-Tracker-Red':categoryTracker.theLessTheBest,'AnswerRecomendations-Tracker-Green':!categoryTracker.theLessTheBest})}>
                          <Checkbox value={categoryTracker.trackerName} >
                            <div className="SwitchName AnswerRecomendations-Switch">{categoryTracker.trackerName}</div>
                          </Checkbox>
                        </li>)
                      })}
                    </ul>
                </div>
                )
              })
            }
          </Checkbox.Group>
        </Form.Item>

        {/*!isEmpty(watchers) && (
          <>
            <h2>Watchers</h2>
            <Form.Item name="checkbox-group2" initialValue={watchers}>
              <Checkbox.Group>
              <div className="AnswerRecomendations-Group">
              <ul>
                {watchers.map( w => {
                  return (<li key={w}>
                    <Checkbox value={w} >
                      <div className="NewWatcher-Form-SwitchName">{w}</div>
                    </Checkbox>
                  </li>)
                })}
              </ul>
              </div>
              </Checkbox.Group>
            </Form.Item>
          </>)*/}
        <div className="AnswersRecomendations-Buttons">
          <Button type='danger' shape='round' onClick={props.onStartOver}>Start over</Button>
          <Button htmlType="submit" type='primary' shape='round' >Continue</Button>
        </div>
      </Form>
    </div>)
}


const ConfigureTrackers = (props) => {
  const { trackers } = props

  return (
    <div className="AnswersRecomendations-Form">
      <Alert message="These are the default values for your trackers. You can change the default values here." type="warning"/>
      <Form layout='horizontal' initialValues={ {layout: 'vertical', trackers: {} }} onFinish={(values) => props.onCreate(values, trackers)}>
        {map(trackers, (tracker, idx) => {
            return (
              <>
                <div><h2>{tracker.trackerName}</h2></div>
                <Row>
                  <Col>
                    <Form.Item name={['trackers',idx,'units']} label="Units" initialValue={tracker.units}>
                      <Input defaultValue={tracker.units}/>
                    </Form.Item>
                    {tracker.listType == 'numbers' && (
                    <>
                      <Form.Item name={['trackers',idx,'maxNumber']} label="Max Number" initialValue={tracker.maxNumber}>
                        <Input defaultValue={tracker.maxNumber}/>
                      </Form.Item>
                      <Form.Item name={['trackers',idx,'minNumber']} label="Min Number" initialValue={tracker.minNumber}>
                        <Input defaultValue={tracker.maxNumber}/>
                      </Form.Item>
                      <Form.Item name={['trackers',idx,'steps']} label="Increments" initialValue={tracker.steps}>
                        <Input defaultValue={tracker.steps}/>
                      </Form.Item>
                    </>
                    )}
                    <Form.Item name={['trackers',idx,'timesAWeek']} label="Times a Week" initialValue={tracker.timesAWeek}>
                      <Input defaultValue={tracker.timesAWeek}/>
                    </Form.Item>
                  </Col>
                </Row>
            </>
            )
        })}

        <div className="AnswersRecomendations-Buttons">
          <Button htmlType="submit" type="primary" shape="round">Update</Button>
        </div>
      </Form>
    </div>
  )
}

const WatcherList = (props) => {
  const [ accetpWatchers, setAccetpWatchers ] = useState(null)
  const { watchers } = props
  const isWatchersEmpty = isEmpty(watchers)

  const handleOnFinish = (values) => {
    if (accetpWatchers) {
      props.onCreate(values)
    }
  }

  useEffect(() => {
    if (!isWatchersEmpty) {
      Modal.info({
        title: "Watchers detected",
        content: `We detected that the watcher(s) ${watchers.join(', ')}  matched your trackers. This is not a mental health diagnosis, but adding the watcher can help you group tracker and get more insight. Feel free to check or uncheck the watcher in the following screen`,
        onOk: () => { setAccetpWatchers(true) },
      })
    } else {
      props.onCreate({})
    }
  },[isWatchersEmpty])

  if (isWatchersEmpty) {
    return (
      <div className='AnswersRecomendations-Spin'>
        <Spin />
      </div>
    )
  } else {
    return (
      <div className="AnswersRecomendations-Form">
      <Form layout='vertical' initialValues={ {layout: 'vertical', trackers: {} }} onFinish={handleOnFinish}>
        <h2>Watchers</h2>
        <Form.Item name="checkbox-group2" initialValue={watchers}>
          <Checkbox.Group>
          <div className="AnswerRecomendations-Group">
          <ul>
            {watchers.map( w => {
              return (<li key={w}>
                <Checkbox value={w} >
                  <div className="NewWatcher-Form-SwitchName">{w}</div>
                </Checkbox>
              </li>)
            })}
          </ul>
          </div>
          </Checkbox.Group>
        </Form.Item>
        <div className="AnswersRecomendations-Buttons">
          <Button type='danger' shape='round' onClick={props.onStartOver}>Start over</Button>
          <Button shape="round" onClick={props.onBack}>Back</Button>
          <Button htmlType="submit" type='primary' shape='round' >Create template</Button>
        </div>
      </Form>
    </div>
    )
  }
}

const filterTrackers = (trackers, names) => {
  return filter(trackers, (tracker) => {
    return names.includes(tracker.trackerName)
  })
}

const AnswersRecomendations = (props) => {
  const [trackerValues, setTrackerValues ] = useState(null)
  const [trackerConfiguration, setTrackerConfiguration ] = useState(null)
  const [step, setStep ] = useState(0)

  const { answers } = props
  const recommenedTrackers = getRecommendedTrackers(answers)

  const handleOnTrackersFinish = (values) => {
    console.log('trackerValues', values)
    setTrackerValues(values)
    setStep(1)
  }

  const handleOnWatchersFinish = (watcherValues) => {
    // console.log('handleOnWatchersFinish',{
    //   ...watcherValues,
    //   ...trackerValues
    // })
    props.onCreate({
      ...watcherValues,
      ...trackerValues
    }, trackerConfiguration)
  }

  const handleOnConfigureTrackersFinish = (values, trackers) => {
    const updatedTrackers = []

    forEach(trackers, (tracker, idx) => {
      const newTrackers = {
        ...tracker,
        ...values.trackers[idx]
      }
      updatedTrackers.push(newTrackers)
    })

    setTrackerConfiguration(updatedTrackers)
    setStep(2)
  }

  return (
    <Box title="Recommended tracking template">
      { step == 0 && (<TrackersList
                    trackers={recommenedTrackers}
                    onStartOver={props.onStartOver}
                    onCreate={handleOnTrackersFinish}
                    />)}
      { step == 1 && (<ConfigureTrackers
                      trackers={filterTrackers(recommenedTrackers, trackerValues['checkbox-group'])}
                      onCreate={handleOnConfigureTrackersFinish}
                      />)}
      { step == 2 && (<WatcherList
                        onBack={() => setStep(0)}
                        onStartOver={props.onStartOver}
                        watchers={getRecommendedWatchers(trackerValues['checkbox-group'])}
                        onCreate={handleOnWatchersFinish}
                      />)}
  </Box>
  )
}

export default AnswersRecomendations
