import moment from 'moment'
import { find } from 'lodash'

import * as actionTypes from './actionTypes'
import { db } from '../../utils/firebase'

const DATE_FORMAT = 'YYYY-MM-DD'

const MOODS_COLORS = ['#01FF70','#2ECC40','#FFDC00','#FF851B','#FF4136']

export const monthMoveForward = () => {
  return {
    type: actionTypes.MONTH_MOVE_FORWARD
  }
}

export const monthMoveBackward = () => {
  return {
    type: actionTypes.MONTH_MOVE_BACKWARD
  }
}

export const generateMonths = (year) => {
  return {
    type: actionTypes.GENERATE_MONTHS,
    year: year
  }
}

export const monthSetNow = (date) => {
  return {
    type: actionTypes.MONTH_SET_NOW,
    date: date
  }
}

export const monthSetTrackersData = (data) => {
  return {
    type: actionTypes.MONTH_SET_TRACKERS_DATA,
    data: data
  }
}

export const monthAddTracker = (tracker) => {
  return {
    type: actionTypes.MONTH_ADD_TRACKER,
    tracker: tracker
  }
}

export const fetchMonthsTrackersData = (uid, selector, year) => {
  return dispach => {
    if (selector === 'moodTracker') {
      db.collection(`accounts/${uid}/moodTrackers`)
      .where('year', '==', parseInt(year))
      .get()
      .then(function(querySnapshot) {
          const data = {}
          querySnapshot.forEach(function(doc) {
              const d = doc.data()
              data[moment(doc.id).format(DATE_FORMAT)] = { pm: MOODS_COLORS[d['pm']-1] , am: MOODS_COLORS[d['am']-1] }
          });
          dispach(monthSetTrackersData(data))
      })
    } else if (selector === 'dayRates') {
      db.collection(`accounts/${uid}/dayRates`)
      .where('year', '==', parseInt(year))
      .get()
      .then(function(snap) {
        const data = {}
        snap.forEach(doc => {
          data[moment(doc.id).format(DATE_FORMAT)] = doc.data().value
        })
        dispach(monthSetTrackersData(data))
      })
    } else {
      db.collection(`accounts/${uid}/trackers/${selector}/values`)
      .where('year', '==', parseInt(year))
      .get()
      .then(function(querySnapshot) {
          db.collection(`accounts/${uid}/trackers`)
          .doc(selector)
          .get()
          .then(trackerDoc => {
            const trackerData = trackerDoc.data()
            const { type } = trackerData
            const { amPm } = trackerData.options
            const data = {}
            querySnapshot.forEach(function(valueDoc) {
              const valueData = valueDoc.data()
              const docId = moment(valueDoc.id).format(DATE_FORMAT)
              switch (type) {
                case 'checklist':
                  if (amPm) {
                    data[docId] = { pm: valueData.value['PM'] ? 'green' : null , am: valueData.value['AM'] ? 'green' : null }
                  } else {
                    data[docId] = !!valueData.value ? 'bg-green' : ''
                  }
                  break;
                case 'options':
                  const { selections } = trackerData.options

                  if (amPm) {
                    const selectionAm = find(selections, s => s.label === valueData.value['AM'])
                    const selectionPm = find(selections, s => s.label === valueData.value['PM'])
                    data[docId] = { pm: valueData.value['PM'] ? selectionPm.color : null , am: valueData.value['AM'] ? selectionAm.color : null }
                  } else {
                    const selection = find(selections, s => s.label === valueData.value)
                    data[docId] = selection.color
                  }

                break;
                case 'numbers':
                  if (amPm) {
                    data[docId] = { pm: valueData.value['PM'], am: valueData.value['AM'], min: trackerData.options.minNumber, max: trackerData.options.maxNumber }
                  } else {
                    data[docId] =  { value: valueData.value, min: trackerData.options.minNumber, max: trackerData.options.maxNumber }
                  }
                  break;
                default:
              }
            });

            window.gtag('event','report_select', {'tracker_name': selector, 'year': year })
            dispach(monthSetTrackersData(data))
          })
      })
    }
  }
}

export const monthLoadTrackers = (value) => {
  return {
    type: actionTypes.MONTH_LOAD_TRACKERS,
    value: value
  }
}

export const monthSetSelector = (value) => {
  return {
    type: actionTypes.MONTH_SET_SELECTORL,
    selector: value
  }
}

export const monthSetSelectorOptions = (options) => {
  return {
    type: actionTypes.MONTH_SET_SELECTOR_OPTIONS,
    options: options
  }
}

export const monthInitSelectorOptions = (uid) => {
  return dispach => {
    db.collection(`accounts/${uid}/trackers`)
    .get()
    .then(function(querySnapshot) {
        const list = []
        querySnapshot.forEach(function(doc) {
            const { options } = doc.data()
            dispach(monthAddTracker(
              {
                ...doc.data(),
                id: doc.id
              }))
            list.push({id: doc.id, label: options.trackerName})
        });
        dispach(monthSetSelectorOptions(list))
    })
  }
}

export const monthSetField = (field, value) => {
  return {
    type: actionTypes.MONTH_SET_FIELD,
    field: field,
    value: value
  }
}

export const monthOnUpdateDaySummary = (uid, date) => {
  return dispach => {
    /* getting journal data */
    db.collection(`accounts/${uid}/logs`)
    .doc(date.format(DATE_FORMAT))
    .get()
    .then(doc => {
      dispach(monthSetField('journalSummary', doc.data()))
    })

    /* get mood tracker */
    db.collection(`accounts/${uid}/moodTrackers`)
    .doc(date.format(DATE_FORMAT))
    .get()
    .then(doc => {
      dispach(monthSetField('moodTrackerSummary', doc.data()))
    })

    /* Get trackers summmary */
    db.collection(`accounts/${uid}/trackers`)
    .get()
    .then((snap) => {
      const p = []
      snap.forEach((trackerDoc) => {
        const r = db.collection(`accounts/${uid}/trackers/${trackerDoc.id}/values`)
          .doc(date.format(DATE_FORMAT))
          .get()
          .then(valueDoc => {
            let returnData = null
            const trackerData = trackerDoc.data()
            const valueData = valueDoc.data()
            switch (trackerData.type) {
              case 'checklist':
                returnData = { tracker: trackerData, value: valueData }
                break;
              case 'options':
                returnData = { tracker: trackerData, value: valueData }
                break;
              case 'numbers':
                returnData = { tracker: trackerData, value: valueData }
                break;
              default:

            }
            return returnData
          })
        p.push(r)
      })

      Promise.all(p).then(results => {
        dispach(monthSetField('trackersSummary', results))
      })
    })
  }
}


export const monthOnBackToToday = () => {
  return {
    type: actionTypes.MONTH_ON_BACK_TO_TODAY
  }
}

export const monthSetCompareTrackerData = (value, data) => {
  return {
    type: actionTypes.MONTH_SET_COMPARE_TRACKER,
    value:value,
    data: data
  }
}

export const fetchMontOnCompareTrackerData = (uid, selector, year) => {
  return dispach => {
    if (selector === null) {
      dispach(monthSetCompareTrackerData(null, null))
    } else {
      db.collection(`accounts/${uid}/trackers/${selector}/values`)
      .where('year', '==', parseInt(year))
      .get()
      .then(function(querySnapshot) {
          db.collection(`accounts/${uid}/trackers`)
          .doc(selector)
          .get()
          .then(trackerDoc => {
            const trackerData = trackerDoc.data()
            const { type } = trackerData
            const { amPm } = trackerData.options
            const data = {}
            querySnapshot.forEach(function(valueDoc) {
              const valueData = valueDoc.data()
              const docId = moment(valueDoc.id).format(DATE_FORMAT)
              switch (type) {
                case 'numbers':
                  if (amPm) {
                    data[docId] = { pm: valueData.value['PM'], am: valueData.value['AM'], min: trackerData.options.minNumber, max: trackerData.options.maxNumber }
                  } else {
                    data[docId] =  { value: valueData.value, min: trackerData.options.minNumber, max: trackerData.options.maxNumber }
                  }
                  break;
                default:
              }
            });
            dispach(monthSetCompareTrackerData(selector, data))
          })
        })
      }
    }
  }
