import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'

import './Auth.scss'
// import './bootstrap.min.css'
import * as actions from '../../store/actions'
import HeaderFullImg from './cabecera.png'
// import CellphoneImg from './cellphone.png'
import Icon1 from './i1.png'
import Icon2 from './i2.png'
import Icon3 from './i3.png'
import HrImg from './hr.png'
import FooterImg from './pie.png'

import { Box } from '../../components/UI'

const Boxes = () => {
  return (
    <div style={{marginTop:55}}>
      <div className="Auth-ff how_it_works">How it works?</div>
      <div className="Auth-ff">
        <div className="Auth-SubTitle">Trackers</div>
        <div className="text-left">Here you will input your daily info from drop-down options. We will suggest some initial trackers based on a few questions. You can delete, customize and create your own trackers at any point. </div>

        <div className="Auth-SubTitle">Watchers</div>
        <div className="text-left">Select a group of trackers to monitor over a period of time and receive an average score on your progress.</div>
      </div>
    </div>
  )
}

const HeaderFull = () => (
  <div>
      <img
        src={HeaderFullImg}
        alt="Hero_image"
        className="hero_image"
      />
      <div id="join_tracker_buddy_text">
        <span className="raleway_light join_text">join</span>
        <span className="raleway_bold tracker_text"> TRACKER</span>
        <span className="raleway_bold buddy_text">BUDDY</span>
      <div className="top_horizontal_line"></div>
      </div>
    </div>
)

const Main = () => (
  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
    <div className="left_middle_section_div">
      <div className="sign_in">
        <div id="firebaseui-auth-container"></div>
      </div>
      <p className="raleway_medium track_your_behaviour" style={{fontSize: '40px'}}>
        Track your behavior and find your triggers
      </p>
      <p className="raleway_light middle_section_text">
        Free and easy to use app that helps you track your activities,
        behaviors and mental health wellbeing.
      </p>
      <p className="raleway_light middle_section_text">
        Use it to identify your triggers, and set goals to improve and take
        control of your mental and physical health.
      </p>
      <div className='alert-danger'>We will never share your data or email</div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '40px'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <span><img className="icons" src={Icon1} alt="icon" /></span>
          <span className="icons_text">FREE</span>
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <span><img className="icons" src={Icon2} alt="icon" /></span>
          <span className="icons_text">CONVENIENT</span>
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <span><img className="icons" src={Icon3} alt="icon" /></span>
          <span className="icons_text">EASY</span>
        </div>
      </div>
      <Boxes />
    </div>

    {/*<div classname="cellphone">
      <img src={CellphoneImg} alt="cellphone_image" id="cellphone_image" />
    </div>*/}
  </div>
)

const HorizontalLine = () => (
  <div id="horizontal_line">
    <img
      src={HrImg}
      alt="horizontal_line"
      style={{width: "80vw"}}
    />
  </div>
)

const Footer = () => (
  <footer>
    <div className="footer">
      <div className="tracker_buddy_copyright" style={{display: 'flex', flexDirection: 'column', zIndex:1}}>
        <span className="tracker_buddy tracker">TRACKER</span>
        <span className="tracker_buddy">BUDDY</span>
        <span className="raleway_thin"> Copyright © 2021</span>
      </div>
    {/*
      <div className="raleway_thin" style={{display: 'flex', flexDirection: 'column'}}>
        <span className="raleway_regular" style={{fontWeight: 700, zIndex:1}}>Redes sociales</span>
        <a href="#">Twitter</a>
        <a href="#">Facebook</a>
        <a href="#">Instagram</a>
        <a href="#">YouTube</a>
      </div>
      <div className="raleway_thin" style={{display: 'flex', flexDirection: 'column'}}>
        <span className="raleway_regular" style={{fontWeight: 700, zIndex:1}}>Sobre nosotros</span>
        <a href="#">Cómo trabajamos</a>
        <a href="#">Términos y condiciones</a>
        <a href="#">Cómo cuidamos tu privacidad</a>
        <a href="#">Ayuda</a>
      </div>
    */}
    </div>
    <div>
      <img className="footer_image" src={FooterImg} alt="footer_image" />
    </div>
  </footer>
)

class Auth extends React.Component {

  ui: null

  componentDidMount() {
    const uiConfig = {
        signInSuccessUrl: '/',
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          window.firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          window.firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: '/terms.html',
        // Privacy policy url/callback.
        privacyPolicyUrl: function() {
          window.location.assign('/privacy.html');
        },
        callbacks: {
          signInSuccessWithAuthResult: (result) => {
            this.props.onSignInUser(result)
            this.props.history.push('/')
          }
        }
      };

      // Initialize the FirebaseUI Widget using Firebase.
      this.ui = new window.firebaseui.auth.AuthUI(window.firebase.auth());
      // The start method will wait until the DOM is loaded.
      this.ui.start('#firebaseui-auth-container', uiConfig);
  }

  componentWillUnmount() {
    this.ui && this.ui.delete()
  }

  render () {
    return (
      <div className="Auth">
        <div className="Auth-Container">
          <HeaderFull />
          <Main />
          <Footer/>
        </div>
      </div>
      )
  }
}

const mapStateToProp = state => {
  return {
    isAuth: state.auth.uid !== null
  }
}

const mapActionsToProps = dispach => {
  return {
    onSignInUser: (authResult) => dispach(actions.authSignInUserInit(authResult))
  }
}

export default connect(mapStateToProp,mapActionsToProps)(withRouter(Auth));
