import moment from 'moment'

import * as actionTypes from '../actions/actionTypes'

const initialState = {
  now: moment(),
  data: { },
  dataLoaded: false
}

const inisghtMoveForward = (state) => {
  const n = state.now.clone().add('month',1)
  return {
    ...state,
    now: n,
    dataLoaded: false

  }
}

const insightMoveBackward = (state) => {
  const n = state.now.clone().subtract('month',1)
  return {
    ...state,
    now: n,
    dataLoaded: false
  }
}

const insightDataSelected = (state, action) => {
  return {
    ...state,
    now: action.date,
    dataLoaded: false
  }
}

const insightSetData = (state, action) => {
  return {
    ...state,
    weeks: action.weeks,
    weeksMeta: action.weeksMeta,
    dataLoaded: true
  }
}

const insightUnloadData = (state) => {
  return {
    ...state,
    dataLoaded: false
  }
}

const insightOnBackToToday = (state) => {
  return {
    ...state,
    now: moment(),
    dataLoaded: false
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.INSIGHT_MOVE_FORWARD: return inisghtMoveForward(state, action)
  case actionTypes.INSIGHT_MOVE_BACKWARD: return insightMoveBackward(state, action)
  case actionTypes.INSIGHT_DATE_SELECTED: return insightDataSelected(state, action)
  case actionTypes.INSIGHT_SET_DATA:      return insightSetData(state, action)
  case actionTypes.INSIGHT_UNLOAD_DATA:   return insightUnloadData(state, action)
  case actionTypes.INSIGHT_ON_BACK_TO_TODAY: return insightOnBackToToday(state, action)
  default: return state
  }
}

export default reducer
