import * as actionTypes from '../actions/actionTypes'
import { isEmpty, isUndefined, range, sum, remove , clone, map, sortBy, last } from 'lodash'
import questionnaires from '../../data/questions'

const initialState = {
  current: 0,
  questions: questionnaires,
  answers: {},
  questionnairePosition:{}
}

const getNextPosition = (questions, current, answers, position) => {
  const nextQuestion = questions.questions[current + position]
  if (isUndefined(nextQuestion)) {
    return position
  }
  if (isUndefined(nextQuestion.depends) && isUndefined(nextQuestion.excludes)) {
    return position
  }

  const answer = answers.find(a => {
    let includeDepends =  false
    let includeExclude = false
    if (nextQuestion.depends) {
      includeDepends = nextQuestion.depends.includes(a.question)
    }

    if (nextQuestion.excludes) {
      includeExclude = nextQuestion.excludes.includes(a.question)
    }

    return (includeDepends || includeExclude)
  })

  if (nextQuestion.excludes && answer && nextQuestion.excludes.includes(answer.question) && (answer.answer !== false && answer.answer !== null )) {
    return getNextPosition(questions, current, answers, position + 1)
  } else if (isUndefined(answer)) {
    return position
  } else if (answer.answer == null) {
    return position
    // return getNextPosition(questions, current, answers, position + 1)
  } else if(answer.answer) {
    return position
  } else if (isEmpty(answer.answer)) {
    return getNextPosition(questions, current, answers, position + 1)
  } else {
    return position
  }
}

const wizardSetQuestion = (state, action) => {
  const answer = { position: state.current, question: action.question, answer: action.answer }

  let newAnswers = null
  if (state.answers[action.title]) {
    newAnswers = clone(state.answers[action.title])
    remove(newAnswers, ({ question }) => answer.question === question)
    newAnswers = newAnswers.concat(answer)
  } else {
    state.answers[action.title] = []
    newAnswers = state.answers[action.title].concat(answer)
  }

  const newPosition = getNextPosition(state.questions[action.idx], (state.questionnairePosition[action.title] || 0), newAnswers, 1)
  const newCurrent = state.current + newPosition

  let pos = null
  if (state.questionnairePosition[action.title]) {
    pos = state.questionnairePosition[action.title] + newPosition
  } else {
    pos =  newPosition
  }

  return {
    ...state,
    answers: {
      ...state.answers,
      [action.title]:newAnswers
    },
    current: newCurrent,
    questionnairePosition:{
      ...state.questionnairePosition,
      [action.title]: pos
    }
  }
}

const wizardInit = (state) => {
  return {
    ...state,
    answers:{},
    current: 0,
    questionnairePosition: {}
  }
}

const wizardOnBack = (state, action) => {
  const newCurrent = state.current - 1
  const lastPosition = last(sortBy(map(state.answers[action.title], ({ position }) => position ), n => n))

  return {
    ...state,
    current: newCurrent,
    questionnairePosition:{
      ...state.questionnairePosition,
      [action.title]:lastPosition
    }
  }
}

const wizardStartOverQuestionairee = (state, action) => {
  const r = action.idx
  const l = range(r)
  const m  = l.map(idx => state.questions[idx].questions.length)
  const newCurrent = r < 0 ? 0 : sum(m)

  return {
    ...state,
    current: newCurrent,
    questionnairePosition: {
      ...state.questionnairePosition,
      [action.questionnaire.title]: 0
    }
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.WIZARD_SET_QUESTION: return wizardSetQuestion(state, action)
    case actionTypes.WIZARD_INIT: return wizardInit(state, action)
    case actionTypes.WIZARD_ON_BACK: return wizardOnBack(state, action)
    case actionTypes.WIZARD_START_OVER_QUESTIONNARE: return wizardStartOverQuestionairee(state, action)
    default: return state;
  }
}

export default reducer
