import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, Spin } from 'antd'
import { Link } from 'react-router-dom'

import Layout from '../Layout/Layout'
import WatchersList from '../../components/WatchersList/WatchersList'
import { Alert } from '../../components/UI'
import * as actions from '../../store/actions'
import { MAX_NUMBER_OF_WATCHERS } from '../../models/watchers'


class Watchers extends React.Component {

  componentDidMount() {
    this.props.loadWatchers(this.props.uid)
  }

  render() {
    const {
      uid,
      watchersResults,
      watcherLoading,
      history,
      onWatcherDelete
    } = this.props

    const isMax = watcherLoading ? false : (watchersResults.length >= MAX_NUMBER_OF_WATCHERS ? false : true)

    return (
      <Layout>
        <div className="Container">
          <Row justify="center" style={{marginTop:10}}>
            <Col xl={14} lg={14} md={24} xs={24} sm={24}>
              <h1>Watchers</h1>
              {
                watcherLoading ?
                <div style={{marginTop:50, pdding:50}}><Spin /></div>
                :
                (
                  <>
                  {isMax ?
                    (<Button type="primary" shape="round" className='tour-add-watcher'>
                      <Link to="/watchers/new">Add watcher</Link>
                    </Button>)
                    :
                    (<Alert message={`You reach the max number (${MAX_NUMBER_OF_WATCHERS}) of watcher you can have`} />)
                  }
                  <WatchersList items={watchersResults} history={history} onWatcherDelete={(watcherId) => onWatcherDelete(uid, watcherId)}/>
                  </>
                )
              }
            </Col>
          </Row>
        </div>
      </Layout>
    )
  }
}


const mapStateToProp = state => {
  return {
    uid: state.auth.uid,
    watchersResults: state.watchers.watcherResultsSecond,
    watchersList: state.watchers.watchersList,
    watcherLoading: state.watchers.watcherLoading
  }
}

const mapActionsToProps = dispach => {
  return {
    loadWatchers: (uid) => dispach(actions.watchersLoadWatchers(uid)),
    onWatcherDelete: (uid, watcherId) => dispach(actions.watchersDeleteWatcher(uid, watcherId)),
  }
}

export default connect(mapStateToProp, mapActionsToProps)(Watchers);
