import React from 'react'
import { pickBy, capitalize, isEmpty } from 'lodash'
import moment from 'moment'
import { Select } from 'antd';

import MonthChart from './MonthChart/MonthChart'
import { Box, Alert } from '../UI'

import './TracksChart.css'

const { Option } = Select;

const filterData = (date, trackersData) => {
  const entries = pickBy(trackersData, (value, key) => {
    const keyDate = moment(key)

    return date.format('YYYY-MM') === keyDate.format('YYYY-MM')
  })

  return entries
}

const TracksCharts = (props) => {
  return (
    <div>
      <div className="MonthToolbar-Selector">
        <div>Compare with</div>
        <div>
          <Select style={{ width: 200 }} defaultValue={props.compareTrackerName}  onChange={props.onCompareChanged} allowClear onClear={() => props.onCompareChanged(null)}>
            {props.options && props.options.map(opts => {
              return <Option key={opts.id} value={opts.id}>{capitalize(opts.label)}</Option>
            })}
          </Select>
        </div>
      </div>

      {props.compareTracker && isEmpty(props.compareTrackerData) && (<Alert style={{marginTop:10}} message="There is no data for the compare tracker selected" type="info" />)}

      {/*<div className="Chart-Legend">*/}
        {/*props.trackSelected && (<span style={{backgroundColor:PRIMARY_COLOR_BG, color:'black', border:`1px solid ${PRIMARY_COLOR_BORDER}`}}>{props.trackSelected.options.trackerName}</span>)*/}
        {/*props.compareTracker && (<span style={{backgroundColor:SECONDARY_COLOR_BG, color:'black', border:`1px solid ${SECONDARY_COLOR_BORDER}`}}>{props.compareTracker.options.trackerName}</span>) */}
      {/*</div>*/}

    {props.months.map( date => {
      const momentDate = moment(date)
      const data = filterData(momentDate, props.trackersData)
      const compareData = filterData(momentDate, props.compareTrackerData)
      const { trackSelected } = props

      if (Object.keys(data).length > 0) {
        return (
          <Box>
            <MonthChart
              onChartClick={props.onChartClick}
              date={momentDate}
              trackSelected={props.trackSelected}
              trackersData={data}
              compareTrackerName={props.compareTrackerName}
              compareTrackerData={compareData}
              compareTracker={props.compareTracker}
            />
          </Box>)
      } else{
        return null
      }
    })}

    </div>
  )
}

export default TracksCharts
