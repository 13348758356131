import { map, compact, size } from 'lodash'
import moment from 'moment'
import { DATE_FORMAT } from './constants'

export const countMissingDays  = (trackers) => {
  const today = moment().format(DATE_FORMAT)
  const completed = size(
          compact(
            map(trackers, tracker => {
                return tracker.entries[today]
  })))

  return trackers.length - completed
}

export const isInThisWeek = (now) => {
  // console.log(now.format(DATE_FORMAT),moment().format(DATE_FORMAT))
  return now.startOf('week').format(DATE_FORMAT) === moment().startOf('week').format(DATE_FORMAT)
}
