import React from 'react'
import { sortBy, toLower} from 'lodash'
import cx from 'classnames'

import { Spin } from 'antd'
import { Tracker } from '../UI'

import './Trackers.css'

const Trackers = ({
  trackers,
  loading,
  onTrackerDelete,
  onEditTrackName,
  date,
  onTrackerClicked,
  categories,
  onLoadCategories,
  format,
  streaks,
  watchers,
  onOpenFeedback,
  trackerJournals,
  trackerJournalsToday,
  onViewJournalOpen
}) => {
  let content = null

  if (loading) {
    content = <Spin/>
  } else {
    const trackersSorted = sortBy(trackers, t => toLower(t.trackerName))

    content = trackersSorted.map((config, index)=>{
      return (
        <div key={config.id} className={cx("Trackers-Tracker",{'this-is-a-tracker': index == 0})}>
          <Tracker
              tour={index == 0}
              streak={streaks ? streaks[config.id] :  null}
              format={format}
              onTrackerDelete={onTrackerDelete}
              onEditTrackName={onEditTrackName}
              type={config.type}
              configuration={{ ...config, onClick:(trackerType, value) => {
                onTrackerClicked(trackerType, value, config.index, config.id)
              }}}
              date={date}
              categories={categories}
              onLoadCategories={onLoadCategories}
              watchers={watchers}
              onOpenFeedback={onOpenFeedback}
              journalToday={trackerJournalsToday ? trackerJournalsToday[config.id] : null}
              journals={trackerJournals ? trackerJournals[config.id] : null}
              onViewJournalOpen={() => onViewJournalOpen(config.id)}
              />
        </div>)
    })
  }

  return (
    <div className="Trackers-List">
      {content}

      {/*
        <div style={{height:30, marginTop:25}}>
      <Button style={{float:'right'}} type='primary' shape='round'>
        <Link to="/trackers/new">Add new tracker</Link>
      </Button>
      </div>*/}
    </div>
  )
}

export default Trackers
