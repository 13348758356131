import React from 'react'

import { ScrollableDate } from '../UI'
import './MonthToolbar.css'

const MonthToolbar = (props) => {
  return (
    <React.Fragment>
      <ScrollableDate
        type='year'
        date={props.date}
        onForward={props.onForward}
        onBackward={props.onBackward}
        onDateSelected={props.onDateSelected}
        onBackToToday={props.onBackToToday}/>
    </React.Fragment>
  )
}

export default MonthToolbar
