import React from 'react'
import { Button, Row, Col } from 'antd'

import './CookiePolicy.scss'

const CookiePolicy = (props) => {
  const { show } = props

  if (show == null) {
    return (
      <div className="CookiePolicy">
        <h1>We use cookies</h1>

        <Row>
          <Col xl={16} lg={16} md={16} xs={24} sm={24}>
            <p>
              We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from.
            </p>
            <a href="/cookies.html" target="_blank">Cookies Policy</a>
          </Col>
          <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <div className="CookiePolicy-Button">
              <Button shape="round" onClick={props.onReject}>Reject</Button>
              <Button type="primary" shape="round" onClick={props.onAccept}>Accept</Button>
            </div>
          </Col>
        </Row>
      </div>
    )
  } else {
    return null
  }
}

export default CookiePolicy
