import React from 'react'

import Calendar from '../Calendar'
// import { getDate, getDayStart, getDayEnd } from '@wojtekmaj/date-utils';

import './TracksCalendars.css'

const TracksCalendars = (props) => {

  return (
    props.months.map((value, index) => {
      return (
        <div className="TrackCalendar" key={value}>
          <Calendar
            nextLabel={null}
            next2Label={null}
            prevLabel={null}
            prev2Label={null}
            className="TrackCalendar-Calendar"
            minDetail="month"
            tileClassName={(value)=> props.onTileClassName(value, props.tracker)}
            showWeekNumbers={false}
            onChange={(value) => props.onChange && props.onChange(value, index)}
            value={value} />
        </div>)
    })
  )
}

export default TracksCalendars
