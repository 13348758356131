import React from 'react'
import { Row, Col, Drawer, Tabs, Select, Tag } from 'antd'
import { getMonth } from '@wojtekmaj/date-utils';
import moment from 'moment'
import { connect } from 'react-redux'
import { isEmpty, capitalize } from 'lodash'

import Layout from '../Layout/Layout'
import { Container, Box, Alert } from '../../components/UI'
import TracksCalendars from '../../components/TracksCalendars/TracksCalendars'
import TracksCharts from '../../components/TracksCharts/TracksCharts'
import DayRateCharts from '../../components/DayRateCharts/DayRateCharts'
import MonthToolbar from '../../components/MonthToolbar/MonthToolbar'
import DaySummary from '../../components/DaySummary/DaySummary'

import './Month.css'

import { colorFor } from '../../utils/utils'

import * as actions from '../../store/actions'

const DATE_FORMAT = 'YYYY-MM-DD'

const { TabPane } = Tabs

class Month extends React.Component {
  state = {
    drawerVisible: false,
    date: null
  }

  calendarChangeHandler = (value, index) => {
    this.setState({ drawerVisible: true, date: value })
    this.props.onUpdateDaySummary(this.props.uid, moment(value))
  }

  drawerCloseHandler = () => {
    this.setState({ drawerVisible: false })
  }

  tileClassNameHandler = ({ activeStartDate, date, view }, tracker) => {
    // console.log('tileClassNameHandler')
    const activeStartDateMonth = getMonth(activeStartDate)
    const dateMonth = getMonth(date)
    const k = moment(date).format(DATE_FORMAT)

    if (activeStartDateMonth === dateMonth) {
      const v = this.props.trackersData[k]

      if (v && (v.pm || v.am)) {
        const colors = { }

        colors.am = colorFor(v.am, v.min, v.max, tracker.options.theLessTheBest)
        colors.pm = colorFor(v.pm, v.min, v.max, tracker.options.theLessTheBest)

        return colors
      } else if (v) {
        return colorFor(v.value, v.min, v.max, tracker.options.theLessTheBest)
      } else {
        return v
      }
    } else {
      return ''
    }
  }

  selectorChangedHandler = (value) => {
    this.props.onSelector(value)
    this.props.onLoadTrackers(this.props.uid, value, this.props.now.format('YYYY'))
  }

  onDateSelectedHandler = (date) => {
    this.props.OnSetNow(date)
    this.props.onGenerateMonths(date.year())
  }

  onTracksChartClickHandler = (date) => {
    this.setState({ drawerVisible: true, date: date })
    this.props.onUpdateDaySummary(this.props.uid, date)
  }

  onDayRatesChartClickHandler = (date) => {
    this.setState({ drawerVisible: true, date: date })
    this.props.onUpdateDaySummary(this.props.uid, date)
  }

  componentDidMount() {
    this.props.onGenerateMonths(this.props.now.year())
    if (!isEmpty(this.props.selector)) {
      this.props.onLoadTrackers(this.props.uid, this.props.selector, this.props.now.format('YYYY'))
    }
    this.props.onInitSelectorOptions(this.props.uid)
  }

  componentDidUpdate() {
    if (this.props.needsUpdate) {
     this.props.onLoadTrackers(this.props.uid, this.props.selector, this.props.now.format('YYYY'))
    }
  }

  handleOnMoveForward = () => {
    this.props.onForward()
  }

  handlerOnMoveBackward = () => {
    this.props.onBackward()
  }

  handleOnBackToToday = () => {
    this.props.onBackToToday()
  }

  handleTabClick = (tab) => {
    window.gtag('event','reports_tab_changed',{ 'tab': tab })
  }

  render () {
    const { renderType, uid, now } = this.props
    return(
      <Layout>
        <div className="Container">
          <MonthToolbar
            onSelectorChanged={this.selectorChangedHandler}
            defaultSelectorValue={this.props.selector}
            date={this.props.now}
            onForward={this.handleOnMoveForward}
            onBackward={this.handlerOnMoveBackward}
            onDateSelected={this.onDateSelectedHandler}
            options={this.props.selectorOptions}
            onBackToToday={this.handleOnBackToToday}
            />
          <Container>
            <Row><Col xl={{ span:12, offset: 6}} lg={{ span:12, offset: 6}} md={24} xs={24} sm={24}>
            <Box title="Select tracker">
              <div className="MonthToolbar-Selector">
                <div>
                  <Select style={{ width: 200 }} defaultValue={this.props.selector} onChange={this.selectorChangedHandler}>
                    {/*<Option value="moodTracker">Mood Tracker</Option>
                    <Option value="dayRates">Day rates</Option>*/}
                    {this.props.selectorOptions && this.props.selectorOptions.map(opts => {
                      return <Option key={opts.id} value={opts.id}>{capitalize(opts.label)}</Option>
                    })}
                  </Select>
                </div>
              </div>
            </Box>
          </Col></Row>
            {!isEmpty(this.props.trackSelected) && (
            isEmpty(this.props.trackersData) ?
            (<Row><Col xl={{ span:12, offset: 6}} lg={{ span:12, offset: 6}} md={24} xs={24} sm={24}><Alert message="There is no data for the tracker selected" type="info" /></Col></Row>)
            :
            (<div className="Month-Tab-Container">
              <Tabs defaultActiveKey="charts" centered onTabClick={this.handleTabClick}>
              <TabPane tab="Charts" key="charts">
                <Row>
                  <Col span={24}>
                    {/*renderType === "calendar" && (
                      <TracksCalendars
                        months={this.props.months}
                        onChange={this.calendarChangeHandler}
                        onTileClassName={this.tileClassNameHandler}
                        />)*/}
                    {renderType === "trackerCharts" && (
                      <Row>
                        <Col xl={{ span:12, offset: 6}} lg={{ span:12, offset: 6}} md={24} xs={24} sm={24}>
                          <TracksCharts
                            options={this.props.selectorOptions}
                            onChartClick={this.onTracksChartClickHandler}
                            months={this.props.months}
                            onChange={this.calendarChangeHandler}
                            trackersData={this.props.trackersData}
                            trackSelected={this.props.trackSelected}
                            onCompareChanged={(value) => this.props.onCompareChanged(uid, value, now.year())}
                            compareTrackerName={this.props.compareTrackerName}
                            compareTrackerData={this.props.compareTrackerData}
                            compareTracker={this.props.compareTracker}
                            />
                        </Col>
                      </Row>
                    )
                      }

                      {/*renderType === "dayRatesCharts" && (
                        <Row>
                          <Col xl={{ span:12, offset: 6}} lg={{ span:12, offset: 6}} md={24} xs={24} sm={24}>
                            <DayRateCharts
                              months={this.props.months}
                              onChange={this.calendarChangeHandler}
                              trackersData={this.props.trackersData}
                              trackSelected={this.props.trackSelected}
                              onChartClick={this.onDayRatesChartClickHandler}
                              />
                          </Col>
                        </Row>
                      )
                        */}
                  </Col>
                </Row>
                <Drawer
                  visible={this.state.drawerVisible}
                  onClose={this.drawerCloseHandler}
                  width="356"
                  >
                  <DaySummary
                    moodTrackerSummary={this.props.moodTrackerSummary}
                    trackersSummary={this.props.trackersSummary}
                    journalSummary={this.props.journalSummary}
                    />
                </Drawer>
              </TabPane>
              <TabPane tab="Calendar" key="calendars">
                <div><Tag>{capitalize(this.props.trackSelected.options.trackerName)} {capitalize(this.props.trackSelected.options.units)}</Tag></div>
                <TracksCalendars
                  months={this.props.months}
                  tracker={this.props.trackSelected}
                  //onChange={this.calendarChangeHandler}
                  onTileClassName={this.tileClassNameHandler}
                  />
              </TabPane>
            </Tabs></div>)
            )}
            </Container>
        </div>
      </Layout>
    )
  }
}

const mapStateToProp = state => {
  return {
    needsUpdate: state.month.needsUpdate,
    now: state.month.now,
    months: state.month.months,
    trackersData: state.month.trackersData,
    selector: state.month.selector,
    selectorOptions: state.month.selectorOptions,
    uid: state.auth.uid,
    moodTrackerSummary: state.month.moodTrackerSummary,
    trackersSummary: state.month.trackersSummary,
    journalSummary: state.month.journalSummary,
    renderType: state.month.renderType,
    trackSelected: state.month.trackSelected,
    compareTrackerName: state.month.compareTrackerName,
    compareTrackerData: state.month.compareTrackerData,
    compareTracker: state.month.compareTracker
  }
}

const mapActionsToProps = dispach => {
  return {
    onForward: () => dispach(actions.monthMoveForward()),
    onBackward: () => dispach(actions.monthMoveBackward()),
    onGenerateMonths: (year) => dispach(actions.generateMonths(year)),
    OnSetNow: (date) => dispach(actions.monthSetNow(date)),
    onLoadTrackers: (uid, selector, year) => dispach(actions.fetchMonthsTrackersData(uid, selector, year)),
    onSelector: (value) => dispach(actions.monthSetSelector(value)),
    onInitSelectorOptions: (uid) => dispach(actions.monthInitSelectorOptions(uid)),
    onFetchTracksData: (uid, selector, year) => dispach(actions.fetchMonthsTrackersData(uid, selector, year)),
    onUpdateDaySummary: (uid, date) => dispach(actions.monthOnUpdateDaySummary(uid, date)),
    onBackToToday: () => dispach(actions.monthOnBackToToday()),
    onCompareChanged: (uid, value, year) => dispach(actions.fetchMontOnCompareTrackerData(uid, value, year))
  }
}

export default connect(mapStateToProp, mapActionsToProps)(Month);
