import React from 'react'

import './DaySummary.css'
import TrackSummary from './TrackSummary/TrackSummary'

const DaySummary = (props) => {
  return (
    <div className="DaySummary">
      <h2 className="DaySummary-Title">Trackers</h2>
      <TrackSummary data={props.trackersSummary} />


      <h2 className="DaySummary-Title">Journal</h2>
      <div className='DaySummary-Journal'>{(props.journalSummary && props.journalSummary.text) || <span className='DaySummary-JournalEmpty'>Empty</span>}</div>
    </div>
  )
}

export default DaySummary
