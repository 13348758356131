import React, { Component } from 'react'
import moment from 'moment'
import { Tooltip } from 'antd';
import MoodSelector from './MoodSelector/MoodSelector'
import classnames from 'classnames'

import { DATE_FORMAT_LONG } from '../../../utils/constants'
import { isCurrentDate } from '../../../utils/dates'

import './MoodEntry.css'

class MoodEntry extends Component {

  render() {
    const { value, week, onMoodClick, moods, date, format} = this.props

    const isFuture = date.diff(moment()) > 0
    const isCurrDate = isCurrentDate(date)


    return (
      <div className={classnames('MoodEntry',{'MoodEntry-IsCurrentDate':isCurrDate,'MoodEntry-Day':format === 'day'})}>
        <label>
          <Tooltip title={date.format(DATE_FORMAT_LONG)}>{this.props.label}</Tooltip>
          </label>
        <div>
          <MoodSelector disabled={isFuture} key='am' value={value && value['am']} week={week} onMoodClick={onMoodClick} text="am" placement="top" moods={moods} date={date}/>
          <MoodSelector disabled={isFuture} key='pm' value={value && value['pm']} week={week} onMoodClick={onMoodClick} text="pm" placement="top" moods={moods} date={date}/>
        </div>
      </div>
    )
  }
}

export default MoodEntry
