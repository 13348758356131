import moment from 'moment'
import { toLower, isUndefined, uniq } from 'lodash'

import { db, deleteCollection } from '../utils/firebase'
import { DATE_FORMAT } from '../utils/constants'

const setMood = (uid, date, time, week, mood) => {
  return db.collection(`accounts/${uid}/moodTrackers`)
          .doc(date.format(DATE_FORMAT))
          .set({
            [time]: mood,
            day: week,
            year: parseInt(date.format('YYYY')),
            month: parseInt(date.format('MM')),
            week: date.week()
          }, { merge: true })
}

const fetchMoods = (uid, year, week) => {
  return db.collection(`accounts/${uid}/moodTrackers`)
    .where('year','==',parseInt(year))
    .where('week','==',week)
    .get()
    .then(function(querySnapshot) {
      const entries = Array(7)
      querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          const { day, am, pm } = doc.data()
          entries[day] = {}
          entries[day]['am'] = am
          entries[day]['pm'] = pm
      })
      return entries
    })
}

const saveLogEntry = (uid, date, value) => {
  const logId = date.format(DATE_FORMAT)
  return db.collection(`accounts/${uid}/logs/`)
          .doc(logId)
          .set({
            text: value,
            year: parseInt(date.format('YYYY')),
            month: parseInt(date.format('DD')),
            week: date.week()
          })
}

const fetchLogEntries = (uid, year, week) => {
  // console.log('fetchLogEntries', parseInt(year),week)
  return db.collection(`accounts/${uid}/logs`)
    .where('year','==',parseInt(year))
    .where('week','==', week)
    .get()
    .then(snap => {
      const entries = {}
      snap.forEach(doc => {
        entries[doc.id] = doc.data().text
      })
      return entries
    })
}

const fetchTrackers = (uid) => {
  return db.collection(`accounts/${uid}/trackers`)
  .get()
  .then(function(querySnapshot) {
    const d = []
    querySnapshot.forEach(function(doc) {
      const data = doc.data()
      d.push({id: doc.id, type: data.type, ...data.options, entries: {}})
    })

    return d
  })
}

const fetchTrackerById = (uid, value) => {
  return db.collection(`accounts/${uid}/trackers`)
  .doc(value)
  .get()
  .then(doc => doc.data())
}

export const fetchTrackerValuesByYearAndWeek = (uid, trackerId, year, week) => {
  const dateAt = moment().week(parseInt(week)).year(parseInt(year)).subtract(30,'days').toDate()
  const dateAtFirebase = window.firebase.firestore.Timestamp.fromDate(dateAt)
  console.log('dateAt', dateAt)

  return db.collection(`accounts/${uid}/trackers/${trackerId}/values`)
  .where("dateAt",">=", dateAtFirebase)
  .get()
  .then(function(querySnapshot) {
    const entries = {}
    querySnapshot.forEach(function(doc) {
        const { value } = doc.data()
        entries[doc.id] = value
    });
    return entries
  })
}

export const fetchTrackerValuesByMonth = (uid, trackerId, year, month) => {
  return db.collection(`accounts/${uid}/trackers/${trackerId}/values`)
  .where('year','==',parseInt(year))
  .where('month','==',month)
  .get()
  .then(function(qs) {
    const entries = []
    qs.forEach(function(doc){
      entries.push({ date: doc.id, value: doc.data().value })
    })
    return entries
  })
}

export const fetchTrackersValues = async (uid, trackers, year, week) => {
  const ret = []
  let promises = []
  for(let idx in trackers) {
    const trackerId = trackers[idx].id
    const p = db.collection(`accounts/${uid}/trackers/${trackerId}/values`)
      .where('year','==',parseInt(year))
      .where('week','==',week)
      .get()
      .then(function(querySnapshot) {
        const entries = {}
        querySnapshot.forEach(function(doc) {
            const { value } = doc.data()
            entries[doc.id] = value
        });
        return entries
      })
      .then( values => {
        ret.push({ tracker: trackers[idx], values: values })
        return ret
      })

    promises.push(p)
  }

  await Promise.all(promises)

  return ret
}

const setTrackerValue = (uid, trackerId, date, saveValue) => {
  const docId = date.format(DATE_FORMAT)
  const dateAt = window.firebase.firestore.Timestamp.fromDate(date.toDate())
  return db.collection(`accounts/${uid}/trackers/${trackerId}/values`)
          .doc(docId)
          .set({
              value: saveValue,
              year: parseInt(date.format('YYYY')),
              month: parseInt(date.format('MM')),
              week: date.week(),
              dateAt: dateAt
            }, { merge: true })

}

const fetchInisghtMoodsTrackerData = (uid, year, month) => {
    return db.collection(`accounts/${uid}/moodTrackers`)
    .where('year', '==', parseInt(year))
    .where('month', '==', parseInt(month))
    .get()
    .then(function(querySnapshot) {
        const data = { weeks: [], weeksMeta:{} }
        querySnapshot.forEach(function(doc) {
            const d = doc.data()
            const entry = {
              date:moment(doc.id).format(DATE_FORMAT),
              values: {
                moodsTracker: { am: d['am'], pm:d['pm']}
              }
             }
             data.weeks.push(entry)
        });

        data.weeksMeta['moodsTracker'] = { label: 'Moods Tracker' }

        return data;
    })
}

const fetchInsightDatRateData = (uid, year, month, data) => {
  return db.collection(`accounts/${uid}/dayRates`)
  .where('year', '==', parseInt(year))
  .where('month', '==', parseInt(month))
  .get()
  .then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
      const d = doc.data()
      const date = moment(doc.id).format(DATE_FORMAT)

      let created = false
      data.weeks = data.weeks.map(e => {
        if (e.date === date) {
          created = true
          return {
            ...e,
            values:{
              ...e.values,
              dayRate: d.value
            }
          }
        } else {
          return e
        }
      })

      if (!created) {
        data.weeks.push({
          date: date,
          values: {
            moodsTracker: { am: null, pm: null },
            dayRate: d.value
          }
        })
      }
    })


    data.weeksMeta['dayRate'] = { label: 'Day Rate' }

    return data
  })
}

const fetchInsightTrackersData = (data, uid, year, month, callback) => {
    db.collection(`accounts/${uid}/trackers`)
      .get()
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              const trackerData = doc.data()
              fetchInsightValuesData(trackerData, data, doc, uid, year, month)
              .then((d) => callback(d))
          });
      })
}

const fetchInsightValuesData = (trackerData, data, doc, uid, year, month) => {
    return db.collection(`accounts/${uid}/trackers/${doc.id}/values`)
    .where('year', '==', parseInt(year))
    .where('month', '==', parseInt(month))
    .get()
    .then(function(querySnapshot2) {
        querySnapshot2.forEach(function(doc2) {
          const valuesData = doc2.data()
          let created = false
          const dateStr = moment(doc2.id).format(DATE_FORMAT)
          data.weeks = data.weeks.map(e => {
            if (e.date === dateStr){
              created = true
              const a = {
                ...e,
                values:{
                  ...e.values,
                  [toLower(trackerData.options.trackerName)]: valuesData.value
                }
              }
              return a
            } else {
              if (isUndefined(e.values[toLower(trackerData.options.trackerName)])) {
                const b = {
                  ...e,
                  values:{
                    ...e.values,
                    [toLower(trackerData.options.trackerName)]: false
                  }
                }
                return b
              } else {
                return e
              }
            }
          })

          if (!created) {
            data.weeks.push({
              date:dateStr,
              values: {
                moodsTracker: { am: null, pm: null },
                [toLower(trackerData.options.trackerName)]: valuesData.value
              }
            })
          }
        })
        data.weeksMeta[toLower(trackerData.options.trackerName)] = { tracker: trackerData, label: trackerData.options.trackerName }
        // data.weeksMeta[toLower(trackerData.options.trackerName)] = trackerData
        return data
    })
}

const buildSaveValueForTrackerType = (trackerType, type, value) => {
  let saveValue = null
  switch (trackerType) {
    case 'checklist':
      if (type) {
        saveValue = { [type]: !value }
      } else {
        saveValue = !value
      }
      break;
    case 'options':
      if (type) {
        saveValue = { [type]: value }
      } else {
        saveValue = value
      }
      break;
    case 'numbers':
      if (type) {
        saveValue = { [type]: value }
      } else {
        saveValue = value
      }
      break;
    default:
  }

  return saveValue
}

const setDayRate = (uid, date, day , value) => {
  return db.collection(`accounts/${uid}/dayRates`).doc(date.format('YYYY-MM-DD')).set({
    day: day,
    value: value,
    year: parseInt(date.format('YYYY')),
    month: parseInt(date.format('MM')),
    week: date.week()
  })
}

const fetchDayRates = (uid, year, week) => {
  return db.collection(`accounts/${uid}/dayRates`)
    .where('year','==',parseInt(year))
    .where('week','==',week)
    .get()
    .then(function(querySnapshot) {
        const entries = {}
        querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            const { day, value } = doc.data()
            entries[day] = value
        });
        return entries
    })
}

const updateTracker = (uid, config, values) => {
  return db.collection(`accounts/${uid}/trackers`).doc(config.id)
    .get()
    .then((doc) => {
      const { options } = doc.data()
      const newOptions = {...options, ...values}

      return db.collection(`accounts/${uid}/trackers`)
        .doc(config.id)
        .update({
          options: newOptions
        }).then(() => {
          return [config, values]
        })
    })

}

const fetchCategories = (uid) => {
  return db.collection(`accounts/${uid}/trackers`)
  .get()
  .then( snaps => {
    let data = []
    snaps.forEach(doc => {
      const { category } = doc.data().options
      if (!isUndefined(category)){
        data.push(category)
      }
    })
    data = uniq(data)
    data = data.map (category => {
      return { value: category, label: category }
    })

    return data
  })
}

const deleteFullTracker = (uid, trackerId) => {
  return deleteCollection(db, `accounts/${uid}/trackers/${trackerId}/values`, 50)
  .then(() => {
    return db.collection(`accounts/${uid}/trackers`)
    .doc(trackerId)
    .delete()
    .then(() => {
      return trackerId
    })
  })
}

const fetchMoodTrackerValuesByMonth = (uid, year, month) => {
  return db.collection(`accounts/${uid}/moodTrackers`)
  .where('year', '==', parseInt(year))
  .where('month', '==', parseInt(month))
  .get()
  .then(function(querySnapshot) {
    const entries = []
    querySnapshot.forEach(doc => {
      const { am, pm } = doc.data()
        entries.push({ date: doc.id, value: { am, pm } })
    })
    return entries
  })
}

const fetchDayRateValuesByMonth = (uid, year, month) => {
  return db.collection(`accounts/${uid}/dayRates`)
  .where('year','==',parseInt(year))
  .where('month', '==', parseInt(month))
  .get()
  .then(function(querySnapshot) {
      const entries = []
      querySnapshot.forEach(function(doc) {
        entries.push({ date: doc.id, value: doc.data().value })
      });
      return entries
  })
}

const fetchWatchers = (uid) => {
  return db.collection(`accounts/${uid}/watchers`)
  .get()
  .then(function(querySnapshot) {
      const entries = []
      querySnapshot.forEach(function(doc) {
        entries.push({ id: doc.id, ...doc.data() })
      });
      return entries
  })
}

const createWatcher = (uid, entry) => {
  return db.collection(`accounts/${uid}/watchers`).doc().set(entry)
}

const deleteWatcher = (uid, watcherId) => {
  return db.collection(`accounts/${uid}/watchers`).doc(watcherId).delete()
}

const setAccountTimestamp = (user) => {
  const timestamp = window.firebase.firestore.Timestamp.fromDate(new Date())
  return db.collection(`accounts`).doc(user.uid).set({
    lastLoggedIn: timestamp,
    displayName: user.displayName
  }, { merge: true })
}

const getAccountProperties = (uid) => {
  return db.collection(`accounts`).doc(uid).get()
    .then( doc => {
      return doc.data()
    }).then(data => {
      if (data) {
        return data
      } else {
        const props = { isActive : false, isTourActive: true }
        return setAccountProperties(uid, props).then(() => props)
      }
    })
}

const createTracker = (uid, entry) => {
  return db.collection(`accounts/${uid}/trackers`).doc().set(entry)
}

const setAccountActive = (uid) => {
  return db.collection(`accounts`).doc(uid).set({
    isActive: true,
    isTourActive: true,
    hasTrackers: true
  }, { merge: true })
}

const setAccountProperties = (uid, properties) => {
  return db.collection(`accounts`).doc(uid).set(properties, { merge: true })
}

const editWatcher = (uid, id, entry) => {
  return db.collection(`accounts/${uid}/watchers`).doc(id).set(entry)
}

const fetchWatcher = (uid, id) => {
  return db.collection(`accounts/${uid}/watchers`).doc(id).get().then(doc => doc.data())
}

const updateWatcher= (uid, watcherId, properties) => {
  return db.collection(`accounts/${uid}/watchers`).doc(watcherId).set(properties, {merge: true})
}

const createTrackerForType = (uid, type, options) => {
  return db.collection(`accounts/${uid}/trackers`).doc().set({
    type: type,
    options: options
  }).then(() => {
    return setAccountProperties(uid, { hasTrackers: true })
  })
}

const createTrackerJournal = (uid, trackerId, entry) => {
  const today = moment()
  const dateAtFirebase = window.firebase.firestore.Timestamp.fromDate(today.toDate())
  // console.log(`accounts/${uid}/trackers/${trackerId}/journals`)
  return db.collection(`accounts/${uid}/trackers/${trackerId}/journals`).doc(today.format(DATE_FORMAT)).set({
    log: entry,
    date: parseInt(today.format('DD')),
    month: parseInt(today.format('MM')),
    year: parseInt(today.format('YYYY')),
    dateAt: dateAtFirebase
  })
}

const fetchTrackerJournalToday = (uid, trackerId) => {
  const today = moment()
  return db.collection(`accounts/${uid}/trackers/${trackerId}/journals`).doc(today.format(DATE_FORMAT)).get().then(doc => doc.data())
}

const fetchTrackerLatestJournal = (uid, trackerId) => {
  return db.collection(`accounts/${uid}/trackers/${trackerId}/journals`)
         .limit(10)
         .get()
         .then(querySnapshot => {
           const entries = []
           querySnapshot.forEach(doc => {
             entries.push({ id: doc.id, ...doc.data()})
           })
           return entries
         })
}

const fetchAllJournalsForTracker = (uid, trackerId) => {
  return db.collection(`accounts/${uid}/trackers/${trackerId}/journals`)
         .limit(100)
         .get()
         .then(querySnapshot => {
           const entries = []
           querySnapshot.forEach(doc => {
             entries.push({ id: doc.id, ...doc.data()})
           })
           return entries
         })
}

export default {
    fetchInisghtMoodsTrackerData,
    fetchInsightTrackersData,
    fetchInsightDatRateData,
    fetchTrackers,
    fetchTrackersValues,
    setMood,
    fetchMoods,
    setTrackerValue,
    buildSaveValueForTrackerType,
    saveLogEntry,
    fetchLogEntries,
    setDayRate,
    fetchDayRates,
    updateTracker,
    fetchCategories,
    deleteFullTracker,
    fetchTrackerValuesByMonth,
    fetchTrackerById,
    fetchMoodTrackerValuesByMonth,
    fetchDayRateValuesByMonth,
    fetchWatchers,
    createWatcher,
    deleteWatcher,
    setAccountTimestamp,
    getAccountProperties,
    createTracker,
    setAccountActive,
    editWatcher,
    fetchWatcher,
    setAccountProperties,
    updateWatcher,
    createTrackerForType,
    createTrackerJournal,
    fetchTrackerValuesByYearAndWeek,
    fetchTrackerJournalToday,
    fetchTrackerLatestJournal,
    fetchAllJournalsForTracker
}
