
export const weekSteps = [
  {
    selector: '.trackers-step',
    content: 'Here you can add new trackers. You can create any kind of tracker you want',
  },
  {
    selector: '.this-is-a-tracker',
    content: "This is a single tracker and it's where you will be inputting your tracking data",
  },
  {
    selector: '.tour-tracker-delete',
    content: "You can delete the tracker you don't want with this button",
  },
  {
    selector: '.tour-tracker-edit',
    content: "You can edit some tracker information with this button",
  },
  {
    selector: '.tour-tracker-bullet',
    content: 'This is where you enter your daily data. Hover the mouse over the bullet and a dropdown list with options will appear',
  },
  {
    selector: '.tour-scrolltoolbar',
    content: 'This is the week scroller, you will be able to move back and forward in weeks from here',
  },
  {
    selector: '.watchers-step',
    content: 'Here you can add new watchers. A watcher is a group of trackers that measures your progress over a period of time.',
  },
  {
    selector: '.tour-watchers',
    content: 'From this menu you will be able to access all your watchers and edit them or delete them',
  },
  {
    selector: '.tour-reports',
    content: 'Once you completed a few tracker entries you will be able to see the reports and how the trackers relate over time.',
  },
];

export const watchersSteps = [
  {
    selector: '.tour-add-watcher',
    content: 'Here you can add a new watcher. A watcher is a group of trackers that measures your progress over a period of time',
  }
]


export const MAP_PAGES_TO_STEPS = {
  '/': weekSteps,
  '/watchers': watchersSteps
}
