import React from 'react'
import { Card, Button, Alert } from 'antd';
import { SelectOutlined, NumberOutlined } from '@ant-design/icons';
import './Step1.css'

// extra={<a href="#">More</a>}
const Step1 = (props) => {
  return (
    <div>
      {/* <div>
        <h2>Create predefined tracker</h2>
        <select>
          <option value="" disabled selected>Select tracker</option>
          {props.predefinedTrackers && props.predefinedTrackers.map(tracker => <option key={tracker.id} value={tracker.id}>{tracker.options.trackerName}</option>)}
        </select>
      </div> */}

      {/* <hr/> */}
      <h2>Build new tracker</h2>
      <div className="Step1-Options">
        {/*<Alert type="info" message="What type of tracker do you want to create?" />*/}
        {/*<div className="Step1-Card">
          <Card title="Checklist"  style={{ width: 300 }}>
            <p>Yes/no answers</p>
            <p>Good for:</p>
            <p>Like a goal tracker </p>
            <p>or habit tracker</p>
            <p><Button type="primary" onClick={() => props.onClick('checklist')}>Select</Button></p>
          </Card>
        </div>*/}
        <div className="Step1-Card">
          <Card title={<span><SelectOutlined className='Step1-Icon'/>Options</span>} style={{ width: 300 }}>
            <p>Multiple choice answers</p>
            <p>Good for:</p>
            <p>tracking your levels of anxiety:</p>
            <p>low, medium, high</p>
            <p><Button type="primary" onClick={() => props.onClick('options')}>Select</Button></p>
          </Card>
        </div>
        <div className="Step1-Card">
          <Card title={<span><NumberOutlined className='Step1-Icon'/>Numbers</span>} style={{ width: 300 }}>
            <p>Quantities answers</p>
            <p>Good for:</p>
            <p>Tracking your hours of sleep</p>
            <p>or the amout of water you drink</p>
            <p><Button type="primary" onClick={() => props.onClick('numbers') }>Select</Button></p>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Step1
