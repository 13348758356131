import * as actionTypes from '../actions/actionTypes'

const initialState = {
  tour: false,
}

const commonSetTour = (state, action) => {
  return {
    ...state,
    tour: action.value
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMMON_SET_TOUR: return commonSetTour(state, action)
    default: return state;
  }
}

export default reducer
