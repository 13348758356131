import moment from 'moment'
import { sum, map, compact, isEmpty} from 'lodash'

import { DATE_FORMAT } from '../utils/constants'

export const MAX_NUMBER_OF_WATCHERS = 5

export const WATCHERS_HIGH_SCORE = 6

export const calculateTodayScore = (watcher, trackers) => {
 let watcherTrackers = map(watcher.trackers, trackerId => {
   return trackers.find( t => t.id == trackerId)
 })

 watcherTrackers = compact(watcherTrackers)

 if (isEmpty(watcherTrackers)) {
   return 0
 }

 const values = map(watcherTrackers, tracker => {
   let value = parseFloat(tracker.entries[moment().format(DATE_FORMAT)])
   if (!tracker.theLessTheBest) {
     value = tracker.maxNumber - value
   }

   return value * 10 / tracker.maxNumber
 })

 if (values) {
   const average = sum(values) / values.length

   return average
 } else {
   return 0
 }
}


export const isWatchetTodayHigh = (score) => {
  return score >= 8
}

export const isWatcherTodaLow = (score) => {
  return score <= 3
}
