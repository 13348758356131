import * as actionTypes from '../actions/actionTypes'
import moment from 'moment'
import { cloneDeep } from 'lodash'

const DATE_FORMAT = 'YYYY-MM-DD'


  // { name:"Bipolar tendencies", score: 8},
  // { name:"Depression tendencies", score: 7},
  // { name:"Bipolar tendencies", score: 8},
  // { name:"Depression tendencies", score: 7},
  // { name:"Bipolar tendencies", score: 8},
  // { name:"Depression tendencies", score: 7},
  // { name:"Bipolar tendencies", score: 8},
  // { name:"Depression tendencies", score: 7},
  // { name:"Bipolar tendencies", score: 8},
  // { name:"Depression tendencies", score: 7}

const initialState = {
  now: moment(),
  trackers:[],
  dayRates: {
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null
  },
  moods: [
    { pm: null, am: null },
    { pm: null, am: null },
    { pm: null, am: null },
    { pm: null, am: null },
    { pm: null, am: null },
    { pm: null, am: null },
    { pm: null, am: null }
  ],
  dataLoaded: false,
  logEntryValues:{},
  moodTrackerLoading: false,
  watcherSummaryList: [],
  trackerJournalsToday: {},
  trackerJournals: {}
}

const weekMoveForward = (state) => {
  return {
    ...state,
    now: state.now.clone().add('week',1),
    dataLoaded: false
  }
}

const weekMoveBackward = (state) => {
  return {
    ...state,
    now: state.now.clone().subtract('week',1),
    dataLoaded: false
  }
}

const trackerClicked = (state, action) => {
  const { name, value, type } = action.values
  const trackers = [...state.trackers]

  switch (action.trackerType) {
    case 'checklist':
      if (type) {
        trackers[action.index].entries[name] = {...trackers[action.index].entries[name] , [type]: !value }
      } else {
        trackers[action.index].entries[name] = !value
      }
      break;
    case 'options':
      if (type) {
        trackers[action.index].entries[name] = {...trackers[action.index].entries[name] , [type]: value }
      } else {
        trackers[action.index].entries[name] = value
      }
      break
    case 'numbers':
      if (type) {
        trackers[action.index].entries[name] = {...trackers[action.index].entries[name] , [type]: value }
      } else {
        trackers[action.index].entries[name] = value
      }
      break
    default:
  }

  return {
    ...state,
    trackers:trackers
  }
}

const weekSelectDate = (state, action) => {
  return {
    ...state,
    now: action.date == null ? moment() : action.date,
    dataLoaded: true
  }
}

const dayRateClicked = (state, action) => {
  const dayRates = {...state.dayRates}
  dayRates[action.day] = action.value

  return {
    ...state,
    dayRates: dayRates
  }
}

const moodClicked = (state, action) => {
  const moods = [...state.moods ]

  if (!moods[action.week]) {
    moods[action.week] = {}
  }

  moods[action.week][action.time] = action.mood

  return {
    ...state,
    moods: moods
  }
}

const weekLoadTrackers = (state, action) => {
  return {
    ...state,
    trackers: action.trackers,
    trackersLoading: false
  }
}

const weekLoadingTrackersStart = (state) => {
  return {
    ...state,
    trackersLoading:true
  }
}

const weekLoadTrackersDataStart = (state, action) => {
  let trackers = cloneDeep(state.trackers)
  trackers = trackers.map( (tracker) => {
    if (tracker.id === action.trackerId) {
      tracker.entries = cloneDeep(action.values)
      return tracker
    } else {
      return tracker
    }
  })
  return {
    ...state,
    trackers: trackers
  }
}

const weekReset = (state) => {
  return {
    ...state,
    dataLoaded: false,
    trackers: []
  }
}

const initMoodTracker = (state, action) => {
  return {
    ...state,
    moods: action.values
  }
}

const weekLoadDayRates = (state, action) => {
  return {
    ...state,
    dayRates: action.values
  }
}

const weekSetCounterLoaded = (state, action) => {
  return {
    ...state,
    countersLoaded: action.value
  }
}

const weekChangeLogEntry = (state, action) => {
  return {
    ...state,
    logEntryValues: {
      ...state.logEntryValues,
      [action.date.format(DATE_FORMAT)]: action.value
    }
  }
}

const weekSetLogEntries = (state, action) => {
  return {
    ...state,
    logEntryValues: action.entries
  }
}

const weekRemoveTracker = (state, action) => {
  const newTrackers = state.trackers.filter((item) => item.id !== action.trackerId )
  return {
    ...state,
    trackers: newTrackers
  }
}

const weekUpdateTrackerName = (state, action) => {
  const newTrackers = state.trackers.map( tracker => {
    if (tracker.id === action.trackerId) {
      return {...tracker, trackerName: action.trackerName, category: action.category}
    } else {
      return tracker
    }
  })

  return {
    ...state,
    trackers: newTrackers
  }
}

const weekSetField = (state, action) => {
  return {
    ...state,
    [action.field]: action.value
  }
}

const weekOnBackToToday = (state) => {
  return {
    ...state,
    now: moment(),
    dataLoaded: false
  }
}

const weekFilterTrackerChange = (state, action) => {
  return {
    ...state,
    filterTrackerText: action.value
  }
}

const weekOpenFeedBack = (state, action) => {
  return {
    ...state,
    feedbackTracker: action.feedbackTracker,
    openFeedback: action.openFeedback
  }
}

const weekLoadTrackerJournal = (state, action) => {
  return {
    ...state,
    trackerJournals: {
      ...state.trackerJournals,
      [action.trackerId]: action.entries
    }
  }
}

const weekLoadTrackerJournalToday = (state, action) => {
  return {
    ...state,
    trackerJournalsToday: {
      ...state.trackerJournalsToday,
      [action.trackerId]: action.data
    }
  }
}

const weekSetJournalList = (state, action) => {
  return {
    ...state,
    journalList: action.journals,
    openViewJournal: true
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.WEEK_MOVE_FORWARD: return weekMoveForward(state, action)
  case actionTypes.WEEK_MOVE_BACKWARD: return weekMoveBackward(state, action)
  case actionTypes.TRACKER_CLICKED: return trackerClicked(state, action)
  case actionTypes.WEEK_SELECT_DATE: return weekSelectDate(state, action)
  case actionTypes.DAY_RATE_CLICKED: return dayRateClicked(state, action)
  case actionTypes.MOOD_CLICKED: return moodClicked(state, action)
  case actionTypes.WEEK_LOAD_TRACKERS: return weekLoadTrackers(state, action)
  case actionTypes.WEEK_LOADING_TRACKERS_START: return weekLoadingTrackersStart(state, action)
  case actionTypes.WEEK_LOAD_TRACKERS_DATA_START: return weekLoadTrackersDataStart(state,action)
  case actionTypes.WEEK_RESET: return weekReset(state, action)
  case actionTypes.INIT_MOOD_TRACKER: return initMoodTracker(state, action)
  case actionTypes.WEEK_LOAD_DAY_RATES: return weekLoadDayRates(state,action)
  case actionTypes.WEEK_SET_COUNTERS_LOADED: return weekSetCounterLoaded(state, action)
  case actionTypes.CHANGE_LOG_ENTRY: return weekChangeLogEntry(state, action)
  case actionTypes.WEEK_LOG_ENTRY_SET: return weekSetLogEntries(state, action)
  case actionTypes.WEEK_REMOVE_TRACKER: return weekRemoveTracker(state, action)
  case actionTypes.WEEK_UPDATE_TRACKER_NAME: return weekUpdateTrackerName(state, action)
  case actionTypes.WEEK_SET_FIELD: return weekSetField(state, action)
  case actionTypes.WEEK_ON_BACK_TO_TODAY: return weekOnBackToToday(state, action)
  case actionTypes.WEEK_FILTER_TRACKER_CHANGE: return weekFilterTrackerChange(state, action)
  case actionTypes.WEEK_SET_MODAL_FEEDBACK: return weekOpenFeedBack(state, action)
  case actionTypes.WEEK_LOAD_TRACKER_JOURNAL: return weekLoadTrackerJournal(state, action)
  case actionTypes.WEEK_LOAD_TRACKER_JOURNAL_TODAY: return weekLoadTrackerJournalToday(state, action)
  case actionTypes.WEEK_SET_JOURNAL_LIST: return weekSetJournalList(state, action)
  default: return state
  }
}

export default reducer
