import React from 'react'
import { Input, Form } from 'antd'

import './TrackersFeedback.scss'

const FeedbackEntry = ({ tracker }) => {
  return (
    <Form.Item name={tracker.id} label={tracker.trackerName}>
      <Input maxLength={255}/>
    </Form.Item>
  )
}

const TrackersFeedback = (props) => {
  const { tracker, onFinish, form } = props

  return (
    <div className="TrackersFeedback">
      <Form layout='vertical' onFinish={onFinish} form={form}>
        <FeedbackEntry key={tracker.id} tracker={tracker} />
      </Form>
    </div>
  )
}

export default TrackersFeedback
