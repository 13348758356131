import client from '../../services/client'
import { entries, find } from 'lodash'
import * as actionTypes from './actionTypes'

export const setDayField = (field, value) => {
  return {
    type: actionTypes.DAY_SET_FIELD,
    field: field,
    value: value
  }
}

export const dayOnLoadTrackers = uid => disapch => {
  client.fetchTrackers(uid)
    .then(trackers => {
      disapch(setDayField('trackers', trackers))
    })
}

export const setTrackerValue = (trackerId, values) => {
  return {
    type: actionTypes.DAY_SET_TRACKER_VALUES,
    values: values,
    trackerId: trackerId
  }
}

export const dayInitTrackers = (uid, year, week, storedTrackers, firstTimeSetup) => dispach => {
  dispach(setDayField('trackersLoading', true))
  client.fetchTrackers(uid)
    .then(trackers => {
      dispach(setDayField('trackers', trackers))
      return trackers
    }).then(trackers => {
      client.fetchTrackersValues(uid, trackers, year, week)
        .then(entries => {
          for (let idx in entries) {
            const { tracker, values } = entries[idx]
            dispach(setTrackerValue(tracker.id, values))
          }
          return true
        }).then(() => dispach(setDayField('trackersLoading', false)))
    })
}

export const dayMoodClicked = (time, week, mood, date) => {
  return {
    type: actionTypes.DAY_MOOD_CLICKED,
    time: time,
    week: week,
    mood: mood,
    date: date
  }
}

export const dayMoodClickedStarted = (uid, date, time, week, mood) => {
  return dispach => {
    client.setMood(uid, date, time, week, mood)
      .then(() => dispach(dayMoodClicked(time, week, mood, date)))
  }
}

export const dayInitMoodTracker = (entries) => {
  return {
    type: actionTypes.DAY_INIT_MOOD_TRACKER,
    values: entries
  }
}

export const dayLoadMoodTrackerData = (uid, year, week) => {
  return dispach => {
    client.fetchMoods(uid, year, week)
      .then((entries) => {
        dispach(dayInitMoodTracker(entries))
      })
  }
}

export const dayDayRateClicked = (day, value) => {
  return {
    type: actionTypes.DAY_DAY_RATE_CLICKED,
    day: day,
    value: value
  }
}

export const dayOnDayRateClickedSend = (uid, date, day, value) => {
  return dispach => {
    client.setDayRate(uid, date, day, value)
    .then(() => {
      dispach(dayDayRateClicked(day, value))
    })
  }
}

export const daySaveLogEntry = (uid, date, label, value) => {
  return dispach => {
    client.saveLogEntry(uid, date, value)
  }
}

export const dayChangeLogEntry = (date, label, value) => {
  return {
    type: actionTypes.DAY_CHANGE_LOG_ENTRY,
    date: date,
    value: value
  }
}

export const dayMoveForward = () => {
  return {
    type: actionTypes.DAY_MOVE_FORWARD
  }
}

export const dayMoveBackward = () => {
  return {
    type: actionTypes.DAY_MOVE_BACKWARD
  }
}

export const daySelectDate = (date) => {

}

export const dayOnBackToToday = () => {
  return {
    type: actionTypes.DAY_ON_BACK_TO_TODAY
  }
}

export const dayRemoveTracker = (trackerId) => {
  return {
    type: actionTypes.DAY_REMOVE_TRACKER,
    trackerId: trackerId
  }
}

export const dayOnTrackDelete = (uid, trackerId) => {
  return dispach => {
    client.deleteFullTracker(uid,trackerId)
    .then(() => {
      dispach(dayRemoveTracker(trackerId))
    })
  }
}

export const dayUpdateTrackerName = (trackerId, trackerName, category) => {
  return {
    type: actionTypes.DAY_UPDATE_TRACKER_NAME,
    trackerId: trackerId,
    trackerName: trackerName,
    category: category
  }
}

export const dayEditTrackName = (uid, config, values) => {
  return dispach => {
    client.updateTracker(uid, config, values)
    .then(([ config, values ])=> {
      dispach(dayUpdateTrackerName(config.id,values.trackerName, values.category))
    })
  }
}

export const dayTrackerClicked = (trackerType, values, index) => {
  return {
    type: actionTypes.DAY_TRACKER_CLICKED,
    trackerType: trackerType,
    values: values,
    index: index
  }
}

export const daySendTrackerValue = (uid, trackerId, values, trackerType, index, id) => {
  return dispach => {
    const { value, type, date } = values

    const saveValue = client.buildSaveValueForTrackerType(trackerType, type, value)
    client.setTrackerValue(uid, trackerId, date, saveValue)
      .then(() => dispach(dayTrackerClicked(trackerType, values, index)))
  }
}

export const daySetLogEntries = (entries) => {
  return {
    type: actionTypes.DAY_LOG_ENTRY_SET,
    entries: entries
  }
}

export const dayInitLogEntries = (uid, year, week) => {
  return dispach => {
    client.fetchLogEntries(uid,year,week)
    .then(entries => dispach(daySetLogEntries(entries)))
  }
}

export const dayLoadDayRates = (values) => {
  return {
    type: actionTypes.DAY_LOAD_DAY_RATES,
    values: values
  }
}

export const dayFetchDayRates = (uid, year, week) => {
  return dispach => {
    client.fetchDayRates(uid, year, week)
    .then((entries) => {
        dispach(dayLoadDayRates(entries))
    })
  }
}

export const dayLoadCategories = uid => dispach => {
  client.fetchCategories(uid)
  .then(data => {
    dispach(setDayField('categories', data))
  })
}

export const dayOnDayChartChange = (uid, value, year, month) => dispach => {
  if (value === 'moodsTracker') {
    client.fetchMoodTrackerValuesByMonth(uid, year, month)
    .then(values => {
      const dataset = { data: values }
      dispach(setDayField('dayChartData', dataset))
      dispach(setDayField('dayChartSelect', value))
    })
  } else if (value === 'dayRate') {
    client.fetchDayRateValuesByMonth(uid, year, month)
    .then(values => {
      const dataset = { data: values }
      dispach(setDayField('dayChartData', dataset))
      dispach(setDayField('dayChartSelect', value))
    })
  } else {
    client.fetchTrackerById(uid, value)
    .then( tracker => {
      client.fetchTrackerValuesByMonth(uid, value, year, month)
      .then( entries => {
        const dataset = { tracker: tracker, data: entries }
        dispach(setDayField('dayChartData', dataset))
        dispach(setDayField('dayChartSelect', value))
      })
    })
  }
}