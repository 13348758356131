import React from 'react';
import { Line } from 'react-chartjs-2'
import { buildChart } from '../../utils/charts';

import { Box } from '../UI'

const convertToData = ({ selectValue, date, data }) => {
  return buildChart(selectValue, date, "", data.tracker, data.data)
}

const DayMonthChart = (props) => {
  const { trackers, onChange, date, selectValue, height } = props
  const [options, data] = selectValue ? convertToData(props) : [null, null]

  const onChangeHandler = (event) => {
    onChange(event.target.value, parseInt(date.format('YYYY')), parseInt(date.format('MM')))
  }

  return (
    <Box title={props.date.format('MMMM')}>
      <select className="Form-Select" onChange={onChangeHandler}>
        <option key='moodsTracker' value='moodsTracker'>Mood Tracker</option>
        <option key='dayRate' value='dayRate'>Day Rate</option>
        {trackers && trackers.map( tracker => <option key={tracker.id} value={tracker.id}>{tracker.trackerName}</option>)}
      </select>
      {selectValue && <Line data={data} options={options} height={height} width={height} />}
    </Box>
  );
}

export default DayMonthChart;
