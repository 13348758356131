import React from 'react'
import { isUndefined, find, capitalize } from 'lodash'
import { Tooltip } from 'antd'

const TrackSummaryCell = (props) => {
  const { tracker, value } = props.data
  const { type, options } = tracker
  const { trackerName } = options

  let content = null

  switch (type) {
    case 'checklist':
      const classes = ["DaySummary-Bullet"]

      if (value) {
        classes.push('DaySummary-Bullet-Active')
        content = (
          <div className={classes.join(' ')}></div>
        )
      } else {
        content = null
      }

      break;
    case 'options':
      if (isUndefined(value)) {
        // content = <div className="DaySummary-Bullet"></div>
        content = null
      } else if (value.value['PM']) {
          const itemAm = find(tracker.options.selections, s => s.label === value.value['AM'] )
          const itemPm = find(tracker.options.selections, s => s.label === value.value['PM'] )

          content = (
            <>
            <Tooltip title={capitalize(value.value['AM'])}>
              <div className="DaySummary-Bullet-AmPm DaySummary-Bullet" style={{backgroundColor:itemAm && itemAm.color}}></div>
            </Tooltip>
            <Tooltip title={capitalize(value.value['PM'])}>
              <div className="DaySummary-Bullet-AmPm DaySummary-Bullet-Pm DaySummary-Bullet" style={{backgroundColor:itemPm && itemPm.color}}></div>
            </Tooltip>
            </>
          )
      } else {
        content = (
          <Tooltip title={value.label}>
          <div className="DaySummary-Bullet" style={{backgroundColor:value.color}}></div>
          </Tooltip>
        )
      }

      break;
    case 'numbers':
      // debugger
      if (isUndefined(value)) {
        // content = <div className="DaySummary-Bullet"></div>
        content = null
      } else if(value.value['AM']) {
        content = (
          <>
          <div className="DaySummary-Bullet-AmPm DaySummary-Bullet">{value.value['AM']}</div>
          <div className="DaySummary-Bullet-AmPm DaySummary-Bullet-Pm DaySummary-Bullet">{value.value['PM']}</div>
          </>
        )
      } else {
        content = (
          <div className="DaySummary-Bullet">{value.value}</div>
        )
      }
      break;
    default:
      content = null
  }

  if (content === null) return null

  return (
    <div>
      <label>{trackerName}</label>
      {content}
    </div>
  )
}

export default TrackSummaryCell
