import React, { useCallback } from 'react'
import { Input } from 'antd';
import { throttle } from 'lodash'
import classnames from 'classnames'

import { BulletSelector } from '../../UI'
import { isCurrentDate } from '../../../utils/dates'

import './LogEntry.css'

const { TextArea } = Input;
const LogEntry = (props) => {
  const { date, label, onSaveLogEntry, format } = props

  const isCurrDate = isCurrentDate(date)

  const cssClasses = classnames('LogEntry',{'LogEntry-IsCurrentDate':isCurrDate, 'LogEntry-Day': format === 'day'})

  const memoizedCallback = useCallback(throttle(v => onSaveLogEntry(date, label, v), 10000),[])
// onKeyDown={(e) => }

  return (
    <div className={cssClasses}>
      <label>{props.date.format('dddd')} {props.date.format('Do')}</label>
      <TextArea
        value={props.value}
        rows={props.size}
        maxLength={1000}
        showCount
        onChange={(e) => {
          props.onChangeLogEntry(props.date, props.label, e.target.value)
          memoizedCallback(e.target.value)
        }}
        onBlur={(e) => {
          props.onSaveLogEntry(props.date, props.label, e.target.value)
        }}/>

      <div className="LogEntry-DayRate">
        <p>Rate your day</p>
        <BulletSelector 
            items={[1,2,3,4,5,6,7,8,9,10]} 
            onClick={props.onDayRateClick} 
            value={props.dayRateValue}/>
      </div>
    </div>
  )
}

export default LogEntry
